export default {
  'nav.account': 'Personligt center',
  'nav.language': 'Forenklet kinesisk',
  'signup.success': 'Tilmelding lykkedes',

  //fejl
  'contact.empty': 'Kontaktoplysninger må ikke være tomme',
  'phone.country.empty': 'Telefonpræfikset må ikke være tomt',
  'phone.empty': 'Telefonen må ikke være tom',
  'email.valid': 'Indtast venligst den korrekte e-mail',

  // base
  'base.success': 'success',
  'base.save': 'Gem',
  'base.slide': 'Træk venligst skyderen for at bekræfte',
  'base.send.code': 'Send bekræftelseskode',
  'base.send.time': '({time}) send bekræftelseskode igen',
  'base.send.success': 'Send vellykket',
  'base.view.more': 'Se mere',
  'base.Language': 'Sprog',
  'base.previous': 'Forrige side',
  'base.next': 'Næste side',
  'base.total.page': 'I alt {page} sider',
  'base.go.to.page': 'Gå til side',
  'base.go': 'gå til',
  'base.followers': 'Følgere',
  'base.follow': 'følg',
  'base.following': 'Folgt',
  'base.visit.store': 'Gennemse butik',
  'base.contact': 'Kontakt os',
  'base.delivery': 'Estimeret leveringstid: 1-7 dage',
  'base.search': 'Søg',
  'base.search.text': 'Indtast søgeordssøgning',
  'base.server': 'Online kundeservice',

  //indeks
  'text.categories.title': 'Produktkategori',
  'text.welcome': 'Velkommen til shopping!',
  'text.wishlist': 'Liste synes godt om',
  'text.account': 'Personligt center',
  'text.orders': 'Ordrer',
  'text.messages': 'Meddelelser',
  'text.suggest.title': 'Eksklusivt tilbud',
  'text.suggest.content': 'Kun for nye globale premium-medlemmer!',
  'text.super.deals': 'Topprodukter, utrolige priser.',

  //Tilmelde
  'signup.register': 'Registrer',
  'signup.signin': 'Login',
  'signup.store.signin': 'Business login',
  'signup.sign.out': 'Log ud',
  'signup.email': 'E-mailadresse',
  'signup.password': 'Adgangskode',
  'signup.qr.password': 'Bekræft adgangskode',
  'signup.confirm.password': 'Bekræft adgangskode',
  'signup.forgot.password': 'Glemt adgangskode',
  'signup.invitation': 'Invitationskode',
  'signup.char': 'Karakter',
  'signup.contains': 'Indeholder tal, bogstaver eller symboler',
  'signup.qr.contains': 'De to adgangskoder er inkonsistente',
  'signup.create.account': 'Opret konto',
  'signup.agree': 'Opret en konto, du accepterer',
  'signup.member.agreement': 'Medlemskabsaftale',
  'signup.and': 'og',
  'signup.privacy.policy': 'Privatlivspolitik',
  'signup.email.code': 'E-mailbekræftelse',
  'signup.last.step': 'Sidste trin',
  'signup.send.email': 'Indtast venligst den 4-cifrede kode sendt til {email}',
  'signup.modify.email': 'Rediger e-mail',
  'signup.verify.email': 'Bekræft e-mail',
  'signup.have.store': 'Har en sælgerkonto',
  'signup.goto.signin': 'Virksomhedslogin',
  'signup.no.store': 'Ingen sælgerkonto',
  'signup.goto.store': 'Tilmeld sælger',
  'signup.next': 'Næste',
  'signup.your.email': 'din e-mail',
  'signup.code.text': 'Bekræftelseskode',
  'signup.submit.signup': 'Tilmeld dig nu',
  'signup.smrz': 'Autentificering af rigtige navn',
  'signup.derb': 'Andet trin',
  'signup.qsrxm': 'Indtast venligst navnet på dokumentet',
  'signup.qsrhm': 'Indtast venligst ID-nummeret',

  //glemte
  'forgot.title': 'Nulstil adgangskode',
  'forgot.btn.check': 'Bekræft e-mail',
  'forgot.reset.now': 'Nulstil nu',

  //butik
  'store.info.open': 'Åben {y} uger',

  // sidefod
  'footer.great.value': 'Stor værdi',
  'footer.great.value.desc': 'Vi tilbyder konkurrencedygtige priser for over 100 millioner varer.',
  'footer.shopping': 'Global Shopping',
  'footer.shopping.desc': 'Vi sender til mere end 200 lande og regioner, og vores hjemmeside er tilgængelig på 7 sprog.',
  'footer.safe.payment': 'Sikker betaling',
  'footer.safe.payment.desc': 'Betal med verdens mest populære og sikreste betalingsmetoder.',
  'footer.shop.with.confidence': 'Shop med tillid',
  'footer.shop.with.confidence.desc': 'Vores køberbeskyttelsespolitik dækker hele din købsproces.',
  'footer.help.center': 'Hjælp',
  'footer.help.center.desc': 'Hjælp i al slags vejr for at skabe en smidig indkøbsoplevelse.',
  'footer.terms.conditions': 'Vilkår og betingelser',
  'footer.return.policy': 'Returpolitik',
  'footer.support.policy': 'Supportpolitik',
  'footer.privacy.policy': 'Privatlivspolitik',
  'footer.be.seller': 'Bliv sælger',
  'footer.apply.now': 'Ansøg nu',
  'footer.stay.connected': 'Forbliv forbundet',

  'footer.about.us': 'Om os',
  'footer.about.company': 'Virksomhedsprofil',
  'footer.about.video': 'Video introduktion',
  'footer.contact': 'Kontaktoplysninger',

  'footer.my.account': 'Min konto',
  'footer.my.logout': 'Log ud',
  'footer.my.order': 'Ordrehistorik',
  'footer.my.wish': 'Min ønskeliste',
  'footer.my.join': 'Bliv medlemspartner',
  'footer.email': 'E-mail',
  'footer.gfemail': 'Officiel e-mail',
  'footer.fwemail': 'Service-e-mail',
  'footer.address': 'Adresse',

  'apply.success': 'Ansøgning gennemført',
  'apply.success.desc': 'Anvendt med succes, gå til login-konto',

  // Kategorier
  'category.title': 'Relaterede kategorier',
  'category.all': 'Alle kategorier',

  //detalje
  'detail.store.home': 'Butikshjem',
  'detail.sale.items': 'Produktliste',
  'detail.recommend': 'Anbefal',
  'detail.orders': 'Salg',
  'detail.quantity': 'Quantity',
  'detail.pieces.available': 'Tilgængelig',
  'detail.delivery': 'Levering',
  'detail.free.shipping': 'Gratis forsendelse',
  'detail.estimated.delivery': 'Estimeret ankomst',
  'detail.days': 'days',
  'detail.buy.now': 'Køb',
  'detail.add.to.cart': 'Tilføj til indkøbskurv',
  'detail.buyer.protection': 'Køberbeskyttelse',
  'detail.money.guarantee': 'Pengene tilbage-garanti',
  'detail.refund.desc': 'Få fuld refusion, hvis varen ikke er som beskrevet eller ikke er leveret',
  'detail.description': 'Produktbeskrivelse',
  'detail.customer.reviews': 'Kundeanmeldelser',
  'detail.specifications': 'Produktspecifikationer',
  'detail.top.selling.products': 'Bedst sælgende produkter',
  'detail.recommended.for.you': 'Anbefalet til dig',
  'detail.sold': 'Salg',
  'detail.receipt': 'Bekræft modtagelse',
  'detail.receipt.title': 'Bekræfte modtagelse af ordre? ',
  'detail.receipt.content': 'Efter bekræftelse er transaktionsordren gennemført',
  'detail.comment': 'Kommentar',
  'detail.refund.title': 'Bekræft ansøgning om refusion',
  'detail.refund.content': 'Efter bekræftelse vil ordren søge om refusion',
  'detail.sqtk': 'Ansøg om refusion',

  //bekræfte
  'confirm.shipping.address': 'Modtageradresse',
  'confirm.change': 'Skift',
  'confirm.payment.methods': 'Betalingsmetoder',
  'confirm.summary': 'Afregning',
  'confirm.total.item.costs': 'I alt varer',
  'confirm.total': 'Total',
  'confirm.checkout': 'Gå til kassen',
  'confirm.place.order': 'Afgiv en ordre nu',
  'confirm.pay.now': 'Betal nu',
  'confirm.order.desc': "Efter at have klikket på 'Afgiv ordre' bekræfter jeg, at jeg har læst og bekræftet",
  'confirm.order.policy': 'Alle vilkår og politikker',
  'confirm.payment': 'Global Shopping sikrer dine oplysninger og betalingssikkerhed',

  // adresse
  'address.title': 'Kvitteringsadresse',
  'address.default': 'default',
  'address.edit': 'Rediger',
  'address.delete': 'Slet',
  'address.new': 'Opret en ny adresse',
  'address.contact': 'Kontakt',
  'address.address': 'Adresse',
  'address.phone': 'Telefonnummer',
  'address.set.default': 'Indstil standard',
  'address.confirm': 'Bekræft',
  'address.cancel': 'Annuller',
  'address.del.title': 'Bekræft at slette adresse?',
  'address.del.content': 'Er du sikker på, at du vil slette denne leveringsadresse?',

  'payment.method': 'Betalingsmetode',

  // Indkøbskurv
  'shopping.title': 'Indkøbskurv',
  'shopping.back.buy': 'Tilbage til specialshopping',
  'shopping.start.shopping': 'Begynd at shoppe',

  // medlem
  'member.account': 'Personligt center',
  'member.overview': 'Oversigt',
  'member.orders': 'Ordrer',
  'member.payment': 'My Wallet',
  'member.address': 'Kvitteringsadresse',
  'member.wishlist': 'Liste synes godt om',
  'member.followlist': 'Følg liste',
  'member.message.center': 'Meddelelsescenter',
  'member.setting': 'Personlige indstillinger',
  'member.shop.info': 'Butikinformation',
  'member.shop.setting': 'Butiksindstillinger',
  'member.order.notify': 'Ny ordremeddelelse',
  'member.order.tips': 'Ved at sende e-mails til sælgerkonto-e-mail',

  // medlemsrækkefølge
  'member.order.title': 'Min ordre',
  'member.order.view.all': 'Se alle',
  'member.order.all': 'Alle',
  'member.order.unpaid': 'ubetalt',
  'member.order.paid': 'Betalt',
  'member.order.procurement': 'skal købes',
  'member.order.seller.paid': 'Købt',
  'member.order.processing': 'skal behandles',
  'member.order.shipped': 'shipped',
  'member.order.return': 'Returnerer',
  'member.order.completed': 'Completed',
  'member.order.refund': 'Refunderet',
  'member.order.all.time': 'all',
  'member.order.empty': 'Ingen ordre endnu',
  'member.order.date': 'date',
  'member.order.purchase.date': 'Køb',
  'member.order.cpoy': 'Kopi',
  'member.order.id': 'Ordre',
  'member.order.detail': 'Ordredetaljer',
  'member.order.logistics': 'Logistikinformation',

  // medlemsbetaling
  'member.payment.title': 'My Wallet',
  'member.wallet.balance': 'wallet balance',
  'member.crypto.recharge': 'Oplad',
  'member.crypto.withdrawal': 'Udtrækning',
  'member.crypto.bank': 'Bankkort',
  'member.wallet.record': 'møntindbetalingspost',
  'member.bankcard.record': 'Bankkortregistrering',
  'member.withdrawal.record': 'Tilbagetrækningsregistrering',
  'member.income.record': 'indkomstrekord',
  'member.transaction.record': 'Transaktionspost',
  'member.wallet.freeze': 'Frys penge',
  'member.wallet.profit': 'Estimeret indtjening',

  //genopladning
  'recharge.currency': 'Valuta',
  'recharge.protocol': 'Protokol',
  'recharge.qrcode': 'QR-kode',
  'recharge.address': 'Wallet-adresse',
  'recharge.copy': 'Kopiér adresse',
  'recharge.ok.text': 'Bekræft',
  'recharge.cancel.text': 'Annuller',

  //bank
  'bank.recharge.title': 'Genopladning af bankkort',
  'bank.title': 'Banknavn',
  'bank.name': 'navn',
  'bank.account': 'Konto',
  'bank.routing': 'Routing',
  'bank.code': 'kode',
  'bank.bankname': 'Banknavn',
  'bank.bankaddress': 'Bankadresse',
  'bank.ok.text': 'Næste',
  'bank.ok.prev': 'Forrige trin',
  'bank.submit': 'Send nu',
  'bank.amount': 'Opladningsbeløb',
  'bank.amount.desc': 'Indtast venligst genopladningsbeløbet',
  'bank.type': 'type',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Kvitteringsnummer',
  'bank.receipt.number.desc': 'Indtast venligst transaktionskvitteringsnummeret',
  'bank.credential.picture': 'Legitimationsbillede',
  'bank.credential.picture.desc': 'Upload venligst legitimationsbilledet',
  'bank.remark': 'Bemærkning',
  'bank.upload': 'Upload billeder',
  'bank.text.title': 'Noter',
  'bank.text.t1': 'Behøver ingen bemærkninger til overførsel, lad det stå tomt',
  'bank.text.t2': 'Vælg Hong Kong som Hongkong-husstandens land, tilføj ikke Kina',
  'bank.text.t3': 'Amerikanske pengeoverførsler, på hverdage, overførsler til Europa før kl. 15.00, pengeoverførsler er tilgængelige i bankens arbejdstid!',
  'bank.text.t4': 'Betalingstiden er T+1, og den seneste betaling er T+3 i Europa',
  'bank.text.t5': 'Informer platformen, før du overfører penge for at bekræfte, om kontoen er tilgængelig. Hvis kontoen lukkes, udbetales der ingen kompensation.',
  'bank.text.t6': 'Indbetaling via bankkort. Kundeserviceoplysninger skal have forrang.',

  //bankliste
  'bank.list.title': 'Banknavn',
  'bank.list.code': 'Bankkode',
  'bank.list.amount': 'Overførselsbeløb',
  'bank.list.number': 'Kvitteringsnummer',
  'bank.list.img': 'Voucherbillede',
  'bank.list.status': 'Status',
  'bank.list.time': 'tid',
  'bank.list.status1': 'Under gennemgang',
  'bank.list.status2': 'Bestået',
  'bank.list.status3': 'Afvist',

  // Tilbagetrækning
  'withdrawal.address.desc': 'Indtast venligst tegnebogens adresse!',
  'withdrawal.number': 'Number',
  'withdrawal.real.number': 'faktisk ankomst',
  'withdrawal.number.desc': 'Indtast venligst udbetalingsbeløbet!',
  'withdrawal.btn.all': 'alle',
  'withdrawal.balance': 'Balance',
  'withdrawal.commission': 'kommission',
  'withdrawal.actual.amount': 'Accounted',
  'withdrawal.notice': 'Prompt',
  'withdrawal.notice.text': 'Før du overfører, bedes du bekræfte, at modtageradresseoplysningerne er korrekte. Når aktiverne er overført, kan de ikke returneres.',
  'withdrawal.notice.content': '{name}({cp_name}) kommission: aktuel markedsværdi {fee_rate}%/pen, minimumsstandard: {fee_min} {name}/pen',
  'withdrawal.submit': 'Send',
  'withdrawal.choice': 'Vælg en kryptovaluta',
  'withdrawal.yzm': 'Bekræftelseskode',
  'withdrawal.fs': 'Send',
  'withdrawal.qsryzm': 'Indtast venligst e-mailbekræftelseskoden',

  // modtage
  'recive.method': 'Protokol',
  'recive.amount': 'Beløb',
  'recive.address': 'adresse',
  'recive.date': 'tid',
  'recive.status': 'Status',
  'recive.create.at': 'Transaktionstid',
  'recive.type': 'Type',
  'recive.befor': 'før transaktion',
  'recive.balance': 'Balance',
  'recive.freeze': 'Frys',
  'recive.review': 'Anmelder',
  'recive.success': 'Bestået',
  'recive.reject': 'Afvist',

  // annoncere
  'advertise.title': 'Promotion Center',
  'advertise.shop.title': 'Butikskampagne',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Udløbet',
  'advertise.shop.promotion': 'Promoting',
  'advertise.shop.expire.date': 'Udløbsdato',
  'advertise.shop.renew': 'Annoncefornyelse',
  'advertise.shop.payable': 'Beløbsbeløb',
  'advertise.shop.explanation': 'Annonceforklaring',
  'advertise.shop.text': 'Deltag i promovering af platformsannoncering, øg butikseksponering og promover transaktionsordrer',
  'advertise.shop.paynow': 'Forny nu',
  'advertise.shop.modal.title': 'Bekræft betaling',
  'advertise.shop.modal.desc': 'Bekræftet at betale kampagnegebyret',
  'advertise.shop.modal.btn': 'Bekræft betaling',

  //Ønskeliste
  'wishlist.title': 'Ønskeliste',
  'wishlist.delete': 'Slet',
  'wishlist.orders': 'Salg',

  //Følg liste
  'followlist.title': 'Følg liste',
  'followlist.delete': 'Slet',
  'followlist.follow': 'Følg',

  // butik
  'store.dashboard': 'Dashboard',
  'store.products': 'Produkter',
  'store.products.list': 'Produktliste',
  'store.products.reviews': 'Produktanmeldelser',
  'store.orders': 'Ordre',
  'store.wallet': 'Wallet',
  'store.message': 'Meddelelsescenter',
  'store.setting': 'indstilling',
  'store.order.total.profit': 'Estimeret samlet driftsindtægt',

  //dashboard
  'dashboard.store.hour.views': 'Realtidsbesøg',
  'dashboard.store.today.views': 'Dagens besøg',
  'dashboard.product.total': 'Produkt i alt',
  'dashboard.product.today': 'Ny i dag',
  'dashboard.order.total': 'Total ordre',
  'dashboard.sales.total': 'Estimeret omsætning',
  'dashboard.sales.real': 'Reelt salg',
  'dashboard.sales.pay': 'Samlet udbetaling',
  'dashboard.sales.profit': 'Samlet overskud',
  'dashboard.commission.total': 'Samlet omsætning',
  'dashboard.commission.today': 'Dagens indtjening',
  'dashboard.order.sales': 'Råvaresalg',
  'dashboard.sales.list': 'Salgsrangering',
  'dashboard.goods.cate.rate': 'Produktklassificeringsforhold',
  'dashboard.goods.wish': 'Like produktrangering',
  'dashboard.january': 'Januar',
  'dashboard.february': 'Februar',
  'dashboard.march': 'Marts',
  'dashboard.april': 'April',
  'dashboard.may': 'Maj',
  'dashboard.june': 'Juni',
  'dashboard.july': 'Juli',
  'dashboard.august': 'August',
  'dashboard.september': 'September',
  'dashboard.october': 'Oktober',
  'dashboard.november': 'November',
  'dashboard.december': 'December',

  // Produkter
  'products.add.new': 'Tilføj nyt produkt',
  'products.add.from.warehouse': 'Tilføj produkter fra lager',
  'products.delete': 'Slet',
  'products.add': 'Tilføj',
  'products.table.img': 'image',
  'products.table.name': 'produktnavn',
  'products.table.category': 'Kategori',
  'products.table.wish': 'synes godt om',
  'products.table.stock': 'stock',
  'products.table.price': 'Price',
  'products.table.profit': 'Profit',
  'products.table.action': 'Handling',
  'products.search.category': 'Søg efter kategori',
  'products.back.product': 'Tilbage til produktliste',
  'products.total': 'Totale varer',
  'products.yes': 'Ja',
  'products.no': 'Annuller',
  'products.batch.add': 'Batchforøgelse',
  'products.ask.add': 'Er du sikker på, at du vil tilføje produkter?',
  'products.batch.delete': 'Batch sletning',
  'products.ask.delete': 'Er du sikker på, at du vil slette produktet?',
  'products.top': 'Top',
  'products.syzd': 'Top på startsiden',
  'products.zdwz': 'Top position',
  'products.t1': 'Promotion',
  'products.t2': 'Foretrukken',
  'products.t3': 'Boutique',
  'products.t4': 'ingen',

  // anmeldelser
  'reviews.title': 'Produktanmeldelser',
  'reviews.product.name': 'Produkt',
  'reviews.user.name': 'Bruger',
  'reviews.star': 'Bedømmelse',
  'reviews.comment': 'Kommentarindhold',
  'reviews.sku': 'Produktspecifikationer',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Dato',

  // butik. ordre
  'store.order.purchase': 'Køb nu',
  'store.order.purchase.desc': 'Er du sikker på, at du vil betale for dette produkt?',
  'store.order.purchase.yes': 'Betal nu',
  'store.order.purchase.no': 'Annuller',
  'store.order.desc': 'Køb af denne ordre kræver betaling, og du vil få fordele efter ordren er gennemført. ',
  'store.order.no': 'Ordrenummer',
  'store.order.number': 'Quantity',
  'store.order.buyer': 'Køber',
  'store.order.total': 'Samlet beløb',
  'store.order.will.earning': 'Indkomst',
  'store.order.profit': 'Profit',
  'store.order.dividends': 'Dividends',
  'store.order.payment.status': 'Betalingsstatus',
  'store.order.seller.buy.status': 'Købsstatus',
  'store.order.status': 'Ordrestatus',
  'store.order.date': 'bestillingsdato',
  'store.order.purchase.date': 'Købsdato',
  'store.order.action': 'Drift',
  'store.order.purchase.amount': 'Købsbeløb',

  //indkomst
  'income.create.at': 'Rekord tid',
  'income.order.sn': 'Ordrenummer',
  'income.realpay': 'Ordrebeløb',
  'income.profit': 'indkomst',

  //Indstilling
  'setting.avatar': 'Forretningsavatar',
  'setting.upload': 'Upload',
  'setting.shop.name': 'Butiksnavn',
  'setting.shop.phone': 'Kontakttelefon',
  'setting.shop.address': 'Butiksadresse',
  'setting.shop.save': 'Gem',
  'setting.upload.pic': 'Upload billede',
  'setting.send.pic': 'Send billede',

  //behandler
  'beseller.title': 'Ansøg om at blive købmand',
  'beseller.account': 'Kontooplysninger',
  'beseller.store': 'Butiksinformation',
  'beseller.store.name': 'Butiksnavn',
  'beseller.store.address': 'Butiksadresse',

  //savehome
  'store.home.title': 'Startsideindstillinger',
  'store.home.topimg': 'topbillede',
  'store.home.banner': 'Karruselbillede',
  'store.home.up3': 'Bemærkninger: Mindst 3 uploads',
  'store.upload.more': 'Upload flere billeder',
  'store.home.columns': 'Kolonner',
  'store.home.bgimg': 'Baggrundsbillede',
  'store.goods.remark': 'Bemærkning: 5 varer pr. linje, højst 10 varer pr. plade',
  'store.home.select': 'Vælg produkt',
  'store.home.add': 'Tilføj kolonne',

  //personlig information
  'setting.update.user': 'Personlige oplysninger',
  'setting.update.info': 'Rediger oplysninger',
  'setting.user.avatar': 'Brugeravatar',
  'setting.user.nickname': 'Brugerkaldenavn',
  'setting.user.nickname.desc': 'Indtast venligst brugerens kaldenavn',

  'setting.safe.title': 'Sikkerhedsoplysninger',
  'setting.user.passwd': 'Skift adgangskode',
  'setting.passwd.title1': 'Gammel adgangskode',
  'setting.passwd.desc1': 'Indtast venligst den gamle adgangskode',
  'setting.passwd.title2': 'Ny adgangskode',
  'setting.passwd.desc2': 'Indtast venligst en ny adgangskode',
  'setting.passwd.title3': 'Bekræft adgangskode',
  'setting.passwd.desc3': 'Indtast venligst en bekræftelsesadgangskode',
  'setting.invitation': 'Inviter venner',
  'setting.copy': 'Kopier',

  'adv.add.products': 'Tilføj salgsfremmende produkter',
  'adv.list.title': 'Produktpromovering',
  'adv.type': 'Promotion Type',
  'adv.begin.time': 'Kampagnetid',
  'adv.end.time': 'Sluttid',
  'adv.status': 'Status',
  'adv.status.s1': 'Promovering',
  'adv.status.s2': 'Slut',
  'adv.add.back': 'Vend tilbage til oprykningslisten',
  'adv.recharge': 'Oplad kampagnepoint',
  'adv.select.goods': 'Vælg varer',
  'adv.select.btn': 'Promoverede produkter',
  'adv.recharge.title': 'Recharge Promotion Points',
  'adv.recharge.balance': 'Punktbalance',
  'adv.point': 'point',
  'adv.point.rate': 'Point ratio',
  'adv.recharge.amount': 'Opladningsbeløb',
  'adv.recharge.input.amount': 'Indtast genopladningsbeløb',
  'adv.select.mod': 'Vælg panel',
  'adv.mod1': 'Anbefalede kolonner',
  'adv.mod2': 'Foretrukken kolonne',
  'adv.mod3': 'Hot salg kolonne',
  'adv.mod4': 'Fremragende liste',
  'adv.mod5': 'Klassifikationsliste',
  'adv.mod6': 'Detaljeret kampagne',
  'adv.ge': 'Mængde',
  'adv.xs': 'time',
  'adv.xzbk': 'Vælg plade',
  'adv.syme': 'Resterende kvote',
  'adv.tgsc': 'Kampagnens varighed',
  'adv.tgjg': 'Kampagnepris',
  'adv.syye': 'Nuværende saldo',
  'adv.tgye': 'Promotion balance',
  'adv.yj1': 'forventet',
  'adv.yj2': 'Start kampagne',
  'adv.pay.point': 'Forbrugspoint',

  'wallet.ykcsxf': 'Ekspeditionsgebyret er fratrukket',
  'wallet.sxf': 'Servicegebyr',
  'wallet.zxje': 'Minimumsbeløb',
  'wallet.24hxe': '24-timers grænse',
  'wallet.desc.text': 'Under nogen omstændigheder vil embedsmanden ikke bede dig om at overføre penge til en konto, og den vil heller ikke bede dig om en bekræftelseskode. Deltag ikke i ulovlige aktiviteter såsom køb på vegne af andre, hvidvaskning af penge og ulovlig fundraising, og pas på onlinesvindel',
  'wallet.cunbi.text': 'Du kan kun indbetale {name} til denne adresse, andre aktiver vil ikke blive hentet',

  'credit.title': 'Kreditgrænse',
  'credit.ed': 'Kvote',
  'credit.hk': 'Tilbagebetaling',
  'credit.kyye': 'Tilgængelig saldo',
  'credit.dqqk': 'Løbende restance',
  'credit.hkje': 'Tilbagebetalingsbeløb',
  'credit.hkje.desc': 'Indtast venligst tilbagebetalingsbeløbet',
  'credit.cgje': 'Købsbeløb',
  'credit.qrz': 'Gå til godkendelse',
  'credit.srxyj': 'Indtast kreditbetalingsbeløb',

  'store.gzl': 'Beløb for opmærksomhed',
  'store.wxz': 'Ubegrænset',

  'auth.smrz': 'Autentificering med rigtige navn',
  'auth.rzlx': 'Type',
  'auth.sfzm': 'ID-kort foran',
  'auth.sffm': 'Bagsiden af ​​ID-kortet',
  'auth.zsxm': 'rigtige navn',
  'auth.zjhm': 'Dokumentnummer',
  'auth.yyzz': 'Virksomhedslicens',
  'auth.gsmc': 'Virksomhedsnavn',
  'auth.shz': 'Revision',
  'auth.ytg': 'Bestået',
  'auth.ybh': 'Afvist',
  'auth.zt': 'status',
  'auth.gr': 'Personligt',
  'auth.gs': 'Enterprise',
  'auth.ljtj': 'Ansøg om godkendelse',
  'auth.wrz': 'Ugodkendt',

  'credit.qts': 'Se beskrivelse',
  'store.spss': 'Overste grænse for varer',
  'store.dpfh': 'Butiksudbytte',
  'store.qydj': 'Aktieniveau',
  'store.level': 'niveau',
  'store.jian': 'pieces',

  'order.notify.email': 'På grund af e-mail-risikokontrol vil e-mails med platformordrepåmindelser blive opsnappet hyppigt. For at modtage dem i tide, anbefales det at tilføje service@nextstore.etxts.com til login-e-mail-hvidlisten',

  'setting.sjxx': 'Virksomhedsoplysninger',
  'setting.smrz': 'Autentificering af rigtige navn',
  'setting.aqzx': 'Sikkerhedscenter',
  'setting.tzxx': 'Meddelelsesoplysninger',
  'setting.dpzx': 'Dekorationshjemmeside',
  'setting.sjqy': 'Forretningsinteresser',
  'setting.sjdjqy': 'Beskrivelse af fordele på forretningsniveau',

  'setting.fhbky': 'Den aktuelle udbytte er ikke tilgængelig',
  'setting.spgzyzy': 'Butikregler og retningslinjer',

  'desc.dqqy': 'Nuværende egenkapital',
  'desc.xjqy': 'Underordnede interesser',
  'desc.sjqy_0': 'Butiks kreditgrænse',
  'desc.sjqy_1': 'Store udbytte',
  'desc.sjqy_2': 'Antal produkter, der kan publiceres',

  'share.yqlb': 'Invitationsliste',
  'share.zmj': 'Sælger i alt',
  'share.zdd': 'Samlet ordre',
  'share.zsy': 'Samlet mæglerindtægt',
  'share.wcl': 'ubehandlet',
  'share.ycl': 'Behandlet',
  'share.ywc': 'Udført',
  'share.dpmc': 'Butiksnavn',
  'share.ddl': 'Ordremængde',
  'share.jjsy': 'Mæglerindkomst',
  'share.zts': 'Samlet antal',

  'chat.state': 'state',
  'chat.zx': 'Online',
  'chat.ljz': 'Opretter forbindelse',

  'bind.wallet.title': 'Tilbagetrækningsadresse',
  'bind.address.title': 'Tilføj adresse',
  'bind.bj': 'Rediger',
  'bind.sc': 'Slet',
  'bind.qrsc': 'Er du sikker på, at du vil slette denne adresse?',
  'bind.qbdz': 'Wallet adresse',
  'bind.tjsj': 'Tilføj tid',
  'bind.cz': 'Operation',
  'withdrawal.address.choice': 'Vælg venligst tilbagetrækningsadressen!',

  'order.sxsj': 'Effektiv tid',
  'order.ljsj': 'virksom øjeblikkeligt',
  'order.dssj': 'Tidsindstillet effektiv',

  'store.st.zt': 'Butiksstatus',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Din butik er normal, fortsæt venligst',
  'store.st.yc': 'Undtagelse',
  'store.st.yc.desc': 'Din butik har nogle unormale ordrer eller modtaget kundeklager',
  'store.st.dj': 'Frys',
  'store.st.dj.desc': 'Din butik har en vis driftsrisiko, og midlerne er frosset',
  'store.st.jy': 'deaktiveret',
  'store.st.jy.desc': 'Din butik har overtrådt de platformsrelaterede aftaler og regler og er blevet deaktiveret',

  'task.sy': 'Hjemmeside',
  'task.rwlbo': 'Opgaveliste',
  'task.rwxqo': 'Opgavedetaljer',
  'task.ljcy': 'Deltag nu',
  'task.gdrw': 'Flere opgaver',
  'task.rwlb': 'Liste over gribeopgaver',
  'task.qbrw': 'Alle opgaver',
  'task.wcyd': 'Jeg deltog i',
  'task.qdz': 'Få ordrer',
  'task.ywc': 'Udført',
  'task.djy': 'skal handles',
  'task.ddje': 'Ordrebeløb',
  'task.cyrs': 'Antal deltagere',
  'task.ewsy': 'Yderligere fordele',
  'task.jzsj': 'Deadline',
  'task.ckxq': 'Se detaljer',
  'task.rwxq': 'Opgavedetaljer',
  'task.cylb': 'Deltagelsesliste',
  'task.qdcyrw': 'Er du sikker på, at du vil deltage i denne ordreopgave?',
  'task.sd': 'Ja',
  'task.bue': 'nej',

  'task.wdsy': 'Omsætning',
  'task.wdfh': 'Udbytte',
  'task.wdqd': 'Få ordre',
  'task.dd.leixin': 'Ordretype',
  'task.dd.qdrw': 'Hurtig ordreopgave',
  'task.dd.ptdd': 'Almindelig ordre',

  'flow.lltg': 'Flow Promotion',
  'flow.gmll': 'Køb datapakke',
  'flow.dqtc': 'Nuværende pakke',
  'flow.tc1': 'Pakke',
  'flow.sy2': 'resterende',
  'flow.tian3': 'dag',
  'flow.jlzs': 'Samlet antal poster',
  'flow.lltc': 'Flow-pakke',
  'flow.ll': 'flow',
  'flow.gmsj': 'Købstid',
  'flow.tcjg': 'Pakkepris',
  'flow.sjzf': 'faktisk betaling',
  'flow.tcsm': 'Pakkebeskrivelse',
  'flow.tcsm.sm': 'Køb af denne pakke vil øge butikkens eksponering og få mere kundetrafik. For at få flere ordrer og øge butikkens omsætning. ',

  'flow.tcmc': 'Pakkenavn',
  'flow.gmsc': 'Købsvarighed',
  'flow.zje': 'Samlet beløb',
  'flow.jzsj': 'Deadline',
  'flow.gmrq': 'Købsdato',
  'flow.tian1': 'dag',
  'flow.zhou1': 'uge',
  'flow.yue1': 'måned',

  'share.dj': 'niveau',

  'share.hz': 'Overfør',
  'share.hzd': 'Overfør til',
  'share.qbye': 'wallet balance',
  'share.tgje': 'Kampagnebeløb',
  'share.hzje': 'Overførselsbeløb',
  'share.qsrhzje': 'Indtast venligst overførselsbeløbet',

  '_inv._title': 'Invitationsbeskrivelse',
  '_inv._t1': '1. Invitation og inviteret forhold',
  '_inv._desc1': 'A inviterer B, B inviterer C, C inviterer D. Det højeste niveau er 4, hvis D inviterer E igen, så har E intet med A at gøre. ',
  '_inv._t2': '2. Inviteren får en belønning',
  '_inv._desc2': 'A får 4 % af det samlede beløb, der er fuldført af ordre B, A får 2 % af det samlede beløb, der er gennemført af ordre C, og A får 1 % af det samlede beløb, der er fuldført ved ordre D',

  '_index._gm': 'Køb',
  '_index._sm': 'Beskrivelse: Øg butikseksponering og tiltrække trafik',

  '_shop._update': '{n} gange/måned, ændret {m} gange i den aktuelle måned',

  '_chat._hc': 'Træk tilbage',

  '_footer._suom': 'Politikbeskrivelse',

  '_footer._platform._qualification': 'Virksomhedskvalifikation',
  '_store._ptzz': 'Næste butiksplatform relaterede kvalifikationer',

  '_abt._gywm': 'Om os',
  '_abt._syms': 'Forretningsmodel',
  '_abt._qyzr': 'Virksomhedsansvar',
  '_abt._lxwm': 'Kontakt os',

  '_wsj._wsjms': 'Fejr Halloween med stil i vores uhyggelige Halloween-butik. Uanset om du leder efter et trick eller en godbid i denne uhyggelige sæson, har vores kollektion af Halloween-kostumer, dekorationer og tilbehør noget for hele familien. Lav et rystende statement med vores dame- og herrekollektioner, eller slip dit barns fantasi løs med fancy kostumer. Fra uforglemmelige søde boligartikler og legetøj til glamourøs skønhed og kosmetik, find de perfekte stykker til at fejre årets mest spændende ferie.',

  '_wsj._b1': 'Efterårsindretning til hjemmet',
  '_wsj._b2': 'Græskar',
  '_wsj._b3': 'Hjem',
  '_wsj._b4': 'Børn',
  '_wsj._b5': 'Babys',
  '_wsj._b6': 'Kvinder',
  '_wsj._b7': 'Fancy kjole',
  '_wsj._b8': 'Personliggjort',

  '_wsj._cj': 'Deltag',
  '_wsj._gz': 'Aktivitetsregler',
  '_wsj._flss': 'Kategorisøgning',
  '_wsj._wsnz': 'Halloween dametøj',
  '_wsj._wsn': 'Halloween herretøj',
  '_wsj._wst': 'Halloween børnetøj',
  '_wsj._wszs': 'Halloween dekoration',
  '_wsj._wslp': 'Halloween gave',

  '_wsj._desc1': '"Halloween Special, Trick or Treat!" I oktober har vi forberedt en karnevalsfest til dig! Kom og snup alle slags skræmmende og chokerende produkter, store rabatter venter på dig! ',
  '_wsj._lkm': 'Gå på indkøb',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween kommer',
  '_wsj._mzbt': 'Skønhed adventskalendere',
  '_wsj._mznr': 'Nedtællingen til den dejligste tid på året er lige landet med adventskalendere, der passer til alle.',

  '_chr._tt1': 'Shop hele julen',
  '_chr._tt2': 'Juledekorationer',
  '_chr._tt3': 'Shop alle gaver',
  '_chr._tt4': 'Shop alt julenattøj',

  '_chr._dt1': 'Optakten til jul er lige så magisk som den store dag. Så få dine gaver sorteret, dine haller pyntet op, og din matchende pyjamas klar til julens magi.',

  '_chr._dtt1': 'Juletøj',
  '_chr._dtt2': 'Matching Family Christmas',
  '_chr._dtt3': 'Adventskalendere',
  '_chr._dtt4': 'Jul derhjemme',
  '_chr._dtt5': 'Hyggelig butik',
  '_chr._dtt6': 'Møbler i tide til jul',

  '_chr._tre1': 'Trending nu',
  '_chr._tre2': 'Føj lidt festlig sjov til dit hjem med de trends, alle elsker.',
  '_chr._tre3': 'Gingerbread',
  '_chr._tre4': 'Julebudding',
  '_chr._tre5': 'Jule Gonks',

  '_chr._ttre1': 'Kort og indpakningspapir',
  '_chr._ttre2': 'Legetøj og spil',
  '_chr._ttre3': 'Mad- og drikkegaver',
  '_chr._ttre4': 'Stock Fillers',
  '_chr._ttre5': 'Hemmelig julemand',
  '_chr._ttre6': 'Babys første jul',
  '_chr._ttre7': 'Skønhedsgaver',
  '_chr._ttre8': 'Premiumgaver',
  '_chr._ttre9': 'Personlige gaver',

  '_sdj._sdnz': 'Dametøj',
  '_sdj._sdn': 'Herretøj',
  '_sdj._sdetlw': 'gave',
  '_sdj._sdzs': 'Dekoration',
  '_sdj._sdjj': 'legetøj',

  '_sdj._title1': 'Julen kommer',
  '_sdj._desc1': 'Jul: sæsonen for at give og taknemmelighed." I december har vi forberedt en smukt dekoreret og varm familiefest til dig! Kom og snup en række lyse og varme produkter med store rabatter, der venter på dig! ',

  '_ssd._lhpp': 'Fleksibel betaling',
  '_ssd._lzhq': 'Source Global',
  '_ssd._pwyx': 'vurderet som fremragende',
  '_ssd._qsth': 'Nem retur',
  '_ssd._jgppcn': 'Prismatching og forpligtelse',
  '_ssd._ztbt': 'Opret en global one-stop shopping',
  '_ssd._yzspl': 'Indhent lagerbeholdning direkte fra over 8.000 uafhængige mærker',
  '_ssd._zcgm': 'Tilmeld dig for at købe',
  '_ssd._zccs': 'Tilmeld dig til salg',

  '_ssd._dlwz': 'Log ind og registrer',

  '_ssd._qjrgwc': 'Læg alle til indkøbskurv',

  '_zddy._bdhb': 'Lokal valuta',
  '_zddy._xzbdhb': 'Vælg lokal valuta',
  '_zddy._zje': 'Samlet beløb',
  '_zddy._xzzffs': 'Vælg venligst den betalingsmetode, der passer til dig',
  '_zddy._jmhb': 'Cryptocurrency',
  '_sjsy._ddpl': 'Ordreliste',
  '_zddy._fhsy': 'Forside',
  '_yhqd._czbz': 'Kontakt venligst dedikeret kundeservice for at bekræfte genopladningsoplysningerne før genopladning.',
  '_yhqd._lxkf': 'Kontakt kundeservice',
  '_yhqd._qsrje': 'Indtast venligst genopladningsbeløbet',
  '_yhqd._wrhyhtd': 'Ingen bankadgang',
  '_yhqd._yhzz': 'Bankoverførsel',

  '_yhbt._yhlx': 'Banktype',
  '_txcd._qsrtxje': 'Indtast venligst udbetalingsbeløbet',
  '_txcd._skr': 'Betalingsmodtager',
  '_txcd._skr._ms': 'Indtast venligst kortholders navn',
  '_txcd._skzh': 'Betalingskontonummer',
  '_txcd._skzh._ms': 'Indtast venligst betalingskontonummeret',
  '_txcd._tjyhk': 'Tilføj bankkort',
  '_txcd._txje': 'Udbetalingsbeløb',
  '_txcd._wdyhdz': 'Bank adresse',
  '_txcd._yhdm': 'kode',
  '_txcd._yhdm._ms': 'Indtast venligst bankkode',
  '_txcd._yhdz': 'adresse',
  '_txcd._yhdz._ms': 'Indtast venligst bankadresse',
  '_txcd._yhlx': 'type',
  '_txcd._yhlx._ms': 'Indtast venligst bankkorttype',
  '_txcd._yhly': 'routing',
  '_txcd._yhly._ms': 'Indtast venligst bankdirigering',
  '_txcd._yhmc': 'bank',
  '_txcd._yhmc._ms': 'Indtast venligst banknavn',
  '_txcd._yhtx': 'Bank kontant hævning',

  '_jys._gmjmhb': 'Køb cryptocurrency',
  '_jys._gmzy': 'Du kan registrere en konto og købe kryptovaluta ved at gå til følgende børser og i henhold til dit nuværende lands politikker. Så genoplad til platformskontoen! '
}
