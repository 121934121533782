<template>
  <div class="nav-header">
    <!-- <span class="nv-title">{{ $t('store.setting') }}</span> -->
    <!-- <span class="nv-desc">这是订单的管理页面，可管理用户的订单</span> -->
  </div>
  <!-- 3 -->
  <div class="top-banner-container" style="margin: 3px 3px" v-if="key == 'halloween'">
    <div class="top-banner" :style="'background-image: url(' + require('@/static/img2/120.png') + '); background-size: 100% 100%'">
      <div class="tb-box">
        <router-link class="tb-join" :to="{ name: 'StoreAddFromHouse', query: { search: 'halloween' } }"> {{ $t('_wsj._cj') }} </router-link>
        <!-- <router-link class="tb-rule" :to="{ name: 'storeDocs', query: { key: 'halloween' } }"> {{ $t('_wsj._gz') }} </router-link> -->
      </div>
    </div>
  </div>

  <div class="top-banner-container" style="margin: 3px 3px" v-if="key == 'christmas'">
    <div class="top-banner" :style="'background-image: url(' + require('@/static/img2/121.png') + '); background-size: 100% 100%'">
      <div class="tb-box">
        <router-link class="tb-join" :to="{ name: 'StoreAddFromHouse', query: { search: 'christmas' } }"> {{ $t('_wsj._cj') }} </router-link>
        <!-- <router-link class="tb-rule" :to="{ name: 'storeDocs', query: { key: 'halloween' } }"> {{ $t('_wsj._gz') }} </router-link> -->
      </div>
    </div>
  </div>

  <a-card>
    <!-- <div style="margin-bottom: 10px; text-align: center">
      <h3>{{ docs.title }}</h3>
    </div> -->
    <a-form name="basic" :label-col="{ span: 4 }" :wrapper-col="{ span: 24 }" v-if="docs">
      <div class="b-content" style="" v-html="docs.content"></div>
    </a-form>
  </a-card>
</template>

<script>
export default {
  name: 'settingVue',
  components: {},
  data() {
    return {
      key: '',
      docs: {}
    }
  },
  watch: {
    $route(vNew) {
      console.log(vNew)
      this.key = this.$route.query.key
      this.getDocs()
    }
  },
  created() {
    this.key = this.$route.query.key
    this.getDocs()
  },
  methods: {
    getDocs() {
      if (this.key) {
        this.$api.docs.getDocs({ key: this.key }).then((res) => {
          this.docs = res.data
        })
      }
    }
  }
}
</script>

<style scoped>
.b-content >>> td {
  border: 1px solid #dfdfdf !important;
}

.b-content >>> p {
  padding: 0 !important;
}
</style>
