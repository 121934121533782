<template>
  <!-- 搜索框 -->
  <div id="category" class="search-header-wrap -fixed-header">
    <HeadNav></HeadNav>
    <!-- 搜索框 -->
    <!-- <SearchBox @onSearch="onSearch"></SearchBox> -->
  </div>
  <!-- 内容 -->
  <div id="root" style="margin-top: 121px">
    <div class="glosearch-wrap">
      <div class="page-content">
        <div class="store-direct-container"></div>
        <div class="main-content">
          <!-- 左侧代码 -->
          <div sstyle="width: 240px; height: 700px">
            <div class="next-affix left-affix" sstyle="width: 240px; position: fixed; top: 88px">
              <div class="left-menu-container" sstyle="max-height: 700px">
                <div class="left-refine-container">
                  <!-- 分类列表 -->
                  <div class="refine-block category" style="padding-top: 0px">
                    <div class="title">{{ $t('category.title') }}</div>
                    <div class="content">
                      <ul>
                        <li style="padding-left: 8px">
                          <a href="javascript:">
                            <left-outlined />
                            <span class="active">{{ categoryList.name }}</span>
                          </a>
                        </li>
                        <li style="padding-left: 20px">
                          <ul class="child-menu">
                            <li class="" v-for="(item, i) in categoryList.category" :key="i">
                              <ul class="category--childMenu--1J3M2bi">
                                <span class="active">{{ item.name }}</span>
                                <li style="padding-left: 20px" v-for="(vo, k) in item.category" :key="k">
                                  <a target="_self" class="category--link--1pMPE_T" @click="onSelect(vo, i, k)">{{ vo.name }}</a>
                                </li>
                              </ul>
                              <!-- <router-link :to="{name:'category',query:{id:item.id,level:item.level}}">{{item.name}}</router-link> -->
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--右边内容  -->
          <div class="right-menu">
            <div class="product-container">
              <!-- 顶部筛选 -->
              <div class="top-container">
                <!-- 当前导航 -->
                <div class="nav-breadcrumb">
                  <div aria-label="Breadcrumb" class="next-breadcrumb">
                    <div class="next-breadcrumb-item">
                      <a href="javascript:" class="next-breadcrumb-text">{{ $t('category.all') }}</a>
                      <span class="next-breadcrumb-separator">
                        <i class="next-icon next-icon-arrow-right next-medium"></i>
                      </span>
                    </div>
                    <div class="next-breadcrumb-item">
                      <span class="next-breadcrumb-text activated" aria-current="page"
                        ><span style="font-weight: 600; color: rgb(0, 0, 0)">"{{ currentCate.name }}"</span></span
                      >
                    </div>
                  </div>
                </div>
                <!-- 筛选 -->
                <div class="top-refine">
                  <!-- 价格 -->
                  <!-- <div class="first">
                    <span class="price-input ltr" style="display: inline-block">
                      <span class="price-text">Price:</span>
                      <span class="next-input next-small min-price" style="width: 64px">
                        <input placeholder="min" height="100%" autocomplete="off" value="" />
                      </span>
                      -
                      <span class="next-input next-small" style="width: 64px">
                        <input placeholder="max" height="100%" autocomplete="off" value="" />
                      </span>
                    </span>
                  </div> -->
                  <div class="sort">
                    <!-- 排序 -->
                    <div class="refine-item refine-sortby">
                      <span class="sort-title">Sort by:</span>
                      <span class="sort-by-wrapper">
                        <span class="sort-item active">Best Match</span>
                        <span class="sort-item">Orders </span>
                        <span class="sort-item"
                          >Price
                          <svg class="svg-icon m price-order">
                            <use xlink:href="#iconrank-"></use>
                          </svg>
                        </span>
                      </span>
                    </div>
                    <!-- 排列方式 -->
                    <div class="none-rtl display-mode">
                      <div class="text">View:</div>
                      <svg :class="['svg-icon m', mode == showMode.square ? 'active' : '']" @click="onSquare()">
                        <use xlink:href="#icongallery"></use>
                      </svg>
                      <svg :class="['svg-icon m', mode == showMode.list ? 'active' : '']" @click="onList()">
                        <use xlink:href="#iconlist"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 商品列表 -->
              <div :class="[mode == showMode.square ? 'JIIxO' : '_2AlTf']">
                <router-link :to="{ name: 'detail', query: { id: item.id } }" v-for="(item, i) in goodsList.data" :key="i" :class="['_3t7zg', mode == showMode.square ? '_2f4Ho' : '_2QFcF']">
                  <div class="_3A0hz gYJvK" style="padding-bottom: 100%">
                    <img class="_1RtJV product-img" :src="item.img" :alt="item.name" />
                    <div>
                      <div class="report-btn-wrap">
                        <span class="report-item" title="Report fraud item"></span>
                      </div>
                    </div>
                    <div class="atwl-btn-wrap">
                      <a class="add-wishlist-btn" data-p4p="true">
                        <i data-p4p="true" class="next-icon next-icon-favourite next-medium"></i>
                      </a>
                    </div>
                  </div>
                  <div class="_3GR-w">
                    <div class="_1tu1Z Vgu6S">
                      <h1 class="_18_85">{{ item.name }}</h1>
                    </div>
                    <div class="mGXnE _37W_B">
                      <span style="font-size: 12px">US $</span>
                      <span style="font-size: 20px">{{ parseInt(item.price) }}</span>
                      <span style="font-size: 12px">.</span>
                      <span style="font-size: 12px">{{ $common.cutPrice(item.price) }}</span>
                    </div>
                    <div class="_11_8K"></div>
                    <div class="ZzMrp">
                      <span class="_1kNf9">{{ item.orders }} sold</span>
                      <!-- <img class="_11Omk" src="../static/img/star.png" height="11" width="12" /> -->
                      <!-- 空星 -->
                      <div style="display: flex; align-items: center; justify-content: flex-start">
                        <div class="next-rating-base next-rating-base-disabled">
                          <div class="next-rating-underlay" aria-hidden="true">
                            <span class="next-rating-icon" v-for="i in parseInt(item.star)" :key="i">
                              <i class="next-icon next-icon-favorites-filling next-medium" style="color: #ffb500"></i>
                            </span>
                            <span class="next-rating-icon" v-for="i in 5 - parseInt(item.star)" :key="i">
                              <i class="next-icon next-icon-favorites-filling next-medium"></i>
                            </span>
                          </div>
                        </div>
                        <span class="eXPaM">{{ item.star }}</span>
                      </div>
                    </div>
                    <div class="KnIS- _4juNd">
                      <div><span class="_2jcMA">Free Shipping</span></div>
                    </div>
                    <div class="_21aoB"></div>
                    <span class="_7CHGi">
                      <router-link :to="{ name: 'storeHome', query: { id: item.store.id } }" class="ox0KZ">
                        {{ item.store.name }}
                      </router-link>
                    </span>
                  </div>
                </router-link>
              </div>
              <!-- 分页 -->
              <div class="list-pagination">
                <div class="product-pagination-wrap">
                  <div class="next-pagination next-medium next-normal">
                    <div class="next-pagination-pages">
                      <button @click="onPrevious()" :disabled="goodsList.pageNo == 1" type="button" class="next-btn next-medium next-btn-normal next-pagination-item next-prev" role="button">
                        <i class="next-icon next-icon-arrow-left next-xs next-btn-icon next-icon-first"></i>
                        {{ $t('base.previous') }}
                      </button>
                      <div class="next-pagination-list">
                        <template v-for="i in goodsList.totalPage" :key="i">
                          <button @click="onPage(i)" type="button" :class="['next-btn next-medium next-btn-normal next-pagination-item', goodsList.pageNo == i ? 'next-current' : '']" role="button" v-if="i > goodsList.pageNo - 8 && (i < goodsList.pageNo + 8 || i < 16)">{{ i }}</button>
                        </template>
                      </div>
                      <button @click="onNext()" :disabled="goodsList.pageNo == goodsList.totalPage" type="button" class="next-btn next-medium next-btn-normal next-pagination-item next-next" role="button">
                        {{ $t('base.next') }}
                        <i class="next-icon next-icon-arrow-right next-xs next-btn-icon next-icon-last"></i>
                      </button>
                    </div>
                  </div>
                  <div class="jump-aera">
                    <span class="total-page"> {{ $t('base.total.page', { page: goodsList.totalPage }) }}</span>
                    <span>{{ $t('base.go.to.page') }}</span>
                    <span class="next-input next-large">
                      <input v-model="ipage" height="100%" autocomplete="off" />
                    </span>
                    <span class="jump-btn" @click="GoPage">{{ $t('base.go') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 回到顶部 -->
  <GoTop></GoTop>

  <!-- 页脚 -->
  <Footer></Footer>
</template>

<script>
import GoTop from '@/components/GoTop.vue'
import Footer from '@/components/Footer.vue'
import HeadNav from '@/components/common/HeadNav.vue'
// import SearchBox from '@/components/category/SearchBox.vue'
import { LeftOutlined } from '@ant-design/icons-vue'
const showMode = {
  square: 1,
  list: 2
}
export default {
  name: 'categoryVue',
  components: {
    GoTop,
    HeadNav,
    // SearchBox,
    Footer,
    LeftOutlined
  },
  data() {
    return {
      //显示模式
      showMode,
      mode: showMode.square,

      //参数
      query: {},

      //分类列表
      currentId: 0,
      currentLevel: 0,
      currentCate: '',
      categoryList: [],

      //商品列表
      ipage: '',
      goodsList: [],

      searchVal: ''
    }
  },
  watch: {
    $route(vNew) {
      this.currentId = vNew.query.id
      this.currentLevel = vNew.query.level
      console.log(vNew.query, '参数')

      //获取最新分类
      this.getCategory()
    }
  },
  created() {
    //初始化
    this.currentId = this.$route.query.id
    this.currentLevel = this.$route.query.level
    console.log(this.$route.query, '参数')

    if (this.$route.query.keyword) {
      this.searchVal = this.$route.query.keyword
    }

    //获取分类
    this.getCategory()
  },
  methods: {
    /**
     * 获取当前分类
     */
    getCategory() {
      let that = this
      this.$api.category.getCategory({ id: this.currentId }).then((res) => {
        that.currentCate = res.data.current
        that.categoryList = res.data.list
        //初始化
        //that.setCurrentCate()

        var str = ''
        for (var i in res.data.item) {
          str += res.data.item[i].name
          if (str != '') {
            str += ' | '
          }
        }

        window.document.title = str
      })

      //获取
      this.getGoodsList(1, this.searchVal)
    },
    /**
     * 排列方式
     */
    onSquare() {
      this.mode = showMode.square
    },
    /**
     * 列表方式
     */
    onList() {
      this.mode = showMode.list
    },
    onPage(i) {
      this.getGoodsList(i)
    },
    GoPage() {
      this.getGoodsList(this.ipage)
    },
    onPrevious() {
      this.getGoodsList(this.goodsList.pageNo - 1)
    },
    onNext() {
      this.getGoodsList(this.goodsList.pageNo + 1)
    },
    /**
     * 设置当前初始化选中
     */
    setCurrentCate() {
      //默认
      this.currentCate = ''
      this.categoryList.category.forEach((item, i) => {
        if (this.currentId == item.id) {
          this.setCurrent(i)
        }
      })
      if (!this.currentCate) {
        this.setCurrent(0)
      }
    },
    /**
     * 设置当前选中
     * @param {*} sort
     */
    setCurrent(i, k) {
      this.currentCate = this.categoryList.category[i].category[k]
    },
    /**
     * 子集分类
     * @param {*} id
     */
    onSelect(vo, i, k) {
      this.currentId = vo.id
      this.currentLevel = vo.level
      console.log(vo, i, k, '参数')

      //获取最新分类
      this.getCategory()

      //this.currentId = id
      //获取
      //this.getGoodsList(1)
      //当前分类
      //this.setCurrent(i, k)
    },
    getGoodsList(page, keyword = '') {
      //获取商品
      let hide = this.$message.loading({ content: 'loading...', key: 'msg' })
      this.$api.goods
        .getGoodsList({
          id: this.currentId,
          page: page,
          size: 60,
          keyword: keyword
        })
        .then((res) => {
          hide()
          this.goodsList = res.data
        })
    },
    onSearch(val) {
      this.getGoodsList(1, val)
    }
  }
}
</script>
<style scoped src="../static/css/home.css"></style>
