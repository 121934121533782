<template>
  <div class="footer-wrap">
    <div hidden>
      <!--gop region start regionId biz/site_server_info version 20210723.192537.165-->
      <div class="site-server-box">
        <div class="container c_flex" style="max-width: 1400px; width: auto">
          <div class="site-item">
            <i class="icon i-great">&nbsp;</i>
            <h3>{{ $t('footer.great.value') }}</h3>
            <p>{{ $t('footer.great.value.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-delivery">&nbsp;</i>
            <h3>{{ $t('footer.shopping') }}</h3>
            <p>{{ $t('footer.shopping.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-payment">&nbsp;</i>
            <h3>{{ $t('footer.safe.payment') }}</h3>
            <p>{{ $t('footer.safe.payment.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-confidence">&nbsp;</i>
            <h3>{{ $t('footer.shop.with.confidence') }}</h3>
            <p>{{ $t('footer.shop.with.confidence.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-help">&nbsp;</i>
            <h3>{{ $t('footer.help.center') }}</h3>
            <p>{{ $t('footer.help.center.desc') }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 协议 -->
    <div class="site-main-box" hidden>
      <div class="container-box" style="max-width: 1400px; width: auto">
        <router-link class="site-box" :to="{ name: 'docs', query: { key: 'terms_conditions' } }">
          <div class="icon">
            <file-done-outlined :style="{ color: '#000', fontSize: '30px' }" />
          </div>
          <h3>{{ $t('footer.terms.conditions') }}</h3>
        </router-link>
        <router-link class="site-box" :to="{ name: 'docs', query: { key: 'return_policy' } }">
          <div class="icon">
            <rollback-outlined :style="{ color: '#000', fontSize: '30px' }" />
          </div>
          <h3>{{ $t('footer.return.policy') }}</h3>
        </router-link>
        <router-link class="site-box" :to="{ name: 'docs', query: { key: 'support_policy' } }">
          <div class="icon">
            <safety-outlined :style="{ color: '#000', fontSize: '30px' }" />
          </div>
          <h3>{{ $t('footer.support.policy') }}</h3>
        </router-link>
        <router-link class="site-box" :to="{ name: 'docs', query: { key: 'privacy_policy' } }">
          <div class="icon">
            <solution-outlined :style="{ color: '#000', fontSize: '30px' }" />
          </div>
          <h3>{{ $t('footer.privacy.policy') }}</h3>
        </router-link>
      </div>
    </div>

    <!-- 脚底 -->
    <div>
      <div class="user-helper-footer" style="display: flex; align-items: center; justify-content: center">
        <div class="container c_flex" style="width: 1400px">
          <div class="col-sm-30 col-md-30 col-lg-24" style="display: flex; flex-direction: column; justify-content: space-between">
            <div class="app-box">
              <div class="social-networks" style="width: 315px">
                <img src="../static/logo128.png" alt="" style="margin-bottom: 10px; width: 220px" />
                <div class="foot-sns-box" style="display: flex; gap: 10px">
                  <div class="a-download">
                    <img src="../static/play.png" alt="" style="width: 100%; cursor: pointer" @click="onGoUrl('android')" />
                    <div class="app-download">
                      <img src="../assets/app_android.png" alt="" />
                    </div>
                  </div>
                  <div class="a-download">
                    <img src="../static/app.png" alt="" style="width: 100%; cursor: pointer" @click="onGoUrl('ios')" />
                    <div class="app-download">
                      <img src="../assets/app_ios.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="app-box" hidden>
              <div class="social-networks">
                <h3>{{ $t('footer.stay.connected') }}</h3>
                <div class="foot-sns-box">
                  <a class="fb" href="javascript:" rel="nofollow" target="_blank">&nbsp;</a>
                  <a class="twitter" href="javascript:" rel="nofollow" target="_blank">&nbsp;</a>
                  <a target="_blank" href="javascript:" class="instagram">&nbsp;</a>
                  <a target="_blank" href="javascript:" class="messenger">&nbsp;</a>
                  <a target="_blank" href="javascript:" class="whatapp">&nbsp;</a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-15 col-md-15 col-lg-12" style="width: 20%">
            <h3 class="f-link-header">{{ $t('footer.about.us') }}</h3>
            <ul class="f-link-list">
              <li style="display: flex; flex-direction: column">
                <!-- <a>{{ $t('footer.about.company') }}</a> -->
                <!-- <router-link :to="{ name: 'about_docs', query: { key: 'company_profile' } }">{{ $t('footer.about.company') }}</router-link> -->
                <router-link :to="{ name: 'about_qualification' }">{{ $t('_footer._platform._qualification') }}</router-link>
                <router-link :to="{ name: 'about_model' }">{{ $t('_abt._syms') }}</router-link>
                <router-link :to="{ name: 'about_liability' }">{{ $t('_abt._qyzr') }}</router-link>
                <router-link :to="{ name: 'about_contact' }">{{ $t('_abt._lxwm') }}</router-link>
              </li>
            </ul>
          </div>

          <div class="col-sm-15 col-md-15 col-lg-12" style="width: 20%">
            <h3 class="f-link-header">{{ $t('_footer._suom') }}</h3>
            <ul class="f-link-list">
              <li style="display: flex; flex-direction: column">
                <router-link :to="{ name: 'docs', query: { key: 'terms_conditions' } }">{{ $t('footer.terms.conditions') }}</router-link>
              </li>
              <li style="display: flex; flex-direction: column">
                <router-link :to="{ name: 'docs', query: { key: 'return_policy' } }">{{ $t('footer.return.policy') }}</router-link>
              </li>
              <li style="display: flex; flex-direction: column">
                <router-link :to="{ name: 'docs', query: { key: 'support_policy' } }">{{ $t('footer.support.policy') }}</router-link>
              </li>
              <li style="display: flex; flex-direction: column">
                <router-link :to="{ name: 'docs', query: { key: 'privacy_policy' } }">{{ $t('footer.privacy.policy') }}</router-link>
              </li>
            </ul>
          </div>

          <div class="col-sm-15 col-md-15 col-lg-12" style="width: 30%">
            <h3 class="f-link-header">{{ $t('footer.contact') }}</h3>
            <ul class="f-link-list">
              <li style="display: flex; flex-direction: column">
                <a href="javascript:" rel="nofollow">{{ $t('footer.gfemail') }}: atlnes@atlnes.com</a>
                <!-- <a href="javascript:" rel="nofollow">{{ $t('footer.fwemail') }}: service@gblspp.com</a> -->
              </li>
            </ul>
          </div>

          <div class="col-lg-12 hidden-md hidden-sm" style="width: 20%">
            <div>
              <h3 class="f-link-header">{{ $t('footer.my.account') }}</h3>
              <ul class="f-link-list">
                <li style="display: flex; flex-direction: column" v-if="isLogin">
                  <a href="javascript:" rel="nofollow" @click="onLogout()">{{ $t('footer.my.logout') }}</a>
                  <router-link :to="{ name: 'orderAll' }" v-if="user.type == 1">{{ $t('footer.my.order') }}</router-link>
                  <router-link :to="{ name: 'storeOrderList' }" v-if="user.type == 2">{{ $t('footer.my.order') }}</router-link>
                  <router-link :to="{ name: 'wishlist' }" v-if="user.type == 1">{{ $t('footer.my.wish') }}</router-link>
                  <router-link :to="{ name: 'beseller' }">{{ $t('footer.my.join') }}</router-link>
                </li>
                <li style="display: flex; flex-direction: column" v-else>
                  <!-- <a href="javascript:" rel="nofollow" @click="onLogout()">{{$t('footer.my.logout')}}</a> -->
                  <router-link :to="{ name: 'storeSignin' }">{{ $t('footer.my.order') }}</router-link>
                  <router-link :to="{ name: 'storeSignin' }">{{ $t('footer.my.wish') }}</router-link>
                  <router-link :to="{ name: 'storeSignin' }">{{ $t('footer.my.join') }}</router-link>
                </li>
              </ul>
            </div>
            <h3 class="f-link-header">{{ $t('footer.be.seller') }}</h3>
            <ul class="f-link-list">
              <li>
                <router-link :to="{ name: 'beseller' }">
                  <span class="_be_seller">{{ $t('footer.apply.now') }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- footer - 3 -->
    <!-- <Footer3></Footer3> -->
  </div>
</template>

<script>
// import Footer3 from "./footer/footer-3.vue";
import { FileDoneOutlined, RollbackOutlined, SafetyOutlined, SolutionOutlined } from '@ant-design/icons-vue'
export default {
  name: 'footerVue',
  components: {
    FileDoneOutlined,
    RollbackOutlined,
    SafetyOutlined,
    SolutionOutlined
  },
  data() {
    return {
      isLogin: false,
      user: {}
    }
  },
  created() {},
  methods: {
    onGoUrl(type) {
      console.log(type)
      if (type === 'android') {
        let url = 'https://wap.atlnes.com/#/pages/download/android'
        window.open(url, '_blank')
      } else if (type === 'ios') {
        let url = 'https://wap.atlnes.com/#/pages/download/iphone'
        window.open(url, '_blank')
      }
    },
    /**
     * 登录状态
     * 由topnav 内部调用父类触发
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.user = this.$common.getInfo()
    },
    onLogout() {
      this.$emit('onLogout', [])
    }
  }
}
</script>
<style scoped src="../static/css/index.css"></style>

<style scoped>
.site-main-box {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.container-box {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  width: 1200px;
  display: flex;
}

.site-box {
  float: left;
  padding: 15px 0;
  text-align: center;
  position: relative;
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.site-box:hover {
  background: #f4f4f4;
}

.site-box h3 {
  color: #000000;
  font-weight: 700;
  font-size: 15px;
}

.container-box:first-child {
  border-left: 1px solid #f4f4f4;
}

.site-box {
  border-right: 1px solid #f4f4f4;
}

.site-box .i-great {
  background: url('../static/index/4.png') no-repeat 0 0;
  background-size: cover;
}

.site-box .icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

._be_seller {
  display: inline-block;
  background-image: linear-gradient(94deg, #6cc9c9, #76cfcf);
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  padding: 5px 15px;
}

.c_flex {
  display: flex;
}

.app-download {
  position: absolute;
  width: 170px;
  height: 170px;
  z-index: 10;
  bottom: 100%;
  border: 2px solid #ff5722;
  display: none;
}
.app-download img {
  width: 100%;
  height: 100%;
}
.a-download {
  flex: 1;
  position: relative;
}
.a-download:hover .app-download {
  display: block;
}
</style>
