<template>
  <div class="MuiModal-root header-1esngu2">
    <div class="MuiBackdrop-root header-um19vb" style="opacity: 1; transition: opacity 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"></div>
    <div tabindex="0" data-testid="sentinelStart"></div>
    <div tabindex="-1" class="header-7p1ifs">
      <div data-testid="secondary-meganav-wide" class="header-1twzkao">
        <nav id="sec-nav-content" data-testid="sec-nav-content" aria-labelledby="meganav-link-0" class="header-nmh78a">
          <div class="header-1ky1ch9" @mouseleave="onMenuLeave()">
            <div style="transition: opacity 350ms ease-in-out 0s; opacity: 1; display: flex; flex-direction: column; flex: 1 1 0%; height: 100%">
              <div class="header-3g5z3c">
                <section class="header-w6eghn">
                  <div data-testid="catalogue" id="catalogue" class="header-16pb8ni">
                    <!-- 二级分类 -->
                    <div class="header-c2wxdd" v-for="(vo, i) in childList.category" :key="i">
                      <div class="header-o17t8w" v-if="i == 0">
                        <ul data-testid="catalogue-items" class="header-id32mj">
                          <li class="header-1qq5wn0">
                            <a title="New In" class="header-xuhat7">
                              <div class="header-1povu0j">
                                <span class="header-5h7vic">New In</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <hr class="header-10syxvh" v-if="i == 0" />
                      <div class="header-o17t8w" v-for="(vvo, ii) in vo" :key="ii">
                        <div font-weight="" class="header-embut7">
                          <span> {{ vvo.name }} </span>
                        </div>
                        <ul data-testid="catalogue-items" class="header-zvh8xj">
                          <li class="header-9cnbpg" v-for="(vvvo, iii) in vvo.category" :key="iii">
                            <router-link class="header-1qm407t" :to="{ name: 'category', query: { id: vvvo.id, level: vvvo.level } }">
                              <div class="header-1povu0j">
                                <span class="header-5h7vic">{{ vvvo.name }}</span>
                              </div>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                      <hr class="header-10syxvh" />
                    </div>
                  </div>
                </section>

                <section class="header-grsdgi">
                  <section data-testid="header-missions" class="header-1cj6rfj">
                    <div class="header-bflyx8" v-for="(vo, i) in childTop" :key="i">
                      <router-link :to="{ name: 'category', query: { id: vo.id, level: vo.level } }" class="header-2pip3d">
                        <div class="header-1ec1cta">
                          <div style="overflow: hidden; height: 100%">
                            <img alt="New Collection" :src="vo.url" class="header-1xfu378" style="height: 100%" />
                          </div>
                        </div>
                        <span class="header-mql9m8"> {{ vo.name }} </span>
                      </router-link>
                    </div>
                  </section>
                  <div class="header-ns3dcm" v-if="childList.category[0][0].category[0]">
                    <router-link :to="{ name: 'category', query: { id: childList.category[0][0].category[0].id, level: childList.category[0][0].category[0].level } }" class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium header-r0qmay" style="text-transform: none">
                      <span data-testid="missions-cta-buttonText">{{ childList.name }} SHOP</span>
                      <img alt="Menu expand icon" src="@/static/img2/chevron.svg" class="header-1k7m2sc" />
                    </router-link>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div tabindex="0" data-testid="sentinelEnd"></div>
  </div>
</template>

<script>
export default {
  name: 'categoryVue',
  props: {
    childList: {
      type: Array,
      default: () => {
        return []
      }
    },
    childTop: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    onMenuLeave() {
      this.$emit('onMenuLeave')
    }
  }
}
</script>

<style></style>
