<template>
  <div class="slick-track">
    <swiper
      :modules="modules"
      :slidesPerView="1"
      :loop="true"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false
      }"
    >
      <swiper-slide v-for="(item, i) in ImgList" :key="i">
        <div style="position: relative">
          <div style="position: absolute; top: 30%; right: 10%; display: flex; gap: 20px">
            <router-link class="tb-join" style="color: #eee; border-color: #eee; font-weight: 700" :to="{ name: 'storeDocs', query: { key: item.key } }"> {{ $t('_wsj._gz') }} </router-link>
            <router-link class="tb-join" style="color: #fff; font-weight: 700" :to="{ name: 'StoreAddFromHouse', query: { search: item.key } }"> {{ $t('_wsj._cj') }} </router-link>
          </div>
          <img :src="item.url" alt="" style="width: 100%" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { Navigation, Pagination, Autoplay } from 'swiper'
const modules = [Navigation, Pagination, Autoplay]

const ImgList = ref([
  {
    id: 1,
    key: 'halloween',
    url: require('@/static/img2/120.png')
  },
  {
    id: 2,
    key: 'christmas',
    url: require('@/static/img2/121.png')
  }
])
</script>
