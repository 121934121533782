<template>
  <div id="next-header-entrypoint" class="Alibaba-font">
    <section id="platform_modernisation_header" class="x-next-plat-mod" style="--display-primary-nav: 1">
      <button id="next-header-skip-to-content" data-testid="next-header-skip-to-content" tabindex="0" aria-label="Skip to main content" class="header-iaq7dt">Skip to main content</button>
      <header dir="ltr">
        <!-- 顶部状态 -->
        <div data-testid="header-upperheader" class="header-1lfz5tr">
          <div data-testid="header-staticheaderbanner" class="header-ehbf4n">
            <div class="header-1vwf51a">
              <span id="mt-vision-header-ribbon" class="header-1dnk6py"></span>
              <div class="header-1flbx05">
                <div class="header-1mzzuk6">
                  <!-- <a title="Store Locator" class="header-1dnk6py">Store Locator</a> -->
                  <div class="header-gybm52"></div>
                </div>
                <div class="header-1mzzuk6">
                  <!-- <a title="Help" class="header-1dnk6py">Help</a> -->
                </div>
              </div>
            </div>
          </div>
          <nav class="header-133sa23">
            <div class="header-ovpuq9">
              <div class="header-adaptive-brand header-53kqd" data-testid="header-adaptive-brand">
                <a href="/" class="header-bcqwvy">
                  <div class="header-mncr4x">
                    <img src="@/static/logo128.png" class="header-d5w927" />
                  </div>
                  <div class="header-kjwo5n">
                    <img src="@/static/logo128.png" class="header-d5w927" />
                  </div>
                </a>
              </div>
              <div data-testid="header-adaptive-search-big" class="header-adaptive-search" style="width: 100%; margin-left: 25px">
                <div data-testid="header-big-screen-search" class="header-12sneaa" style="display: flex; flex-direction: column">
                  <div class="header-1dyzhh5">
                    <div class="header-dost5">
                      <div id="header-search-form" class="header-bwguz8">
                        <label for="header-big-screen-search-box" class="header-1f9vf8v">Search product or brand</label>
                        <div required="" class="MuiInputBase-root MuiInputBase-colorPrimary header-1t576rc">
                          <div class="d-loading" v-if="searching" style="position: absolute">
                            <loading-outlined />
                          </div>
                          <input style="width: 100%" @keyup.enter="onSearch()" v-model="searchVal" @input="onInput" type="text" autocomplete="off" placeholder="Search product or brand" class="MuiInputBase-input header-mnn31" />
                        </div>
                        <button @click="onSearch()" class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium header-12f2lej" tabindex="0" type="submit">
                          <img src="@/static/img2/search-input-button.svg" alt="Search Icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="MuiBox-root header-14naf6f">
                    <div class="header-ig4kw0"></div>
                  </div>
                  <!-- 搜索商铺 -->
                  <div :class="['search-box', searchShop.length > 0 ? 'active' : '']">
                    <ul>
                      <li v-for="(item, i) in searchShop" :key="i" class="search-li" @click="onShop(item)">
                        <span class="suggest_key">
                          <span class="ui-autocomplete-item-hl">{{ item.name }}</span> ({{ item.sid }})</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- 头部 -->
            <login-state ref="loginState"></login-state>
          </nav>
        </div>

        <!-- 分类 -->
        <div id="platform_modernisation_meganav" @mouseleave="onMenuLeave()">
          <div data-testid="primary-meganav" class="header-pg9n8w">
            <div class="header-1ayz35y">
              <div class="header-pybuhu">
                <div class="header-1nvxwd2">
                  <div style="transition: opacity 350ms ease-in-out; opacity: 0">
                    <div class="header-1yv8ntl">
                      <button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeLarge header-1gzpori" tabindex="-1" type="button" data-testid="meganav-primarynav-arrow-left"><img src="@/static/img2/chevron-left.svg" alt="Chevron icon" width="0.4375" class="header-1bvcxp" /></button>
                      <div class="header-1nya8j"></div>
                    </div>
                  </div>
                </div>
                <!-- 菜單 -->
                <ul data-testid="snail-trail-container" id="snail-trail-container" style="justify-content: var(--platmod_meganav_alignment, space-between)" class="header-eyt1ar">
                  <li :class="[currentMenu == i ? 'header-xjlyw' : 'header-1i6zncs']" @mouseenter="onMenuEnter(i)" v-for="(vo, i) in categoryList" :key="i">
                    <a>
                      <div font-weight="600" :class="[currentMenu == i ? 'header-l3omv2' : 'header-14mi5yk']">{{ vo.name }}</div>
                      <span :class="[currentMenu == i ? 'header-1pfy3s7' : 'header-d08108']"></span>
                    </a>
                  </li>
                </ul>
                <div class="header-1ibjmlb">
                  <div style="transition: opacity 350ms ease-in-out 0s; opacity: 0">
                    <div class="header-9dccvu">
                      <button class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeLarge header-1gzpori" tabindex="-1" type="button" data-testid="meganav-primarynav-arrow-right"><img src="@/static/img2/chevron-left.svg" alt="Chevron icon" width="0.4375" class="header-1bvcxp" /></button>
                      <div class="header-1nya8j"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
          <!-- 分類列表 -->
          <CategoryList :childList="childList" :childTop="childTop" @onMenuLeave="onMenuLeave" @onMenuEnter="onMenuEnter" v-if="currentMenu >= 0"></CategoryList>
        </div>
      </header>
    </section>
  </div>
</template>

<script>
import CategoryList from './CategoryList.vue'

import { getCurrentInstance } from 'vue'
import { setLangauge } from '@/utils/vue-i18n'
import { LoadingOutlined } from '@ant-design/icons-vue'
import LoginState from './LoginState.vue'
export default {
  name: 'indexVue',
  components: {
    CategoryList,
    LoadingOutlined,
    LoginState
  },
  setup() {
    const { proxy } = getCurrentInstance()
    function change(lang) {
      proxy.$i18n.locale = lang
      setLangauge(lang)
      console.log('切换语言', lang)
    }
    return { change }
  },
  data() {
    return {
      categoryList: [],
      childList: [],
      childTop: [],
      currentMenu: -1,

      isShowSignin: false,

      //消息
      msgCenter: {
        msg: 0,
        order: 0,
        cart: 0
      },

      //语言
      language: {
        text: 'English',
        lang: 'en-us'
      },

      //搜索
      searchVal: '',
      searchShop: [],
      searching: false,

      sp_info: {},
      isLogin: false
    }
  },
  created() {
    if (this.$route.query.token) {
      let token = this.$route.query.token
      this.onAutoLogin(token)
    } else {
      this.tokenStatus()
    }

    this.getCategory()
  },
  methods: {
    onAutoLogin(token) {
      let that = this
      localStorage.setItem('sp_token', token)
      that.$api.auth.autoLogin({ token: token }).then((res) => {
        //存入缓存
        let info = res.data
        delete info.token
        localStorage.setItem('sp_info', JSON.stringify(info))
        that.tokenStatus()
        setTimeout(function () {
          that.$router.push({ name: 'index' })
        }, 1000)
      })
    },
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
      this.$emit('tokenStatus', [])
    },
    signinEvent() {
      //转调用HeadNav
      this.$refs.loginState.onSignup()
    },
    onInput() {
      //通知上级
      console.log(this.searchVal)
      // 搜索符合的店铺名称
      this.searching = true

      let that = this
      this.$api.home.searchShop({ search: this.searchVal }).then((res) => {
        that.searching = false
        that.searchShop = res.data
      })
    },
    onShop(val) {
      this.$router.push({ name: 'storeHome', query: { id: val.id } })
    },
    onSearch() {
      this.searchShop = []
      this.$router.push({ name: 'category', query: { id: 0, level: 0, keyword: this.searchVal } })
    },
    onMenuEnter(i) {
      // console.log('in', i)
      this.currentMenu = i
      this.childList = this.categoryList[i]
      this.childTop = this.categoryList[i].top
    },
    onMenuLeave(i) {
      console.log('out', i)
      this.currentMenu = -1
    },
    getCategory() {
      const that = this
      this.$api.category.getCategoryList().then((res) => {
        that.categoryList = res.data
      })
    }
  }
}
</script>

<style></style>
<style>
.menus:hover .mod-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.mod-menu {
  transform: translateY(10px);
  position: absolute;
  background: #fff;
  top: 27px;
  left: 0;
  padding: 5px 5px;
  border-radius: 7px;
  box-shadow: 3px 5px 10px 0px #b5b5b5;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease, visibility 0.15s ease, transform 0.15s ease;
}

.menus {
  position: relative;
}

.menu-suid {
  padding: 5px 10px;
  font-weight: 700;
}

.menu-item {
  padding: 5px 15px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}

.menu-item:hover {
  background-color: #d3ebeb96;
  color: #13abab;
}

.mod-menu a {
  color: #000 !important;
  display: flex;
  justify-content: flex-start !important;
}

.search-box {
  position: absolute;
  width: 100%;
  background: #fff;
  top: 36px;
  padding: 0px 0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #9b9b9b;
  max-height: 400px;
  overflow-y: scroll;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease, visibility 0.15s ease, transform 0.15s ease;
}

.search-box.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.search-li {
  padding: 5px 20px;
  cursor: pointer;
}

.search-li:hover {
  background-color: #13abab3d;
}

.app-download {
  width: 140px;
  height: 140px;
}

.app-download img {
  width: 100%;
}
.app-box {
  display: flex;
}

.header-133sa23 {
  display: flex;
  height: 100%;
  background-color: inherit;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
}
</style>

<style src="@/static/css2/headnav.css"></style>
<style src="@/static/css2/Gel3-0.min.css?v=2023.830.500.5"></style>
<style src="@/static/css2/Alibaba-font.min.css"></style>
