<template>
  <!-- 选择银行卡 -->
  <div :style="{ padding: '0 20px' }">
    <div class="hb-sdfqe">
      <div class="hb-sdijfi">USD {{ ShowParams.show_usd }}</div>
      <div class="hb-tit1asd">{{ $t('_zddy._zje') }}</div>
    </div>
    <div class="ant-row ant-form-item" style="row-gap: 0px">
      <div class="ant-col ant-col-2 ant-form-item-label">
        <div for="basic_amount" class="ant-form-item-required sfejc"></div>
      </div>
      <div class="ant-col ant-col-20 ant-form-item-control sfejc">
        <div class="tipsbox">
          <info-circle-outlined style="font-size: 16px; margin-right: 10px" />
          <span
            >{{ $t('_yhqd._czbz') }}
            <a style="margin-left: 5px; position: relative" class="lxkf">
              <span>{{ $t('_yhqd._lxkf') }}</span>
              <div class="tbxos">
                <ul>
                  <li class="item">
                    <a :href="msgCenter.whatsapp ? 'https://wa.me/' + msgCenter.whatsapp : 'javascript:'" :target="msgCenter.whatsapp ? '_blank' : ''">
                      <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                        <IconWhatsApp />
                        WhatsApp
                      </div>
                    </a>
                  </li>
                  <li class="item">
                    <a :href="msgCenter.telegram ? 'https://t.me/' + msgCenter.telegram : 'javascript:'" :target="msgCenter.telegram ? '_blank' : ''">
                      <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                        <IconTelegram />
                        Telegram
                      </div>
                    </a>
                  </li>
                  <li class="item">
                    <a v-if="msgCenter.line" :href="msgCenter.line" :target="msgCenter.line ? '_blank' : ''">
                      <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                        <IconLine />
                        line
                      </div>
                    </a>
                    <div class="line-qrcode" v-else-if="msgCenter.line_qrcode">
                      <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                        <IconLine />
                        Line
                        <div class="line-box">
                          <img :src="msgCenter.line_qrcode + '?r=' + Math.random() * 1000" alt="line" />
                        </div>
                      </div>
                    </div>
                    <a v-else href="javascript:">
                      <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                        <IconLine />
                        Line
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </a>
          </span>
        </div>
      </div>
    </div>
    <div v-if="step == 1" style="display: flex; align-items: center; justify-content: center; padding: 10px">
      <a-spin :spinning="spinning" style="width: 100%">
        <a-form name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off" v-if="currencyList.length > 0">
          <a-form-item :label="$t('bank.title')">
            <a-select ref="select" v-model:value="selectIndex" style="width: 100%" @change="onCurrencyChange">
              <a-select-option :value="i" v-for="(item, i) in currencyList" :key="i">【{{ item.title }}】{{ item.bank_name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24 }">
            <div style="display: flex">
              <div style="display: flex; flex-direction: column; align-items: flex-end; width: 27%">
                <div v-if="current.name.length > 0">{{ $t('bank.name') }}：</div>
                <div v-if="current.account.length > 0">{{ $t('bank.account') }}：</div>
                <div v-if="current.bank_type.length > 0">{{ $t('_yhbt._yhlx') }}：</div>
                <div v-if="current.routing.length > 0">{{ $t('bank.routing') }}：</div>
                <div v-if="current.code.length > 0">{{ $t('bank.code') }}：</div>
                <div v-if="current.bank_name.length > 0">{{ $t('bank.bankname') }}：</div>
                <div v-if="current.bank_address.length > 0">{{ $t('bank.bankaddress') }}：</div>
              </div>
              <div style="width: 80%">
                <div v-if="current.name.length > 0">{{ current.name }}</div>
                <div v-if="current.account.length > 0">{{ current.account }}</div>
                <div v-if="current.bank_type.length > 0">{{ current.bank_type }}</div>
                <div v-if="current.routing.length > 0">{{ current.routing }}</div>
                <div v-if="current.code.length > 0">{{ current.code }}</div>
                <div v-if="current.bank_name.length > 0">{{ current.bank_name }}</div>
                <div v-if="current.bank_address.length > 0">{{ current.bank_address }}</div>
              </div>
            </div>
          </a-form-item>
        </a-form>
        <div v-else-if="!spinning">
          {{ $t('_yhqd._wrhyhtd') }}
        </div>
      </a-spin>
    </div>

    <!-- 提交信息 -->
    <div :style="{ padding: '0 20px' }" v-if="step == 2">
      <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
        <!-- type -->
        <!-- <a-form-item :label="$t('bank.type')">
          <a-radio-group v-model:value="params.type">
            <a-radio :value="1">{{ $t('bank.usd') }} (USD)</a-radio>
            <a-radio :value="2">{{ $t('bank.eur') }} (EUR)</a-radio>
          </a-radio-group>
        </a-form-item> -->
        <a-form-item :label="$t('_zddy._bdhb')">
          {{ currencyHb.name }}
        </a-form-item>

        <!-- amount -->
        <a-form-item :label="$t('_zzsd._zzje')" name="transfer" :rules="[{ required: true, message: $t('_zzsd._qsrzzje') }]">
          {{ params.transfer }} {{ currencyHb.name }}
          <!-- <a-input v-model:value="params.transfer" :suffix="currencyHb.name" type="number" :step="0.00000001" :placeholder="$t('_zzsd._qsrzzje')" /> -->
        </a-form-item>
        <!-- receipt -->
        <a-form-item :label="$t('bank.receipt.number')" :rules="[{ required: true, message: 'Please input your username!' }]">
          <a-input v-model:value="params.number" type="text" :placeholder="$t('bank.receipt.number.desc')" />
        </a-form-item>
        <!-- 封面图 -->
        <a-form-item :label="$t('bank.credential.picture')" :required="true">
          <upload-file @onUpload="onUpload"></upload-file>
        </a-form-item>
        <!-- 打开文件 -->

        <!-- remark -->
        <a-form-item :wrapper-col="{ span: 24 }">
          <div style="display: flex; margin-left: 15%; flex-direction: column">
            <div>⚠️{{ $t('bank.text.title') }}</div>
            <div>📌1.{{ $t('bank.text.t1') }}</div>
            <!-- <div>📌{{ $t('bank.text.t2') }}</div> -->
            <div>📌2.{{ $t('bank.text.t3') }}</div>
            <div>📌3.{{ $t('bank.text.t4') }}</div>
            <div style="color: red">📌4.{{ $t('bank.text.t5') }}</div>
            <div style="color: red">📌5.{{ $t('bank.text.t6') }}</div>
          </div>
        </a-form-item>

        <!-- <a-form-item>
          <a-button key="back" @click="onPrev">上一步</a-button>
          <a-button key="submit" type="primary" :loading="loadingBtn" @click="onSubmit">立即提交</a-button>
        </a-form-item> -->
      </a-form>
    </div>
  </div>

  <!-- 按钮 -->
  <div class="ant-row ant-form-item" style="margin-bottom: 0">
    <div class="ant-col ant-col-24 ant-form-item-label">
      <div for="basic_amount" class="ant-form-item-required" style="display: flex; align-items: center; justify-content: center; gap: 10px">
        <template v-if="step == STEP.SelectBank">
          <a-button key="back" @click="onFatherStep()">{{ $t('bank.ok.prev') }}</a-button>
          <a-button type="primary" @click="onNext" v-if="currencyList.length > 0">{{ $t('bank.ok.text') }}</a-button>
        </template>
        <template v-if="step == STEP.EnterInfo">
          <a-button type="primary" @click="onPrev">{{ $t('bank.ok.prev') }}</a-button>
          <a-button key="submit" type="primary" :loading="loadingBtn" @click="onSubmit">{{ $t('bank.submit') }}</a-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import UploadFile from '@/components/UploadFile.vue'
const STEP = {
  SelectBank: 1,
  EnterInfo: 2
}
export default {
  name: 'RechargeVue',
  components: {
    UploadFile,
    InfoCircleOutlined
  },
  props: {
    ShowParams: {
      type: Object,
      default: () => {}
    },
    currencyHb: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['BankCardSuccess', 'onStep'],
  data() {
    return {
      STEP,
      //存币
      step: 0,
      selectIndex: 0,
      currencyList: [],
      current: {},

      spinning: true,

      // 提交信息
      params: {
        type: 1,
        amount: '',
        transfer: 0,
        number: '',
        img: ''
      },

      // 上传图片
      loading: false,
      imageUrl: '',
      img: '',

      // 提交
      loadingBtn: false,

      msgCenter: {
        whatsapp: '',
        telegram: ''
      }
    }
  },
  created() {
    this.show()

    let that = this
    this.$api.currency.getBankCardList().then((res) => {
      that.spinning = false
      that.currencyList = res.data
      //默认第一个
      that.onCurrencyChange(0)
    })

    this.$api.home.getBase().then((res) => {
      this.msgCenter = res.data
    })
  },
  methods: {
    onFatherStep() {
      this.$emit('onStep', 2)
    },
    onUpload(img) {
      this.imageUrl = img
      this.img = img
    },
    /**
     * 上传封面图
     * @param {*} img
     * @param {*} callback
     */
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    onUploadChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        var response = info.file.response
        if (response.code === 1) {
          this.loading = false
          this.$message.error(response.msg)
          return
        }

        this.img = response.data.url
        this.loading = false

        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp'
      if (!isJpgOrPng) {
        this.$message.error('can only upload jpg, png, bmp pictures')
      }
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('Images must be less than 20MB!')
      }
      return isJpgOrPng && isLt2M
    },
    /**
     * 存币
     */
    show() {
      this.step = 1
      this.selectIndex = 0
      this.imageUrl = ''
      this.img = ''
      this.params = {
        type: this.currencyHb.type,
        amount: this.ShowParams.show_usd,
        transfer: this.ShowParams.amount,
        number: '',
        img: ''
      }
    },
    hide() {
      this.step = 0
    },
    // 改变
    onCurrencyChange(i) {
      this.current = this.currencyList[i]
    },
    onNext() {
      this.step = 2
    },
    onPrev() {
      this.step = 1
    },
    valid() {
      if (this.params.amount === '') {
        this.$message.error('amount cannot be empty')
        return false
      }

      if (this.params.number === '') {
        this.$message.error('number cannot be empty')
        return false
      }

      if (this.params.img === '') {
        this.$message.error('credential image cannot be empty')
        return false
      }

      return true
    },
    onSubmit() {
      this.loadingBtn = true

      this.params.bank_id = this.current.id
      this.params.img = this.img

      if (!this.valid()) {
        this.loadingBtn = false
        return
      }

      //提交到后台
      const that = this
      this.$api.store
        .transferBankCard(this.params)
        .then((res) => {
          that.loadingBtn = false
          if (res.code == 0) {
            that.$message.success(res.msg)
            setTimeout(function () {
              that.$emit('BankCardSuccess')
            }, 500)
          }
        })
        .catch((err) => {
          err
          that.loadingBtn = false
        })
    }
  }
}
</script>

<style>
.tipsbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ff000047;
  border-radius: 10px;
  background-color: #ff000014;
  color: #c30000;
  font-size: 14px;
  font-weight: 700;
}

.lxkf:hover .tbxos {
  visibility: visible;
  opacity: 1;
}

.tbxos {
  top: 100%;
  left: 0;
  width: 130px;
  position: absolute;
  background: #fff;
  padding: 10px;
  z-index: 1;
  box-shadow: 2px 1px 5px #ccc;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.05s ease-in;
}

.tbxos li {
  list-style: none;
  line-height: 25px;
}
.tbxos a {
  color: #000;
}

.ant-spin-nested-loading {
  width: 100%;
}
</style>
