export default {
  'nav.account': 'Pusat Pribadi',
  'nav.language': 'Cina disederhanakan',
  'signup.success': 'Pendaftaran berhasil', //error	:

  'contact.empty': 'Informasi kontak tidak boleh kosong',
  'phone.country.empty': 'Awalan telepon tidak boleh kosong',
  'phone.empty': 'Telepon tidak boleh kosong',
  'email.valid': 'Tolong masukkan email yang benar', // base	:

  'base.success': 'Kesuksesan',
  'base.save': 'Menyimpan',
  'base.slide': 'Silakan tarik penggeser untuk memverifikasi',
  'base.send.code': 'Kirim kode',
  'base.send.time': '({time}) Kirim ulang kode',
  'base.send.success': 'Kirim berhasil',
  'base.view.more': 'Lihat Lebih Banyak',
  'base.Language': 'Bahasa',
  'base.previous': 'Halaman sebelumnya',
  'base.next': 'Halaman selanjutnya',
  'base.total.page': 'Total {halaman} halaman',
  'base.go.to.page': 'Buka halaman',
  'base.go': 'Pergi ke',
  'base.followers': 'Pengikut',
  'base.follow': 'Mengikuti',
  'base.following': 'Mengikuti',
  'base.visit.store': 'Jelajahi Toko',
  'base.contact': 'Hubungi kami',
  'base.delivery': 'Perkiraan waktu pengiriman: 1-7 hari',
  'base.search': 'Mencari',
  'base.search.text': 'Masukkan kata kunci untuk mencari',
  'base.server': 'Melayani', //index	:

  'text.categories.title': 'Kategori Produk',
  'text.welcome': 'Selamat berbelanja!',
  'text.wishlist': 'Daftar Keinginan',
  'text.account': 'Pusat Pribadi',
  'text.orders': 'Pesanan',
  'text.messages': 'Pesan',
  'text.suggest.title': 'Penawaran eksklusif',
  'text.suggest.content': 'Hanya untuk anggota premium global baru!',
  'text.super.deals': 'Produk unggulan dengan harga luar biasa.', //signup	:

  'signup.register': 'Mendaftar',
  'signup.signin': 'Masuk',
  'signup.store.signin': 'Login Bisnis',
  'signup.sign.out': 'Keluar',
  'signup.email': 'Alamat email',
  'signup.password': 'Kata sandi',
  'signup.qr.password': 'Konfirmasi sandi',
  'signup.confirm.password': 'Konfirmasi sandi',
  'signup.forgot.password': 'Tidak ingat kata sandi',
  'signup.invitation': 'Kode Undangan',
  'signup.char': 'Karakter',
  'signup.contains': 'Berisi angka, huruf atau simbol',
  'signup.qr.contains': 'Kedua kata sandi tidak konsisten',
  'signup.create.account': 'Buat Akun',
  'signup.agree': 'Buat akun, Anda setuju',
  'signup.member.agreement': 'Perjanjian Anggota',
  'signup.and': 'Dan',
  'signup.privacy.policy': 'Kebijakan pribadi',
  'signup.email.code': 'Verifikasi email',
  'signup.last.step': 'Langkah terakhir',
  'signup.send.email': 'Silakan masukkan 4 digit kode yang dikirimkan ke {email}',
  'signup.modify.email': 'Ubah email',
  'signup.verify.email': 'Email Verifikasi',
  'signup.have.store': 'Punya akun pedagang',
  'signup.goto.signin': 'Login Bisnis',
  'signup.no.store': 'Tidak ada akun pedagang',
  'signup.goto.store': 'Daftar Pedagang',
  'signup.next': 'Berikutnya',
  'signup.your.email': 'Email mu',
  'signup.code.text': 'Kode verifikasi',
  'signup.submit.signup': 'Daftar sekarang',
  'signup.smrz': 'Otentikasi nama asli',
  'signup.derb': 'Tahap kedua',
  'signup.qsrxm': 'Silakan masukkan nama dokumen',
  'signup.qsrhm': 'Silakan masukkan nomor ID', //forgot	:

  'forgot.title': 'Setel Ulang Kata Sandi',
  'forgot.btn.check': 'Verifikasi email',
  'forgot.reset.now': 'Setel Ulang Sekarang', //store	:

  'store.info.open': 'Buka {y} Minggu', // footer	:

  'footer.great.value': 'Nilai luar biasa',
  'footer.great.value.desc': 'Kami menawarkan harga yang kompetitif untuk lebih dari 100 juta item.',
  'footer.shopping': 'Belanja Global',
  'footer.shopping.desc': 'Kami mengirim ke lebih dari 200 negara dan wilayah, dan situs web kami tersedia dalam 7 bahasa.',
  'footer.safe.payment': 'Pembayaran Aman',
  'footer.safe.payment.desc': 'Bayar dengan metode pembayaran terpopuler dan teraman di dunia.',
  'footer.shop.with.confidence': 'Berbelanja dengan percaya diri',
  'footer.shop.with.confidence.desc': 'Kebijakan perlindungan pembeli kami mencakup seluruh proses pembelian Anda.',
  'footer.help.center': 'Pusat Bantuan',
  'footer.help.center.desc': 'Bantuan 24/7 untuk menciptakan pengalaman berbelanja yang lancar.',
  'footer.terms.conditions': 'Syarat dan Ketentuan',
  'footer.return.policy': 'Kebijakan pengembalian',
  'footer.support.policy': 'Kebijakan Dukungan',
  'footer.privacy.policy': 'Kebijakan pribadi',
  'footer.be.seller': 'Menjadi penjual',
  'footer.apply.now': 'Daftar Sekarang',
  'footer.stay.connected': 'Tetap terhubung',

  'footer.about.us': 'Tentang kami',
  'footer.about.company': 'Profil Perusahaan',
  'footer.about.video': 'Video Pengenalan',
  'footer.contact': 'Kontak',

  'footer.my.account': 'Akun saya',
  'footer.my.logout': 'Keluar',
  'footer.my.order': 'Riwayat Pesanan',
  'footer.my.wish': 'Keinginanku',
  'footer.my.join': 'Menjadi mitra anggota',
  'footer.email': 'Surel',
  'footer.gfemail': 'Email resmi',
  'footer.fwemail': 'Email Layanan',
  'footer.address': 'Alamat',

  'apply.success': 'Aplikasi berhasil',
  'apply.success.desc': 'Berhasil diterapkan, masuk ke akun login', // categories	:

  'category.title': 'Kategori Terkait',
  'category.all': 'Semua Kategori', //detail	:

  'detail.store.home': 'Rumah Toko',
  'detail.sale.items': 'Daftar Barang',
  'detail.recommend': 'Direkomendasikan',
  'detail.orders': 'Penjualan',
  'detail.quantity': 'Kuantitas',
  'detail.pieces.available': 'Tersedia',
  'detail.delivery': 'Pengiriman',
  'detail.free.shipping': 'Bebas biaya kirim',
  'detail.estimated.delivery': 'Perkiraan pengiriman',
  'detail.days': 'Hari',
  'detail.buy.now': 'Membeli',
  'detail.add.to.cart': 'Masukkan ke keranjang',
  'detail.buyer.protection': 'Perlindungan Pembeli',
  'detail.money.guarantee': 'Garansi uang kembali',
  'detail.refund.desc': 'Dapatkan pengembalian dana penuh jika barang tidak sesuai deskripsi atau tidak terkirim',
  'detail.description': 'Deskripsi Produk',
  'detail.customer.reviews': 'Ulasan Pelanggan',
  'detail.specifications': 'Spesifikasi Produk',
  'detail.top.selling.products': 'Produk Terlaris',
  'detail.recommended.for.you': 'Direkomendasikan untukmu',
  'detail.sold': 'Penjualan',
  'detail.receipt': 'Konfirmasi penerimaan',
  'detail.receipt.title': 'Konfirmasi penerimaan pesanan?',
  'detail.receipt.content': 'Setelah konfirmasi, transaksi pesanan selesai',
  'detail.comment': 'Komentar', //confirm	:

  'confirm.shipping.address': 'Alamat penerima',
  'confirm.change': 'Mengubah',
  'confirm.payment.methods': 'Cara Pembayaran',
  'confirm.summary': 'Hunian',
  'confirm.total.item.costs': 'Jumlah Barang',
  'confirm.total': 'Total',
  'confirm.checkout': 'Periksa',
  'confirm.place.order': 'Pesan sekarang',
  'confirm.pay.now': 'Bayar sekarang',
  'confirm.order.desc': "Setelah klik 'Place Order', saya konfirmasi sudah membaca dan konfirmasi",
  'confirm.order.policy': 'Semua syarat dan kebijakan',
  'confirm.payment': 'Belanja Global memastikan keamanan informasi dan pembayaran Anda', // address	:

  'address.title': 'Alamat penerima',
  'address.default': 'bawaan',
  'address.edit': 'Sunting',
  'address.delete': 'Menghapus',
  'address.new': 'Buat alamat baru',
  'address.contact': 'Kontak',
  'address.address': 'Alamat',
  'address.phone': 'Nomor telepon',
  'address.set.default': 'Set standar',
  'address.confirm': 'Mengonfirmasi',
  'address.cancel': 'Membatalkan',
  'address.del.title': 'Konfirmasi untuk menghapus alamat?',
  'address.del.content': 'Apakah Anda yakin ingin menghapus alamat pengiriman ini?',

  'payment.method': 'Cara Pembayaran', // Shopping Cart	:

  'shopping.title': 'Kereta Belanja',
  'shopping.back.buy': 'Kembali ke belanja khusus',
  'shopping.start.shopping': 'Mulai Berbelanja', // member	:

  'member.account': 'Pusat Pribadi',
  'member.overview': 'Ringkasan',
  'member.orders': 'Pesanan',
  'member.payment': 'Dompetku',
  'member.address': 'Alamat pengiriman',
  'member.wishlist': 'Daftar Keinginan',
  'member.followlist': 'Daftar Ikuti',
  'member.message.center': 'Pusat pesan',
  'member.setting': 'Pengaturan pribadi',
  'member.shop.info': 'Informasi Toko',
  'member.shop.setting': 'Pengaturan Toko',
  'member.order.notify': 'Pemberitahuan Pesanan Baru',
  'member.order.tips': 'Dengan mengirimkan email ke email akun pedagang', // member. order	:

  'member.order.title': 'Pesananku',
  'member.order.view.all': 'Lihat semua',
  'member.order.all': 'Semua',
  'member.order.unpaid': 'Tidak dibayar',
  'member.order.paid': 'Dibayar',
  'member.order.procurement': 'Pembelian',
  'member.order.seller.paid': 'Dibeli',
  'member.order.processing': 'Tertunda',
  'member.order.shipped': 'dikirim',
  'member.order.return': 'Kembali',
  'member.order.completed': 'Lengkap',
  'member.order.refund': 'Dikembalikan',
  'member.order.all.time': 'Semua',
  'member.order.empty': 'Belum ada pesanan',
  'member.order.date': 'Tanggal',
  'member.order.purchase.date': 'Pembelian',
  'member.order.cpoy': 'Menyalin',
  'member.order.id': 'Memesan',
  'member.order.detail': 'Detail',
  'member.order.logistics': 'informasi logistik', // member. payment	:

  'member.payment.title': 'Dompetku',
  'member.wallet.balance': 'Saldo Dompet',
  'member.crypto.recharge': 'Isi ulang',
  'member.crypto.withdrawal': 'Menarik',
  'member.crypto.bank': 'Kartu bank',
  'member.wallet.record': 'Catatan Setoran Koin',
  'member.bankcard.record': 'Catatan Kartu Bank',
  'member.withdrawal.record': 'Catatan Penarikan',
  'member.income.record': 'Catatan Pendapatan',
  'member.transaction.record': 'Catatan transaksi',
  'member.wallet.freeze': 'Bekukan dana',
  'member.wallet.profit': 'Perkiraan pendapatan', //recharge	:

  'recharge.currency': 'Mata uang',
  'recharge.protocol': 'Protokol',
  'recharge.qrcode': 'Kode QR',
  'recharge.address': 'Alamat Dompet',
  'recharge.copy': 'Salin alamat',
  'recharge.ok.text': 'Konfirmasi',
  'recharge.cancel.text': 'Membatalkan', //bank	:

  'bank.recharge.title': 'Isi ulang kartu bank',
  'bank.title': 'Nama Bank',
  'bank.name': 'Nama',
  'bank.account': 'Akun',
  'bank.routing': 'Rute',
  'bank.code': 'Kode',
  'bank.bankname': 'Nama Bank',
  'bank.bankaddress': 'Alamat bank',
  'bank.ok.text': 'Berikutnya',
  'bank.ok.prev': 'Langkah sebelumnya',
  'bank.submit': 'Kirim sekarang',
  'bank.amount': 'Jumlah Isi Ulang',
  'bank.amount.desc': 'Silakan masukkan jumlah isi ulang',
  'bank.type': 'Jenis',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Nomor Tanda Terima',
  'bank.receipt.number.desc': 'Silakan masukkan nomor resi transaksi',
  'bank.credential.picture': 'Gambar Kredensial',
  'bank.credential.picture.desc': 'Silakan unggah gambar kredensial',
  'bank.remark': 'Komentar',
  'bank.upload': 'Mengunggah gambar',
  'bank.text.title': 'Catatan',
  'bank.text.t1': 'Tidak perlu keterangan apa pun untuk transfer, biarkan kosong',
  'bank.text.t2': 'Untuk rumah tangga di Hong Kong, pilih Hong Kong daripada Tiongkok',
  'bank.text.t3': 'Pengiriman uang Amerika, pada hari kerja, kirimkan pengiriman uang Eropa sebelum jam 15.00, pengiriman uang tersedia selama jam kerja bank!',
  'bank.text.t4': 'Waktu pembayaran T+1, dan pembayaran terakhir T+3 di Eropa',
  'bank.text.t5': 'Beri tahu platform sebelum mentransfer uang untuk mengonfirmasi apakah akun tersedia. Jika rekening ditutup, tidak ada kompensasi yang dibayarkan.',
  'bank.text.t6': 'Setor melalui kartu bank. Informasi layanan pelanggan akan berlaku.', //banklist	:

  'bank.list.title': 'Nama Bank',
  'bank.list.code': 'Kode Bank',
  'bank.list.amount': 'Jumlah transfer',
  'bank.list.number': 'Nomor Tanda Terima',
  'bank.list.img': 'Gambar Voucher',
  'bank.list.status': 'Status',
  'bank.list.time': 'Waktu',
  'bank.list.status1': 'Sedang ditinjau',
  'bank.list.status2': 'Lulus',
  'bank.list.status3': 'Ditolak', // Withdrawal	:

  'withdrawal.address.desc': 'Silakan masukkan alamat dompet!',
  'withdrawal.number': 'Nomor',
  'withdrawal.real.number': 'kedatangan sebenarnya',
  'withdrawal.number.desc': 'Silakan masukkan jumlah penarikan!',
  'withdrawal.btn.all': 'Semua',
  'withdrawal.balance': 'Keseimbangan',
  'withdrawal.commission': 'Komisi',
  'withdrawal.actual.amount': 'Akun',
  'withdrawal.notice': 'Melihat',
  'withdrawal.notice.text': 'Sebelum mentransfer, harap konfirmasi bahwa informasi alamat penerima sudah benar. Setelah aset dipindahkan, tidak dapat dikembalikan.',
  'withdrawal.notice.content': '{name}({cp_name}) komisi: nilai pasar saat ini {fee_rate}%/transaction, standar minimum: {fee_min} {name}/transaction',
  'withdrawal.submit': 'Kirim',
  'withdrawal.choice': 'Pilih mata uang kripto',
  'withdrawal.yzm': 'Kode Email',
  'withdrawal.fs': 'Mengirim',
  'withdrawal.qsryzm': 'Silakan masukkan kode email', // recive	:

  'recive.method': 'Protokol',
  'recive.amount': 'Jumlah',
  'recive.address': 'Alamat',
  'recive.date': 'Waktu',
  'recive.status': 'Status',
  'recive.create.at': 'Waktu Transaksi',
  'recive.type': 'Jenis',
  'recive.befor': 'Sebelum transaksi',
  'recive.balance': 'Keseimbangan',
  'recive.freeze': 'Membekukan',
  'recive.review': 'Meninjau',
  'recive.success': 'Lulus',
  'recive.reject': 'Ditolak', // advertise	:

  'advertise.title': 'Pusat Promosi',
  'advertise.shop.title': 'Promosi Toko',
  'advertise.shop.status': 'Status',
  'advertise.shop.expired': 'Kedaluwarsa',
  'advertise.shop.promotion': 'Mempromosikan',
  'advertise.shop.expire.date': 'Tanggal habis tempo',
  'advertise.shop.renew': 'Pembaruan iklan',
  'advertise.shop.payable': 'Jumlah yang harus dibayar',
  'advertise.shop.explanation': 'Penjelasan Iklan',
  'advertise.shop.text': 'Berpartisipasi dalam promosi periklanan platform, tingkatkan eksposur toko, dan promosikan pesanan transaksi',
  'advertise.shop.paynow': 'Perbarui segera',
  'advertise.shop.modal.title': 'Konfirmasi pembayaran',
  'advertise.shop.modal.desc': 'Dikonfirmasi membayar biaya promosi',
  'advertise.shop.modal.btn': 'Konfirmasi pembayaran', //Wish List	:

  'wishlist.title': 'Daftar Keinginan',
  'wishlist.delete': 'Menghapus',
  'wishlist.orders': 'Penjualan', //Follow List	:

  'followlist.title': 'Daftar Ikuti',
  'followlist.delete': 'Menghapus',
  'followlist.follow': 'Mengikuti', // store	:

  'store.dashboard': 'Dasbor',
  'store.products': 'Produk',
  'store.products.list': 'Daftar Produk',
  'store.products.reviews': 'Ulasan produk',
  'store.orders': 'Pesanan',
  'store.wallet': 'Dompet',
  'store.message': 'Pusat pesan',
  'store.setting': 'Pengaturan',
  'store.order.total.profit': 'Perkiraan keuntungan total', //dashboard	:

  'dashboard.store.hour.views': 'Kunjungan waktu nyata',
  'dashboard.store.today.views': 'Tampilan Hari Ini',
  'dashboard.product.total': 'Jumlah Produk',
  'dashboard.product.today': 'Ditambahkan hari ini',
  'dashboard.order.total': 'Jumlah Pesanan',
  'dashboard.sales.total': 'Perkiraan omzet',
  'dashboard.sales.real': 'Penjualan nyata',
  'dashboard.sales.pay': 'Total Pembayaran',
  'dashboard.sales.profit': 'Total keuntungan',
  'dashboard.commission.total': 'Total pendapatan',
  'dashboard.commission.today': 'Penghasilan hari ini',
  'dashboard.order.sales': 'Penjualan Produk',
  'dashboard.sales.list': 'Peringkat Penjualan',
  'dashboard.goods.cate.rate': 'Rasio kategori produk',
  'dashboard.goods.wish': 'Suka Peringkat Barang',
  'dashboard.january': 'Januari',
  'dashboard.february': 'Februari',
  'dashboard.march': 'Berbaris',
  'dashboard.april': 'April',
  'dashboard.may': 'Mungkin',
  'dashboard.june': 'Juni',
  'dashboard.july': 'Juli',
  'dashboard.august': 'Agustus',
  'dashboard.september': 'September',
  'dashboard.october': 'Oktober',
  'dashboard.november': 'November',
  'dashboard.december': 'Desember', // products	:

  'products.add.new': 'Tambahkan produk baru',
  'products.add.from.warehouse': 'Tambahkan produk dari gudang',
  'products.delete': 'Menghapus',
  'products.add': 'Menambahkan',
  'products.table.img': 'Gambar',
  'products.table.name': 'Nama',
  'products.table.category': 'Kategori',
  'products.table.wish': 'menyukai',
  'products.table.stock': 'Saham',
  'products.table.price': 'Harga',
  'products.table.profit': 'Laba',
  'products.table.action': 'Tindakan',
  'products.search.category': 'Cari berdasarkan Kategori',
  'products.back.product': 'Kembali ke daftar produk',
  'products.total': 'Jumlah Produk',
  'products.yes': 'Ya',
  'products.no': 'Membatalkan',
  'products.batch.add': 'Tambahan Batch',
  'products.ask.add': 'Apakah Anda yakin ingin menambahkan produk?',
  'products.batch.delete': 'Hapus banyak',
  'products.ask.delete': 'Apakah Anda yakin ingin menghapus produk tersebut?',
  'products.top': 'Atas',
  'products.syzd': 'Rumah Atas',
  'products.zdwz': 'Posisi',
  'products.t1': 'Promosi',
  'products.t2': 'Lebih disukai',
  'products.t3': 'Butik',
  'products.t4': 'tidak ada', // reviews	:

  'reviews.title': 'Ulasan produk',
  'reviews.product.name': 'Produk',
  'reviews.user.name': 'Pengguna',
  'reviews.star': 'Peringkat',
  'reviews.comment': 'Tinjau Konten',
  'reviews.sku': 'Spesifikasi Produk',
  'reviews.imgs': 'Galeri foto',
  'reviews.created': 'Tanggal', // store. order	:

  'store.order.purchase': 'Pembelian',
  'store.order.purchase.desc': 'Apakah Anda yakin ingin membayar untuk produk ini?',
  'store.order.purchase.yes': 'Bayar sekarang',
  'store.order.purchase.no': 'Membatalkan',
  'store.order.desc': 'Pembayaran diperlukan untuk membeli pesanan, dan Anda akan menerima manfaat setelah pesanan selesai.',
  'store.order.no': 'Jumlah order',
  'store.order.number': 'Kuantitas',
  'store.order.buyer': 'Pembeli',
  'store.order.total': 'Jumlah total',
  'store.order.will.earning': 'Penghasilan',
  'store.order.profit': 'Laba',
  'store.order.dividends': 'Dividen',
  'store.order.payment.status': 'Status pembayaran',
  'store.order.seller.buy.status': 'Status Pembelian',
  'store.order.status': 'Status pemesanan',
  'store.order.date': 'Tanggal pemesanan',
  'store.order.purchase.date': 'Tanggal Pembelian',
  'store.order.action': 'Tindakan',
  'store.order.purchase.amount': 'Jumlah pembelian', //income	:

  'income.create.at': 'Rekam Waktu',
  'income.order.sn': 'Jumlah order',
  'income.realpay': 'Jumlah pesanan',
  'income.profit': 'penghasilan', //Setting	:

  'setting.avatar': 'Avatar Bisnis',
  'setting.upload': 'Mengunggah',
  'setting.shop.name': 'Nama Toko',
  'setting.shop.phone': 'Hubungi Telepon',
  'setting.shop.address': 'Alamat Toko',
  'setting.shop.save': 'Menyimpan',
  'setting.upload.pic': 'Mengunggah gambar',
  'setting.send.pic': 'Mengirim gambar', //beseller	:

  'beseller.title': 'Melamar menjadi pedagang',
  'beseller.account': 'Informasi Akun',
  'beseller.store': 'Informasi toko',
  'beseller.store.name': 'Nama Toko',
  'beseller.store.address': 'Alamat Toko', //savehome	:

  'store.home.title': 'Pengaturan Beranda',
  'store.home.topimg': 'gambar atas',
  'store.home.banner': 'Korsel',
  'store.home.up3': 'Keterangan: Minimal 3 upload',
  'store.upload.more': 'unggah banyak gambar',
  'store.home.columns': 'Kolom',
  'store.home.bgimg': 'Gambar latar belakang',
  'store.goods.remark': 'Keterangan: 5 item per baris, maksimal 10 item per bagian',
  'store.home.select': 'Pilih produk',
  'store.home.add': 'Tambahkan kolom', //personal information	:

  'setting.update.user': 'informasi pribadi',
  'setting.update.info': 'Ubah informasi',
  'setting.user.avatar': 'Avatar Pengguna',
  'setting.user.nickname': 'Nama Panggilan Pengguna',
  'setting.user.nickname.desc': 'Silakan masukkan nama panggilan pengguna',

  'setting.safe.title': 'Informasi keselamatan',
  'setting.user.passwd': 'Ganti kata sandi',
  'setting.passwd.title1': 'Password lama',
  'setting.passwd.desc1': 'Silakan masukkan kata sandi lama',
  'setting.passwd.title2': 'Kata sandi baru',
  'setting.passwd.desc2': 'Silakan masukkan kata sandi baru',
  'setting.passwd.title3': 'Konfirmasi sandi',
  'setting.passwd.desc3': 'Silakan masukkan kata sandi konfirmasi',
  'setting.invitation': 'Mengundang teman-teman',
  'setting.copy': 'Menyalin',

  'adv.add.products': 'Tambahkan produk promosi',
  'adv.list.title': 'Promosi produk',
  'adv.type': 'Jenis Promosi',
  'adv.begin.time': 'Waktunya promosi',
  'adv.end.time': 'Akhir waktu',
  'adv.status': 'Status',
  'adv.status.s1': 'Mempromosikan',
  'adv.status.s2': 'Akhir',
  'adv.add.back': 'Kembali ke daftar yang dipromosikan',
  'adv.recharge': 'Isi ulang poin promosi',
  'adv.select.goods': 'Pilih Barang',
  'adv.select.btn': 'Produk yang Dipromosikan',
  'adv.recharge.title': 'Isi Ulang Poin Promosi',
  'adv.recharge.balance': 'Saldo poin',
  'adv.point': 'titik',
  'adv.point.rate': 'Rasio poin',
  'adv.recharge.amount': 'Jumlah isi ulang',
  'adv.recharge.input.amount': 'Masukkan jumlah isi ulang',
  'adv.select.mod': 'Pilih panel',
  'adv.mod1': 'Kolom yang direkomendasikan',
  'adv.mod2': 'Kolom pilihan',
  'adv.mod3': 'Kolom terlaris',
  'adv.mod4': 'Daftar yang bagus',
  'adv.mod5': 'Daftar klasifikasi',
  'adv.mod6': 'Promosi Terperinci',
  'adv.ge': 'kuantitas',
  'adv.xs': 'jam',
  'adv.xzbk': 'Pilih piring',
  'adv.syme': 'Sisa kuota',
  'adv.tgsc': 'Durasi promosi',
  'adv.tgjg': 'Harga promosi',
  'adv.syye': 'Saldo saat ini',
  'adv.tgye': 'Saldo promosi',
  'adv.yj1': 'mengharapkan',
  'adv.yj2': 'Mulai promosi',
  'adv.pay.point': 'Titik konsumsi',

  'wallet.ykcsxf': 'Biaya penanganan telah dipotong',
  'wallet.sxf': 'Biaya jasa',
  'wallet.zxje': 'Jumlah minimal',
  'wallet.24hxe': 'batas 24 jam',
  'wallet.desc.text': 'Dalam keadaan apa pun, petugas tidak akan meminta Anda mentransfer uang ke rekening, juga tidak akan meminta kode verifikasi. Jangan berpartisipasi dalam aktivitas ilegal seperti pembelian atas nama orang lain, pencucian uang, dan penggalangan dana ilegal, serta waspada terhadap penipuan online',
  'wallet.cunbi.text': 'Anda hanya dapat menyetorkan {name} ke alamat ini, aset lainnya tidak akan diambil',

  'credit.title': 'Batas Kredit',
  'credit.ed': 'Kuota',
  'credit.hk': 'Pembayaran kembali',
  'credit.kyye': 'Saldo Tersedia',
  'credit.dqqk': 'Tunggakan saat ini',
  'credit.hkje': 'Jumlah pembayaran kembali',
  'credit.hkje.desc': 'Silakan masukkan jumlah pembayaran',
  'credit.cgje': 'Jumlah pembelian',
  'credit.qrz': 'Sertifikasi',
  'credit.srxyj': 'Masukkan jumlah pembayaran kredit',

  'store.gzl': 'mengikuti',
  'store.wxz': 'Tak terbatas',

  'auth.smrz': 'Otentikasi nama asli',
  'auth.rzlx': 'Jenis',
  'auth.sfzm': 'KTP depan',
  'auth.sffm': 'Sisi belakang KTP',
  'auth.zsxm': 'nama asli',
  'auth.zjhm': 'Nomor dokumen',
  'auth.yyzz': 'Lisensi bisnis',
  'auth.gsmc': 'Nama perusahaan',
  'auth.shz': 'Audit',
  'auth.ytg': 'Lulus',
  'auth.ybh': 'Ditolak',
  'auth.zt': 'status',
  'auth.gr': 'Pribadi',
  'auth.gs': 'Perusahaan',
  'auth.ljtj': 'Ajukan permohonan autentikasi',
  'auth.wrz': 'Tidak diautentikasi',

  'credit.qts': 'Lihat deskripsi',
  'store.spss': 'Batas atas komoditas',
  'store.dpfh': 'Simpan dividen',
  'store.qydj': 'Tingkat ekuitas',
  'store.level': 'tingkat',
  'store.jian': 'bagian-bagian',

  'order.notify.email': 'Karena pengendalian risiko email, email pengingat pesanan platform sering dikirim dan akan disadap. Untuk menerimanya tepat waktu, disarankan untuk menambahkan service@nextstore.etxts.com ke daftar putih email login',

  'setting.sjxx': 'Informasi',
  'setting.smrz': 'Autentikasi',
  'setting.aqzx': 'Keamanan',
  'setting.tzxx': 'Pemberitahuan',
  'setting.dpzx': 'Dekorasi',
  'setting.sjqy': 'Ekuitas',
  'setting.sjdjqy': 'Deskripsi Manfaat Tingkat Merchant',

  'setting.fhbky': 'Dividen saat ini tidak tersedia',
  'setting.spgzyzy': 'Aturan dan pedoman toko',

  'desc.dqqy': 'Ekuitas saat ini',
  'desc.xjqy': 'Kepentingan bawahan',
  'desc.sjqy_0': 'Batas kredit toko',
  'desc.sjqy_1': 'Simpan dividen',
  'desc.sjqy_2': 'Jumlah produk yang dapat dipublikasikan',

  'share.yqlb': 'Daftar undangan',
  'share.zmj': 'Jumlah penjual',
  'share.zdd': 'Jumlah pesanan',
  'share.zsy': 'Total pendapatan pialang',
  'share.wcl': 'belum diproses',
  'share.ycl': 'Diproses',
  'share.ywc': 'Lengkap',
  'share.dpmc': 'Nama Toko',
  'share.ddl': 'Jumlah pesanan',
  'share.jjsy': 'Pendapatan perantara',
  'share.zts': 'Jumlah total entri',

  'chat.state': 'Negara',
  'chat.zx': 'On line',
  'chat.ljz': 'Menghubungkan',

  'bind.wallet.title': 'Alamat penarikan',
  'bind.address.title': 'Tambahkan alamat',
  'bind.bj': 'Sunting',
  'bind.sc': 'Menghapus',
  'bind.qrsc': 'Apakah Anda yakin ingin menghapus alamat ini?',
  'bind.qbdz': 'Alamat dompet',
  'bind.tjsj': 'Tambahkan waktu',
  'bind.cz': 'Operasi',
  'withdrawal.address.choice': 'Silakan pilih alamat penarikan!',

  'order.sxsj': 'Waktu efektif',
  'order.ljsj': 'segera efektif',
  'order.dssj': 'Waktunya efektif',

  'store.st.zt': 'Status Toko',
  'store.st.zc': 'normal',
  'store.st.zc.desc': 'Toko anda normal, tolong pertahankan',
  'store.st.yc': 'Pengecualian',
  'store.st.yc.desc': 'Toko Anda memiliki beberapa pesanan yang tidak normal atau menerima keluhan pelanggan',
  'store.st.dj': 'Membekukan',
  'store.st.dj.desc': 'Toko Anda memiliki risiko operasional tertentu dan dana dibekukan',
  'store.st.jy': 'dengan disabilitas',
  'store.st.jy.desc': 'Toko Anda telah melanggar perjanjian dan aturan terkait platform dan telah dinonaktifkan',

  'task.sy': 'Beranda',
  'task.rwlbo': 'Daftar tugas',
  'task.rwxqo': 'Rincian tugas',
  'task.ljcy': 'Berpartisipasilah sekarang',
  'task.gdrw': 'Lebih banyak tugas',
  'task.rwlb': 'Daftar tugas yang diambil',
  'task.qbrw': 'Semua tugas',
  'task.wcyd': 'Saya berpartisipasi dalam',
  'task.qdz': 'Mendapatkan pesanan',
  'task.ywc': 'Lengkap',
  'task.djy': 'untuk diperdagangkan',
  'task.ddje': 'Jumlah',
  'task.cyrs': 'Nomor',
  'task.ewsy': 'Penghasilan',
  'task.jzsj': 'Tenggat waktu',
  'task.ckxq': 'Melihat rincian',
  'task.rwxq': 'Rincian tugas',
  'task.cylb': 'Daftar partisipasi',
  'task.qdcyrw': 'Apakah kamu yakin ingin berpartisipasi dalam tugas pemesanan ini?',
  'task.sd': 'Ya',
  'task.bue': 'TIDAK',

  'task.wdsy': 'Pendapatan',
  'task.wdfh': 'Dividen',
  'task.wdqd': 'Dapatkan pesanan',
  'task.dd.leixin': 'Jenis pesanan',
  'task.dd.qdrw': 'Tugas pesanan cepat',
  'task.dd.ptdd': 'Pesanan biasa',

  'flow.lltg': 'Aliran Promosi',
  'flow.gmll': 'Beli paket data',
  'flow.dqtc': 'Paket saat ini',
  'flow.tc1': 'Kemasan',
  'flow.sy2': 'tersisa',
  'flow.tian3': 'hari',
  'flow.jlzs': 'Jumlah total catatan',
  'flow.lltc': 'Paket aliran',
  'flow.ll': 'mengalir',
  'flow.gmsj': 'Waktu pembelian',
  'flow.tcjg': 'Harga paket',
  'flow.sjzf': 'pembayaran sebenarnya',
  'flow.tcsm': 'Deskripsi paket',
  'flow.tcsm.sm': 'Membeli paket ini akan meningkatkan eksposur toko dan mendapatkan lebih banyak lalu lintas pelanggan. Untuk mendapatkan lebih banyak pesanan dan meningkatkan pendapatan toko.',

  'flow.tcmc': 'Nama paket',
  'flow.gmsc': 'Durasi pembelian',
  'flow.zje': 'Jumlah total',
  'flow.jzsj': 'Tenggat waktu',
  'flow.gmrq': 'Tanggal Pembelian',
  'flow.tian1': 'hari',
  'flow.zhou1': 'pekan',
  'flow.yue1': 'bulan',

  'share.dj': 'tingkat',

  'share.hz': 'Transfer',
  'share.hzd': 'Transfer ke',
  'share.qbye': 'saldo dompet',
  'share.tgje': 'Jumlah promosi',
  'share.hzje': 'Jumlah transfer',
  'share.qsrhzje': 'Silakan masukkan jumlah transfer',

  '_inv._title': 'Deskripsi Undangan',
  '_inv._t1': '1. Undangan dan hubungan yang diundang',
  '_inv._desc1': 'A ajak B, B ajak C, C ajak D. Level paling tinggi 4, kalau D ajak E lagi, maka E tidak ada sangkut pautnya dengan A.',
  '_inv._t2': '2. Pengundang mendapat imbalan',
  '_inv._desc2': 'A mendapat 4% dari total jumlah yang diselesaikan pesanan B, A mendapat 2% dari total jumlah yang diselesaikan pesanan C, dan A mendapat 1% dari total jumlah yang diselesaikan pesanan D',

  '_index._gm': 'Pembelian',
  '_index._sm': 'Deskripsi: Meningkatkan eksposur toko dan menarik lalu lintas',

  '_shop._update': '{n} kali/bulan, diubah {m} kali pada bulan ini',

  '_chat._hc': 'menarik kembali',

  '_footer._suom': 'Deskripsi Kebijakan',

  '_footer._platform._qualification': 'Kualifikasi perusahaan',
  '_store._ptzz': 'Kualifikasi terkait platform Atles Mall',

  '_abt._gywm': 'Tentang kami',
  '_abt._syms': 'Model bisnis',
  '_abt._qyzr': 'Tanggung jawab perusahaan',
  '_abt._lxwm': 'Hubungi kami',

  '_wsj._wsjms': 'Rayakan Halloween dengan penuh gaya di toko Halloween kami yang seram. Baik Anda sedang mencari trik atau suguhan selama musim seram ini, koleksi kostum, dekorasi, dan aksesori Halloween kami memiliki sesuatu untuk seluruh keluarga. Buat pernyataan yang menakutkan dengan koleksi wanita dan pria kami, atau bebaskan imajinasi anak Anda dengan kostum kostum. Dari peralatan rumah tangga dan mainan lucu yang tak terlupakan, hingga kecantikan dan kosmetik glamor, temukan pakaian yang sempurna untuk merayakan liburan paling menarik tahun ini ',

  '_wsj._b1': 'Dekorasi Rumah Musim Gugur',
  '_wsj._b2': 'Labu',
  '_wsj._b3': 'Rumah',
  '_wsj._b4': 'Anak-anak',
  '_wsj._b5': 'Bayi',
  '_wsj._b6': 'Wanita',
  '_wsj._b7': 'Gaun Mewah',
  '_wsj._b8': 'Dipersonalisasi',

  '_wsj._cj': 'Bergabung',
  '_wsj._gz': 'Aturan aktivitas',
  '_wsj._flss': 'Pencarian kategori',
  '_wsj._wsnz': 'Pakaian wanita Halloween',
  '_wsj._wsn': 'Pakaian pria Halloween',
  '_wsj._wst': 'Pakaian anak Halloween',
  '_wsj._wszs': 'Dekorasi Halloween',
  '_wsj._wslp': 'Hadiah Halloween',

  '_wsj._desc1': '"Spesial Halloween, Trik atau Suguhan!" Bulan Oktober ini, kami telah menyiapkan pesta karnaval untuk Anda! Datang dan dapatkan semua jenis produk yang menakutkan dan mengejutkan, diskon besar menanti Anda! ',
  '_wsj._lkm': 'Pergi belanja',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween akan datang',
  '_wsj._mzbt': 'Kalender kedatangan kecantikan',
  '_wsj._mznr': 'Hitungan mundur menuju waktu terindah tahun ini baru saja tiba dengan kalender kedatangan yang cocok untuk semua orang.',

  '_chr._tt1': 'Belanja sepanjang Natal',
  '_chr._tt2': 'Dekorasi Natal',
  '_chr._tt3': 'Belanja semua Hadiah',
  '_chr._tt4': 'Belanja semua Pakaian Tidur Natal',

  '_chr._dt1': 'Menjelang Natal sama ajaibnya dengan hari besarnya. Jadi, siapkan hadiah Anda, hiasi aula Anda, dan piyama serasi Anda siap untuk keajaiban Natal.',

  '_chr._dtt1': 'Pakaian Natal',
  '_chr._dtt2': 'Mencocokkan Natal Keluarga',
  '_chr._dtt3': 'Kalender Advent',
  '_chr._dtt4': 'Natal di Rumah',
  '_chr._dtt5': 'Toko Nyaman',
  '_chr._dtt6': 'Furnitur Tepat Waktu Untuk Natal',

  '_chr._tre1': 'Tren sekarang',
  '_chr._tre2': 'Tambahkan sedikit kemeriahan perayaan ke rumah Anda dengan tren yang disukai semua orang.',
  '_chr._tre3': 'Roti jahe',
  '_chr._tre4': 'Puding Natal',
  '_chr._tre5': 'Natal Gonks',

  '_chr._ttre1': 'Kartu & Kertas Pembungkus',
  '_chr._ttre2': 'Mainan & Permainan',
  '_chr._ttre3': 'Hadiah Makanan & Minuman',
  '_chr._ttre4': 'Pengisi Stocking',
  '_chr._ttre5': 'Sinterklas Rahasia',
  '_chr._ttre6': 'Natal Pertama Bayi',
  '_chr._ttre7': 'Hadiah Kecantikan',
  '_chr._ttre8': 'Hadiah Premium',
  '_chr._ttre9': 'Hadiah yang Dipersonalisasi',

  '_sdj._sdnz': 'Pakaian wanita',
  '_sdj._sdn': 'Pakaian Pria',
  '_sdj._sdetlw': 'hadiah',
  '_sdj._sdzs': 'Dekorasi',
  '_sdj._sdjj': 'mainan',

  '_sdj._title1': 'Natal akan tiba',
  '_sdj._desc1': '"Natal: musim memberi dan bersyukur." Bulan Desember ini, kami telah menyiapkan pesta keluarga yang didekorasi dengan cerah dan hangat untuk Anda! Datang dan dapatkan berbagai produk cerah dan hangat dengan diskon besar menanti Anda! ',

  '_ssd._lhpp': 'Pembayaran fleksibel',
  '_ssd._lzhq': 'Sumber Global',
  '_ssd._pwyx': 'dinilai sangat baik',
  '_ssd._qsth': 'Pengembalian mudah',
  '_ssd._jgppcn': 'Pencocokan Harga dan Komitmen',
  '_ssd._ztbt': 'Ciptakan belanja terpadu global',
  '_ssd._yzspl': 'Sumber inventaris langsung dari lebih dari 8.000 merek independen',
  '_ssd._zcgm': 'Daftar untuk membeli',
  '_ssd._zccs': 'Daftar untuk dijual',

  '_ssd._dlwz': 'Masuk dan daftar',

  '_ssd._qjrgwc': 'Tambahkan semua ke troli',

  '_zddy._bdhb': 'Mata uang lokal',
  '_zddy._xzbdhb': 'Pilih mata uang lokal',
  '_zddy._zje': 'Jumlah total',
  '_zddy._xzzffs': 'Silakan pilih metode pembayaran yang sesuai dengan Anda',
  '_zddy._jmhb': 'Mata uang kripto',
  '_sjsy._ddpl': 'Daftar Pesanan',
  '_zddy._fhsy': 'halaman Depan',
  '_yhqd._czbz': 'Silakan hubungi layanan pelanggan khusus untuk mengonfirmasi informasi isi ulang sebelum mengisi ulang.',
  '_yhqd._lxkf': 'Hubungi Layanan Pelanggan',
  '_yhqd._qsrje': 'Silakan masukkan jumlah isi ulang',
  '_yhqd._wrhyhtd': 'Tidak ada akses bank',
  '_yhqd._yhzz': 'Transfer Bank',

  '_yhbt._yhlx': 'Jenis bank',
  '_txcd._qsrtxje': 'Silakan masukkan jumlah penarikan',
  '_txcd._skr': 'Penerima pembayaran',
  '_txcd._skr._ms': 'Silakan masukkan nama pemegang kartu',
  '_txcd._skzh': 'Menerima akun',
  '_txcd._skzh._ms': 'Silakan masukkan nomor rekening pembayaran',
  '_txcd._tjyhk': 'Tambahkan kartu bank',
  '_txcd._txje': 'Jumlah penarikan',
  '_txcd._wdyhdz': 'Alamat bank',
  '_txcd._yhdm': 'Kode',
  '_txcd._yhdm._ms': 'Silakan masukkan kode bank',
  '_txcd._yhdz': 'alamat',
  '_txcd._yhdz._ms': 'Silakan masukkan alamat bank',
  '_txcd._yhlx': 'jenis',
  '_txcd._yhlx._ms': 'Silakan masukkan jenis kartu bank',
  '_txcd._yhly': 'rute',
  '_txcd._yhly._ms': 'Silakan masukkan nomor perutean bank',
  '_txcd._yhmc': 'bank',
  '_txcd._yhmc._ms': 'Silakan masukkan nama bank',
  '_txcd._yhtx': 'Penarikan bank',

  '_jys._gmjmhb': 'Beli mata uang kripto',
  '_jys._gmzy': 'Anda dapat mendaftarkan akun dan membeli mata uang kripto dengan mengunjungi bursa berikut dan sesuai dengan kebijakan negara Anda saat ini. Kemudian isi ulang ke akun platform! '
}
