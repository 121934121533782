<template>
  <div>
    <!-- 活动轮播图 -->
    <ActivityBanner></ActivityBanner>
    <a-layout :style="{ height: screenHeight + 'px', minHeight: '1000px' }">
      <!-- <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible theme="light" :width="250">
      <div class="logo">
        <shop-outlined v-show="collapsed" />
        <div v-show="!collapsed" class="shop-box">
          <router-link :to="{ name: 'index' }" class="s-box">
            <div class="shop-name">{{ store.name }}</div>
            <div class="shop-sid">（SID:{{ store.sid }}）</div>
          </router-link>
          <div class="lev-bg" v-if="store.level > 5">
            <div v-for="(vo, k) in store.level - 5" :key="k">
              <img src="@/static/level/c.png" />
            </div>
          </div>
          <div class="lev-bg" v-else>
            <div v-for="(vo, k) in store.level" :key="k">
              <img src="@/static/level/b.png" />
            </div>
          </div>
        </div>
      </div>
    </a-layout-sider> -->
      <a-layout>
        <a-layout-header class="al-header">
          <div style="display: flex; flex: 1">
            <div class="kan">
              <!-- <router-link :to="{ name: 'index' }" class="s-box" style="color: #fff">
              <div class="shop-name">{{ store.name }}</div>
              <div class="shop-sid">（SID:{{ store.sid }}）</div>
            </router-link>
            <div>
              <div class="lev-bg" v-if="store.level > 5">
                <div v-for="(vo, k) in store.level - 5" :key="k">
                  <img src="@/static/level/c.png" />
                </div>
              </div>
              <div class="lev-bg" v-else>
                <div v-for="(vo, k) in store.level" :key="k">
                  <img src="@/static/level/b.png" />
                </div>
              </div>
            </div> -->
              <router-link :to="{ name: 'storeHome', query: { id: store.id } }" class="s-box shop-box">
                <div class="shop-box" style="align-items: flex-start; color: #fff">
                  <div style="">
                    <div class="shop-name">{{ store.name }}</div>
                  </div>
                  <!-- SID -->
                  <div class="shop-sid" v-if="store.sid">UID:{{ store.sid }}</div>
                  <div>
                    <div class="lev-bg" v-if="store.level > 5">
                      <div v-for="(vo, k) in store.level - 5" :key="k">
                        <img src="@/static/level/c.png" />
                      </div>
                    </div>
                    <div class="lev-bg" v-else>
                      <div v-for="(vo, k) in store.level" :key="k">
                        <img src="@/static/level/b.png" />
                      </div>
                    </div>
                  </div>
                  <!-- </a-tooltip> -->
                </div>
              </router-link>
              <div class="shop-box" style="align-items: center">
                <router-link :to="{ name: 'index' }" class="s-box fanh" style="">
                  <rollback-outlined style="font-size: 25px" />
                  <div>{{ $t('_zddy._fhsy') }}</div>
                </router-link>
              </div>
            </div>

            <!-- 菜单 -->
            <div style="width: 100%">
              <a-menu theme="dark" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys" mode="horizontal" @click="onMenu">
                <!-- 仪表盘 -->
                <a-menu-item :key="MENU.dashboard">
                  <dashboard-outlined style="font-size: 18px" />
                  <span>{{ $t('store.dashboard') }}</span>
                </a-menu-item>
                <!-- 产品 -->
                <a-sub-menu :key="MENU.products">
                  <template #title>
                    <shop-outlined />
                    <a-badge :dot="msgCenter.comment == 2">
                      <span>{{ $t('store.products') }}</span>
                    </a-badge>
                  </template>
                  <a-menu-item :key="MENU.productsList">{{ $t('store.products.list') }}</a-menu-item>
                  <a-menu-item :key="MENU.productsReviews">
                    <a-badge :dot="msgCenter.comment == 2" style="color: #a6a690">
                      {{ $t('reviews.title') }}
                    </a-badge>
                  </a-menu-item>
                </a-sub-menu>
                <!-- 订单 -->
                <a-menu-item :key="MENU.order">
                  <upload-outlined style="font-size: 18px" />
                  <a-badge :dot="msgCenter.order > 0">
                    <span>{{ $t('store.orders') }}</span>
                  </a-badge>
                </a-menu-item>
                <!-- 钱包 -->
                <a-menu-item :key="MENU.wallet">
                  <transaction-outlined style="font-size: 18px" />
                  <span>{{ $t('store.wallet') }}</span>
                </a-menu-item>
                <!-- 广告推广 -->
                <a-sub-menu :key="MENU.advertise">
                  <template #title>
                    <fund-projection-screen-outlined style="font-size: 18px" />
                    <span>{{ $t('advertise.title') }}</span>
                  </template>
                  <a-menu-item :key="MENU.advertiseList">{{ $t('adv.list.title') }}</a-menu-item>
                  <a-menu-item :key="MENU.advertiseFlow">{{ $t('flow.lltg') }}</a-menu-item>
                </a-sub-menu>
                <!-- 消息中心 -->
                <a-menu-item :key="MENU.message">
                  <message-outlined style="font-size: 18px" />
                  <a-badge :count="msgCenter.msg">
                    <span>{{ $t('store.message') }}</span>
                  </a-badge>
                </a-menu-item>
                <!-- 分享 -->
                <a-sub-menu :key="MENU.share">
                  <template #title>
                    <share-alt-outlined style="font-size: 18px" />
                    <span>{{ $t('setting.invitation') }}</span>
                  </template>
                  <a-menu-item :key="MENU.storeShare">{{ $t('setting.invitation') }}</a-menu-item>
                  <a-menu-item :key="MENU.storeShareList">{{ $t('share.yqlb') }}</a-menu-item>
                </a-sub-menu>
                <!-- 设置 -->
                <a-sub-menu :key="MENU.setting">
                  <template #title>
                    <setting-outlined style="font-size: 18px" />
                    <span>{{ $t('store.setting') }}</span>
                  </template>
                  <a-menu-item :key="MENU.storeStore">{{ $t('setting.sjxx') }}</a-menu-item>
                  <a-menu-item :key="MENU.storeAuth">{{ $t('setting.smrz') }}</a-menu-item>
                  <a-menu-item :key="MENU.storeSafety">{{ $t('setting.aqzx') }}</a-menu-item>
                  <a-menu-item :key="MENU.storeNotify">{{ $t('setting.tzxx') }}</a-menu-item>
                  <a-menu-item :key="MENU.storeMyHome">{{ $t('setting.dpzx') }}</a-menu-item>
                  <a-menu-item :key="MENU.storeBenefit">{{ $t('setting.sjqy') }}</a-menu-item>
                  <a-menu-item :key="MENU.storeRule">{{ $t('setting.spgzyzy') }}</a-menu-item>
                </a-sub-menu>
              </a-menu>
            </div>
          </div>
          <div style="display: flex">
            <!-- <div>
            <menu-unfold-outlined v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)" />
            <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />
          </div> -->
            <div class="header-right">
              <div class="css-setting">
                <a-badge :count="msgCenter.msg">
                  <router-link :to="{ name: 'storeMessage' }">
                    <div class="d-box">
                      <alert-outlined :style="{ fontSize: '18px', color: '#fff' }" />
                      <div class="d-lang">
                        {{ $t('text.messages') }}
                      </div>
                    </div>
                  </router-link>
                </a-badge>
              </div>
              <div class="css-setting">
                <!-- <router-link :to="{ name: 'storeSetting' }"> -->
                <a-dropdown>
                  <a class="ant-dropdown-link d-box">
                    <!-- <setting-outlined :style="{ fontSize: '18px', color: '#fff' }" /> -->
                    <!-- <div class="d-lang">{{ language.text }}</div> -->

                    <div class="langs-box">
                      <div :class="'langs-img langs-' + language.lang"></div>
                      <span class="tit">{{ language.text }}</span>
                    </div>
                  </a>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item @click="onLanguage($event, item.key)" v-for="(item, i) in list" :key="i">
                        <a class="switcher-item" href="javascript:;">
                          <div class="langs-box">
                            <div :class="'langs-img langs-' + item.key"></div>
                            <span class="lang-item" style="padding: 0">
                              {{ item.title }}
                            </span>
                          </div>
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>

                <!-- </router-link> -->
              </div>
              <div>
                <a-dropdown>
                  <div class="css-avatar">
                    <div class="c-avatar">
                      <a-avatar :size="45">
                        <template #icon>
                          <img v-if="store.avatar" :src="store.avatar" alt="" />
                          <UserOutlined v-else />
                        </template>
                      </a-avatar>
                    </div>
                    <div>
                      <div class="cname" style="width: 70px; overflow: hidden; text-overflow: ellipsis">{{ store.name }}</div>
                      <div class="cdesc">seller</div>
                    </div>
                    <a class="ant-dropdown-link d-box">
                      <caret-down-outlined :style="{ fontSize: '18px', color: '#fff' }" />
                    </a>
                  </div>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item @click="onSignout()">
                        <a class="switcher-item" href="javascript:;">{{ $t('signup.sign.out') }}</a>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
          </div>
        </a-layout-header>
        <!-- <div class="top-banner-container" v-if="current.name == MENU.dashboard" style="margin: 3px 3px">
        <div class="top-banner" :style="'background-image: url(' + require('@/static/img2/120.png') + '); background-size: 100% 100%'">
          <div class="tb-box">
            <router-link class="tb-join" :to="{ name: 'StoreAddFromHouse', query: { search: 'halloween' } }"> {{ $t('_wsj._cj') }} </router-link>
            <router-link class="tb-rule" :to="{ name: 'storeDocs', query: { key: 'halloween' } }"> {{ $t('_wsj._gz') }} </router-link>
          </div>
        </div>
      </div> -->
        <!-- <div class="top-banner-container" v-if="current.name == MENU.dashboard" style="margin: 3px 3px">
          <div class="top-banner" :style="'background-image: url(' + require('@/static/img2/121.png') + '); background-size: 100% 100%'">
            <div class="tb-box">
              <router-link class="tb-join" :to="{ name: 'StoreAddFromHouse', query: { search: 'christmas' } }"> {{ $t('_wsj._cj') }} </router-link>
              <router-link class="tb-rule" :to="{ name: 'storeDocs', query: { key: 'christmas' } }"> {{ $t('_wsj._gz') }} </router-link>
            </div>
          </div>
        </div> -->
        <a-layout-content :style="{ margin: '0 16px 24px 16px', padding: '24px' }">
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
  <!-- 页脚 -->
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { RollbackOutlined, CaretDownOutlined, ShopOutlined, DashboardOutlined, UserOutlined, UploadOutlined, SettingOutlined, AlertOutlined, MessageOutlined, FundProjectionScreenOutlined, TransactionOutlined, ShareAltOutlined } from '@ant-design/icons-vue'
const MENU = {
  dashboard: 'storeDashboard',
  products: 'storeProducts',
  productsList: 'storeProductsList',
  productsReviews: 'storeProductsReviews',
  order: 'storeOrderList',
  wallet: 'storeWallet',
  advertise: 'storeAdvertise',
  advertiseList: 'storeAdvertiseList',
  advertiseFlow: 'storeAdvertiseFlow',
  advertiseAdd: 'advertiseAdd',
  setting: 'storeSetting',
  storeStore: 'storeStore',
  storeAuth: 'storeAuth',
  storeSafety: 'storeSafety',
  storeMyHome: 'storeMyHome',
  storeNotify: 'storeNotify',
  storeBenefit: 'storeBenefit',
  message: 'storeMessage',
  share: 'share',
  storeShare: 'storeShare',
  storeShareList: 'storeShareList',
  other: 'other',
  storeRule: 'storeRule'
}
import { getCurrentInstance } from 'vue'
import { setLangauge } from '@/utils/vue-i18n'
import ActivityBanner from '@/components/ActivityBanner.vue'
import { mapState } from 'vuex'
export default defineComponent({
  name: 'layoutVue',
  components: {
    ShopOutlined,
    DashboardOutlined,
    UserOutlined,
    UploadOutlined,
    // MenuUnfoldOutlined,
    // MenuFoldOutlined,
    SettingOutlined,
    AlertOutlined,
    MessageOutlined,
    FundProjectionScreenOutlined,
    TransactionOutlined,
    ShareAltOutlined,
    CaretDownOutlined,
    RollbackOutlined,
    ActivityBanner
  },
  setup() {
    const state = reactive({
      selectedKeys: [MENU.dashboard],
      openKeys: [],
      collapsed: false
    })

    const { proxy } = getCurrentInstance()
    function change(lang) {
      proxy.$i18n.locale = lang
      setLangauge(lang)
      console.log('切换语言', lang)
    }

    return {
      ...toRefs(state),
      change
    }
  },
  data() {
    return {
      //菜单
      MENU,
      screenHeight: document.body.clientHeight,
      current: {},

      // 店铺信息
      store: {},

      //语言
      language: {
        text: 'English',
        lang: 'en-us'
      },

      //循环
      ajaxMsg: null,
      ajaxTime: 2000,
      // msgCenter: {
      //   msg: 0,
      //   order: 0
      // },

      list: [
        {
          title: 'English',
          key: 'en-us'
        },
        {
          title: '한국어',
          key: 'ko-kr'
        },
        {
          title: '日本語',
          key: 'ja-jp'
        },
        {
          title: 'Deutsch',
          key: 'de-de'
        },
        {
          title: 'عربي',
          key: 'ar-sa'
        },
        {
          title: 'Русский',
          key: 'be-by'
        },
        {
          title: 'Română',
          key: 'ro-ro'
        },
        {
          title: 'Français',
          key: 'fr-fr'
        },
        {
          title: 'Türkçe',
          key: 'tr-tr'
        },
        {
          title: 'Italiano',
          key: 'it-it'
        },
        {
          title: 'Suomalainen',
          key: 'fi-fi'
        },
        {
          title: 'España',
          key: 'es-es'
        },
        {
          title: 'Danmark',
          key: 'dk-da'
        },
        {
          title: 'Nederland',
          key: 'nl-nl'
        },
        {
          title: 'Tiếng Việt',
          key: 'vi-vn'
        },
        {
          title: 'Português',
          key: 'pt-pt'
        },
        {
          title: 'Bahasa Indonesia',
          key: 'id-id'
        },
        {
          title: '繁體中文',
          key: 'zh-hant'
        }
      ]
    }
  },
  watch: {
    $route(newRoute) {
      this.setCurrent(newRoute)
    }
  },
  created() {
    this.setCurrent(this.$route)

    // 获取config
    this.language = JSON.parse(localStorage.getItem('config'))

    //获取店铺基本信息
    this.$api.store.getStoreInfo().then((res) => {
      this.store = res.data
    })

    this.$store.dispatch('base/getBase', {})

    // this.onSocket()
  },
  computed: {
    ...mapState({
      msgCenter: (state) => state.base.msgCenter
    })
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          this.screenHeight = document.body.clientHeight
        })
      })()
    }
    // 定时获取消息通知
    // this.runAjaxMsg()
  },
  beforeUnmount() {
    // clearInterval(this.ajaxMsg)
  },
  methods: {
    /**
     * 异步循环获取消息
     */
    // async runAjaxMsg() {
    //   //let that = this
    //   await this.$api.home.getBase().then((res) => {
    //     this.msgCenter = res.data
    //   })
    //   this.ajaxMsg = setTimeout(this.runAjaxMsg, this.ajaxTime)
    // },
    // onSocket() {
    //   //连接
    //   const that = this
    //   this.$socket.on('base', function (data) {
    //     that.msgCenter = data
    //   })
    // },
    /**
     * 切换语言
     */
    onLanguage(e, lang) {
      console.log(e)

      this.language = {
        text: e.target.innerText,
        lang: lang
      }

      localStorage.setItem('config', JSON.stringify(this.language))
      this.change(this.language.lang)

      this.$router.go(0)
    },

    onMenu(vo) {
      this.$router.push({ name: vo.key })
    },
    setCurrent(route) {
      this.current = route
      this.selectedKeys = [route.meta.key, route.name]
      // this.openKeys = [route.meta.name]

      // console.log(this.selectedKeys, this.openKeys)
    },
    /**
     * 退出登录
     */
    onSignout() {
      localStorage.removeItem('sp_token')
      localStorage.removeItem('sp_info')
      this.isLogin = false
      // this.$socket.close()
      this.$emit('onSignout', [])
      this.$router.push({ name: 'index' })
    }
  }
})
</script>
<style scoped src="@/static/css/store-css.css"></style>

<style>
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #30455e;
  color: #f1c010;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  background-color: #30455e;
  color: #f1c010;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: #30455e;
  color: #f1c010;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #30455e;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover span {
  color: #f1c010;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #30455e;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #f1c010;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #f1c010;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #fff;
  background: #000000;
  font-weight: 700;
  font-size: 14px;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
  margin: 0 0.5px;
}
.ant-menu-dark .ant-badge span {
  color: #fff;
}
/* .ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: 2px solid #53aafb;
} */

.al-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  background: #000;
  color: #fff;
}
.shop-box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.shop-name {
  max-width: 215px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 20px;
}
.shop-sid {
  font-size: 13px;
  font-weight: 400;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout .site-layout-background {
  background: #fff;
}

.d-box {
  display: flex;
  align-items: center;
  height: 25px;
}

.d-lang {
  margin-left: 5px;
  color: #9e9e9e;
}
.s-box {
  width: 100%;
  display: flex;
  align-items: center;
}

.kan {
  display: flex;
  /* flex-direction: column; */
  line-height: initial;
  height: 100%;
  justify-content: center;
  padding: 0 10px;
  gap: 10px;
  width: 210px;
}

.top-banner {
  position: relative;
  height: 60px;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tb-box {
  position: absolute;
  right: 13%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tb-join {
  border: 3px solid #fff;
  padding: 1px 10px;
  border-radius: 10px;
  color: #fff;
  /* position: absolute; */
  /* right: 13%; */
}

.tb-rule {
  border: 3px solid #fff;
  padding: 1px 10px;
  border-radius: 10px;
  color: #fff;
  /* position: absolute; */
  /* right: 13%; */
}

.fanh {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  width: auto;
  padding: 5px;
  color: #fff;
}
.fanh:hover {
  background-color: #30455e;
}

.fanh1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  width: auto;
  padding: 5px;
}
.fanh1:hover {
  background-color: #f9f9f9;
}
</style>
