export default {
  'nav.account': 'Henkilökohtainen keskus',
  'nav.language': 'yksinkertaistettu kiina',
  'signup.success': 'Rekisteröityminen onnistui',

  //virhe
  'contact.empty': 'Yhteystiedot eivät voi olla tyhjiä',
  'phone.country.empty': 'Puhelimen etuliite ei voi olla tyhjä',
  'phone.empty': 'Puhelin ei voi olla tyhjä',
  'email.valid': 'Anna oikea sähköpostiosoite',

  // pohja
  'base.success': 'menestys',
  'base.save': 'Tallenna',
  'base.slide': 'Vahvista vetämällä liukusäädintä',
  'base.send.code': 'Lähetä vahvistuskoodi',
  'base.send.time': '({time}) lähetä vahvistuskoodi uudelleen',
  'base.send.success': 'Lähetä onnistuneesti',
  'base.view.more': 'Näytä lisää',
  'base.Language': 'Language',
  'base.previous': 'Edellinen sivu',
  'base.next': 'Seuraava sivu',
  'base.total.page': 'Yhteensä {sivu} sivua',
  'base.go.to.page': 'Siirry sivulle',
  'base.go': 'siirry',
  'base.followers': 'Seuraajat',
  'base.follow': 'Follow',
  'base.following': 'Seurattu',
  'base.visit.store': 'Selaa kauppaa',
  'base.contact': 'Ota yhteyttä',
  'base.delivery': 'Arvioitu toimitusaika: 1-7 päivää',
  'base.search': 'Hae',
  'base.search.text': 'Anna avainsanahaku',
  'base.server': 'Online-asiakaspalvelu',

  //indeksi
  'text.categories.title': 'Tuoteluokka',
  'text.welcome': 'Tervetuloa ostoksille!',
  'text.wishlist': 'Tykkää lista',
  'text.account': 'Personal Center',
  'text.orders': 'Tilaukset',
  'text.messages': 'Viestit',
  'text.suggest.title': 'Yksinoikeustarjous',
  'text.suggest.content': 'Vain uusille maailmanlaajuisille premium-jäsenille!',
  'text.super.deals': 'Huipputuotteet, uskomattomat hinnat.',

  //Kirjaudu
  'signup.register': 'Rekisteröidy',
  'signup.signin': 'Kirjaudu',
  'signup.store.signin': 'Yrityskirjautuminen',
  'signup.sign.out': 'Kirjaudu ulos',
  'signup.email': 'Sähköpostiosoite',
  'signup.password': 'Salasana',
  'signup.qr.password': 'Vahvista salasana',
  'signup.confirm.password': 'Vahvista salasana',
  'signup.forgot.password': 'Unohditko salasanan',
  'signup.invitation': 'Kutsukoodi',
  'signup.char': 'Hahmo',
  'signup.contains': 'Sisältää numeroita, kirjaimia tai symboleja',
  'signup.qr.contains': 'Kaksi salasanaa ovat ristiriidassa',
  'signup.create.account': 'Luo tili',
  'signup.agree': 'Luo tili, hyväksyt',
  'signup.member.agreement': 'Jäsensopimus',
  'signup.and': 'ja',
  'signup.privacy.policy': 'Tietosuojakäytäntö',
  'signup.email.code': 'Sähköpostin vahvistus',
  'signup.last.step': 'Viimeinen vaihe',
  'signup.send.email': 'Anna nelinumeroinen koodi, joka lähetettiin osoitteeseen {email}',
  'signup.modify.email': 'Muokkaa sähköpostiosoitetta',
  'signup.verify.email': 'Vahvista sähköpostiosoite',
  'signup.have.store': 'Oma kauppiastili',
  'signup.goto.signin': 'Yrityskirjautuminen',
  'signup.no.store': 'Ei kauppiastiliä',
  'signup.goto.store': 'Signup Merchant',
  'signup.next': 'Seuraava',
  'signup.your.email': 'sähköpostiosoitteesi',
  'signup.code.text': 'Vahvistuskoodi',
  'signup.submit.signup': 'Rekisteröidy nyt',
  'signup.smrz': 'Oikean nimen todennus',
  'signup.derb': 'Toinen vaihe',
  'signup.qsrxm': 'Anna asiakirjan nimi',
  'signup.qsrhm': 'Anna tunnusnumero',

  //unohdin
  'forgot.title': 'Palauta salasana',
  'forgot.btn.check': 'Vahvista sähköpostiosoite',
  'forgot.reset.now': 'Nollaa nyt',

  //myymälä
  'store.info.open': 'Avoinna {y} viikkoa',

  // alatunniste
  'footer.great.value': 'Suuri arvo',
  'footer.great.value.desc': 'Tarjoamme kilpailukykyiset hinnat yli 100 miljoonalle tuotteelle.',
  'footer.shopping': 'Global Shopping',
  'footer.shopping.desc': 'Toimitamme yli 200 maahan ja alueelle, ja verkkosivustomme on saatavilla 7 kielellä.',
  'footer.safe.payment': 'Turvallinen maksu',
  'footer.safe.payment.desc': 'Maksa maailman suosituimmilla ja turvallisimmilla maksutavoilla.',
  'footer.shop.with. luottamus': 'Osta luottavaisin mielin',
  'footer.shop.with. luottamus.desc': 'Ostajan suojakäytäntömme kattaa koko ostoprosessisi.',
  'footer.help.center': 'Ohjekeskus',
  'footer.help.center.desc': 'Apua jokasään luomaan sujuva ostoskokemus.',
  'footer.terms.conditions': 'Käyttöehdot',
  'footer.return.policy': 'Palautuskäytäntö',
  'footer.support.policy': 'Tukikäytäntö',
  'footer.privacy.policy': 'Tietosuojakäytäntö',
  'footer.be.seller': 'Ryhdy myyjäksi',
  'footer.apply.now': 'Hae nyt',
  'footer.stay.connected': 'Pysy yhteydessä',

  'footer.about.us': 'Tietoja meistä',
  'footer.about.company': 'Yrityksen profiili',
  'footer.about.video': 'Videon esittely',
  'footer.contact': 'Yhteystiedot',

  'footer.my.account': 'Oma tili',
  'footer.my.logout': 'Kirjaudu ulos',
  'footer.my.order': 'Tilaushistoria',
  'footer.my.wish': 'Toivelistani',
  'footer.my.join': 'Ryhdy jäsenkumppaniksi',
  'footer.email': 'Sähköposti',
  'footer.gfemail': 'Virallinen sähköposti',
  'footer.fwemail': 'Palveluposti',
  'footer.address': 'Osoite',

  'apply.success': 'Hakemus onnistui',
  'apply.success.desc': 'Asennettu onnistuneesti, siirry kirjautumistilille',

  // luokat
  'category.title': 'Aiheeseen liittyvät luokat',
  'category.all': 'Kaikki luokat',

  //yksityiskohta
  'detail.store.home': 'Kaupan etusivu',
  'detail.sale.items': 'Tuoteluettelo',
  'detail.recommend': 'Recommend',
  'detail.orders': 'Sales',
  'detail.quantity': 'Määrä',
  'detail.pieces.available': 'Saatavilla',
  'detail.delivery': 'Toimitus',
  'detail.free.shipping': 'Ilmainen toimitus',
  'detail.estimated.delivery': 'Arvioitu saapuminen',
  'detail.days': 'days',
  'detail.buy.now': 'Osta',
  'detail.add.to.cart': 'Lisää ostoskoriin',
  'detail.buyer.protection': 'Ostajan suoja',
  'detail.money.guarantee': 'Rahat takaisin -takuu',
  'detail.refund.desc': 'Saat täyden hyvityksen, jos tuote ei ole kuvauksen mukainen tai sitä ei ole toimitettu',
  'detail.description': 'Tuotteen kuvaus',
  'detail.customer.reviews': 'Asiakasarvostelut',
  'detail.specifications': 'Product Specifications',
  'detail.top.selling.products': 'Myydyimmät tuotteet',
  'detail.recommended.for.you': 'Suositellaan sinulle',
  'detail.sold': 'Myynti',
  'detail.receipt': 'Vahvista kuitti',
  'detail.receipt.title': 'Vahvista tilauksen vastaanottaminen? ',
  'detail.receipt.content': 'Vahvistuksen jälkeen tapahtumatilaus on valmis',
  'detail.comment': 'Comment',
  'detail.refund.title': 'Vahvista palautushakemus',
  'detail.refund.content': 'Vahvistuksen jälkeen tilaus hakee hyvitystä',
  'detail.sqtk': 'Hae hyvitystä',

  //vahvistaa
  'confirm.shipping.address': 'Vastaanottajan osoite',
  'confirm.change': 'Muuta',
  'confirm.payment.methods': 'Maksutavat',
  'confirm.summary': 'Sovitusratkaisu',
  'confirm.total.item.costs': 'Tuotteet yhteensä',
  'confirm.total': 'Yhteensä',
  'confirm.checkout': 'Siirry kassalle',
  'confirm.place.order': 'Tee tilaus nyt',
  'confirm.pay.now': 'Maksa nyt',
  'confirm.order.desc': "Klikkauksen jälkeen 'Tee tilaus' vahvistan, että olen lukenut ja vahvistanut",
  'confirm.order.policy': 'Kaikki ehdot ja käytännöt',
  'confirm.payment': 'Global Shopping varmistaa tietosi ja maksusi turvallisuuden',

  // osoite
  'address.title': 'Kuittiosoite',
  'address.default': 'oletus',
  'address.edit': 'Muokkaa',
  'address.delete': 'Poista',
  'address.new': 'Luo uusi osoite',
  'address.contact': 'Ota yhteyttä',
  'address.address': 'Osoite',
  'address.phone': 'Puhelinnumero',
  'address.set.default': 'Aseta oletus',
  'address.confirm': 'Vahvista',
  'address.cancel': 'Peruuta',
  'address.del.title': 'Vahvista osoitteen poistaminen?',
  'address.del.content': 'Haluatko varmasti poistaa tämän toimitusosoitteen?',

  'payment.method': 'Maksutapa',

  // Ostoskärry
  'shopping.title': 'Ostoskori',
  'shopping.back.buy': 'Takaisin erikoisostoksille',
  'shopping.start.shopping': 'Aloita ostokset',

  // jäsen
  'member.account': 'Personal Center',
  'member.overview': 'Yleiskatsaus',
  'member.orders': 'Tilaukset',
  'member.payment': 'Oma lompakkoni',
  'member.address': 'Kuittiosoite',
  'member.wishlist': 'Tykkää lista',
  'member.followlist': 'Follow list',
  'member.message.center': 'Viestikeskus',
  'member.setting': 'Henkilökohtaiset asetukset',
  'member.shop.info': 'Kaupan tiedot',
  'member.shop.setting': 'Kaupan asetukset',
  'member.order.notify': 'Uusi tilausilmoitus',
  'member.order.tips': 'Lähettämällä sähköposteja kauppiastilin sähköpostiin',

  // jäsen. järjestys
  'member.order.title': 'Oma tilaukseni',
  'member.order.view.all': 'Näytä kaikki',
  'member.order.all': 'Kaikki',
  'member.order.unpaid': 'paid',
  'member.order.paid': 'Maksettu',
  'member.order.procurement': 'ostettava',
  'member.order.seller.paid': 'Ostettu',
  'member.order.processing': 'käsiteltävä',
  'member.order.shipped': 'lähetetty',
  'member.order.return': 'Palaa',
  'member.order.completed': 'Valmis',
  'member.order.refund': 'Refunded',
  'member.order.all.time': 'kaikki',
  'member.order.empty': 'Ei vielä tilausta',
  'member.order.date': 'date',
  'member.order.purchase.date': 'Osta',
  'member.order.cpoy': 'Kopioi',
  'member.order.id': 'Tilaa',
  'member.order.detail': 'Tilauksen tiedot',
  'member.order.logistics': 'Logistiikkatiedot',

  // jäsenmaksu
  'member.payment.title': 'My Wallet',
  'member.wallet.balance': 'lompakon saldo',
  'member.crypto.recharge': 'Recharge',
  'member.crypto.withdrawal': 'Peruuttaminen',
  'member.crypto.bank': 'Pankkikortti',
  'member.wallet.record': 'kolikoiden talletustietue',
  'member.bankcard.record': 'Pankkikorttitietue',
  'member.withdrawal.record': 'Irtautumistietue',
  'member.income.record': 'tulotietue',
  'member.transaction.record': 'Tapahtumatietue',
  'member.wallet.freeze': 'Jäädä varat',
  'member.wallet.profit': 'Arvioidut tulot',

  //ladata
  'recharge.currency': 'Valuutta',
  'recharge.protocol': 'Protocol',
  'recharge.qrcode': 'QR-koodi',
  'recharge.address': 'Lompakon osoite',
  'recharge.copy': 'Kopioi osoite',
  'recharge.ok.text': 'Vahvista',
  'recharge.cancel.text': 'Peruuta',

  //pankki
  'bank.recharge.title': 'Pankkikortin lataus',
  'bank.title': 'Pankin nimi',
  'bank.name': 'nimi',
  'bank.account': 'Tili',
  'bank.routing': 'Routing',
  'bank.code': 'koodi',
  'bank.bankname': 'Pankin nimi',
  'bank.bankaddress': 'Pankin osoite',
  'bank.ok.text': 'Seuraava',
  'bank.ok.prev': 'Edellinen vaihe',
  'bank.submit': 'Lähetä nyt',
  'bank.amount': 'Lataa summa',
  'bank.amount.desc': 'Anna veloitussumma',
  'bank.type': 'type',
  'bank.usd': 'USD',
  'bank.eur': 'Euro',
  'bank.receipt.number': 'Kuitin numero',
  'bank.receipt.number.desc': 'Anna tapahtumakuitin numero',
  'bank.credential.picture': 'Tiedostokuva',
  'bank.credential.picture.desc': 'Lähetä tunnistekuva',
  'bank.remark': 'Huomautus',
  'bank.upload': 'Lataa kuvia',
  'bank.text.title': 'Huomautukset',
  'bank.text.t1': 'Et tarvitse huomautuksia siirtoa varten, jätä se tyhjäksi',
  'bank.text.t2': 'Valitse Hongkongin kotitalouden maaksi Hongkong, älä lisää Kiinaa',
  'bank.text.t3': 'Yhdysvaltain rahalähetykset, arkisin, rahalähetykset Eurooppaan ennen klo 15.00, rahalähetykset ovat saatavilla pankin aukioloaikoina!',
  'bank.text.t4': 'Maksuaika on T+1 ja viimeisin maksu on T+3 Euroopassa',
  'bank.text.t5': 'Ilmoita alustalle ennen rahansiirtoa varmistaaksesi, onko tili käytettävissä. Jos tili suljetaan, korvausta ei makseta.',
  'bank.text.t6': 'Talletus pankkikortilla. Asiakaspalvelun tiedot ovat ensisijaisia.',

  //pankkilista
  'bank.list.title': 'Pankin nimi',
  'bank.list.code': 'Pankkikoodi',
  'bank.list.amount': 'Siirtosumma',
  'bank.list.number': 'Kuitin numero',
  'bank.list.img': 'Kupon kuva',
  'bank.list.status': 'Status',
  'bank.list.time': 'time',
  'bank.list.status1': 'Tarkistus käynnissä',
  'bank.list.status2': 'Hyväksytty',
  'bank.list.status3': 'Hylätty',

  // Peruuttaminen
  'withdrawal.address.desc': 'Anna lompakon osoite!',
  'veloitus.numero': 'Number',
  'withdrawal.real.number': 'todellinen saapuminen',
  'withdrawal.number.desc': 'Syötä nostosumma!',
  'withdrawal.btn.all': 'kaikki',
  'withdrawal.balance': 'Balance',
  'withdrawal.commission': 'palkkio',
  'withdrawal.actual.amount': 'Kirjattu',
  'withdrawal.notice': 'Prompt',
  'withdrawal.notice.text': 'Varmista ennen siirtoa, että vastaanottajan osoitetiedot ovat oikein. Kun omaisuus on siirretty pois, niitä ei voi palauttaa.',
  'withdrawal.notice.content': '{nimi}({cp_name}) provisio: nykyinen markkina-arvo {fee_rate} %/kynä, vähimmäisstandardi: {maksu_min} {nimi}/kynä',
  'withdrawal.submit': 'Lähetä',
  'withdrawal.choice': 'Valitse kryptovaluutta',
  'withdrawal.yzm': 'Vahvistuskoodi',
  'withdrawal.fs': 'Lähetä',
  'withdrawal.qsryzm': 'Anna sähköpostin vahvistuskoodi',

  // vastaanottaa
  'recive.method': 'Protokolla',
  'recive.amount': 'Summa',
  'recive.address': 'osoite',
  'recive.date': 'aika',
  'recive.status': 'Tila',
  'recive.create.at': 'Tapahtuma-aika',
  'recive.type': 'Type',
  'recive.befor': 'ennen tapahtumaa',
  'recive.balance': 'Balance',
  'recive.freeze': 'Freeze',
  'recive.review': 'Arvostelee',
  'recive.success': 'Hyväksytty',
  'recive.reject': 'Hylätty',

  // mainostaa
  'advertise.title': 'Promotion Center',
  'advertise.shop.title': 'Shop Promotion',
  'advertise.shop.status': 'Tila',
  'advertise.shop.expired': 'Vanhentunut',
  'advertise.shop.promotion': 'Promoting',
  'advertise.shop.expire.date': 'Viimeinen voimassaolopäivä',
  'advertise.shop.renew': 'Mainoksen uusiminen',
  'advertise.shop.payable': 'Maksettava summa',
  'advertise.shop.explanation': 'Mainoksen selitys',
  'advertise.shop.text': 'Osallistu alustamainonnan edistämiseen, lisää myymälän näkyvyyttä ja mainosta tapahtumatilauksia',
  'advertise.shop.paynow': 'Uusi nyt',
  'advertise.shop.modal.title': 'Vahvista maksu',
  'advertise.shop.modal.desc': 'Vahvistettu kampanjamaksun maksamiseen',
  'advertise.shop.modal.btn': 'Vahvista maksu',

  //Toivelista
  'wishlist.title': 'Wishlist',
  'wishlist.delete': 'Poista',
  'wishlist.orders': 'Sales',

  //Seuraa listaa
  'followlist.title': 'Follow List',
  'followlist.delete': 'Poista',
  'followlist.follow': 'Follow',

  // kauppa
  'store.dashboard': 'Dashboard',
  'store.products': 'Tuotteet',
  'store.products.list': 'Tuoteluettelo',
  'store.products.reviews': 'Tuotearvostelut',
  'store.orders': 'Tilaukset',
  'store.wallet': 'Lompakko',
  'store.message': 'Viestikeskus',
  'store.setting': 'asetus',
  'store.order.total.profit': 'Arvioidut kokonaistulot',

  //mittaristo
  'dashboard.store.hour.views': 'Reaaliaikaiset käynnit',
  'dashboard.store.today.views': 'Tänään käynnit',
  'dashboard.product.total': 'Tuote yhteensä',
  'dashboard.product.today': 'Uutta tänään',
  'dashboard.order.total': 'Tilauksen kokonaismäärä',
  'dashboard.sales.total': 'Arvioitu liikevaihto',
  'dashboard.sales.real': 'Todellinen myynti',
  'dashboard.sales.pay': 'Total Payout',
  'dashboard.sales.profit': 'Kokonaisvoitto',
  'dashboard.commission.total': 'Kokonaistulot',
  'dashboard.commission.today': 'Tämän päivän tulos',
  'dashboard.order.sales': 'Hyödykemyynti',
  'dashboard.sales.list': 'Myynnin ranking',
  'dashboard.goods.cate.rate': 'Tuotteiden luokittelusuhde',
  'dashboard.goods.wish': 'Like product ranking',
  'dashboard.january': 'tammikuu',
  'dashboard.february': 'February',
  'dashboard.march': 'Maaliskuu',
  'dashboard.april': 'April',
  'dashboard.may': 'toukokuu',
  'dashboard.june': 'June',
  'dashboard.july': 'July',
  'dashboard.august': 'Elokuu',
  'dashboard.september': 'September',
  'dashboard.october': 'October',
  'dashboard.november': 'November',
  'dashboard.december': 'December',

  // Tuotteet
  'products.add.new': 'Lisää uusi tuote',
  'products.add.from.warehouse': 'Lisää tuotteita varastosta',
  'products.delete': 'Poista',
  'products.add': 'Lisää',
  'products.table.img': 'image',
  'products.table.name': 'tuotteen nimi',
  'products.table.category': 'Category',
  'products.table.wish': 'tykkää',
  'products.table.stock': 'stock',
  'products.table.price': 'Price',
  'products.table.profit': 'Profit',
  'products.table.action': 'Action',
  'products.search.category': 'Hae luokan mukaan',
  'products.back.product': 'Takaisin tuoteluetteloon',
  'products.total': 'Tavarat yhteensä',
  'products.yes': 'Kyllä',
  'products.no': 'Peruuta',
  'products.batch.add': 'Erän lisäys',
  'products.ask.add': 'Haluatko varmasti lisätä tuotteita?',
  'products.batch.delete': 'Erän poistaminen',
  'products.ask.delete': 'Haluatko varmasti poistaa tuotteen?',
  'products.top': 'Top',
  'products.syzd': 'Kotisivun alkuun',
  'products.zdwz': 'Korkein sijainti',
  'products.t1': 'tarjous',
  'products.t2': 'Preferred',
  'products.t3': 'Butique',
  'products.t4': 'ei mitään',

  // arvostelut
  'reviews.title': 'Tuotearvostelut',
  'reviews.product.name': 'Tuote',
  'reviews.user.name': 'Käyttäjä',
  'reviews.star': 'Arvio',
  'reviews.comment': 'kommentin sisältö',
  'reviews.sku': 'Tuotteen tiedot',
  'reviews.imgs': 'Atlas',
  'reviews.created': 'Päivämäärä',

  // kauppa. tilaus
  'store.order.purchase': 'Osta nyt',
  'store.order.purchase.desc': 'Haluatko varmasti maksaa tästä tuotteesta?',
  'store.order.purchase.yes': 'Maksa nyt',
  'store.order.purchase.no': 'Peruuta',
  'store.order.desc': 'Tämän tilauksen ostaminen vaatii maksun, ja saat etuja tilauksen valmistuttua. ',
  'store.order.no': 'Tilausnumero',
  'store.order.number': 'Määrä',
  'store.order.buyer': 'Ostaja',
  'store.order.total': 'Kokonaismäärä',
  'store.order.will.earning': 'Tuot',
  'store.order.profit': 'Tuotto',
  'store.order.dividends': 'Osingot',
  'store.order.payment.status': 'Maksun tila',
  'store.order.seller.buy.status': 'Oston tila',
  'store.order.status': 'Tilauksen tila',
  'store.order.date': 'tilauspäivä',
  'store.order.purchase.date': 'Ostopäivä',
  'store.order.action': 'Toiminta',
  'store.order.purchase.amount': 'Ostosumma',

  //tulo
  'income.create.at': 'Ennätysaika',
  'income.order.sn': 'Tilausnumero',
  'income.realpay': 'Tilauksen summa',
  'income.profit': 'tulot',

  //Asetus
  'setting.avatar': 'Business Avatar',
  'setting.upload': 'Lataa',
  'setting.shop.name': 'Kaupan nimi',
  'setting.shop.phone': 'Yhteyspuhelin',
  'setting.shop.address': 'Shop Address',
  'setting.shop.save': 'Tallenna',
  'setting.upload.pic': 'Lataa kuva',
  'setting.send.pic': 'Lähetä kuva',

  //beseller
  'beseller.title': 'Hae kauppiaaksi',
  'beseller.account': 'Tilitiedot',
  'beseller.store': 'Kaupan tiedot',
  'beseller.store.name': 'Kaupan nimi',
  'beseller.store.address': 'myymälän osoite',

  //tallentaa kotiin
  'store.home.title': 'Kotisivun asetukset',
  'store.home.topimg': 'yläkuva',
  'store.home.banner': 'Karusellikuva',
  'store.home.up3': 'Huomautuksia: vähintään 3 latausta',
  'store.upload.more': 'Lataa lisää kuvia',
  'store.home.columns': 'Sarakkeet',
  'store.home.bgimg': 'Taustakuva',
  'store.goods.remark': 'Huomautus: 5 tuotetta per rivi, enintään 10 tuotetta per lautanen',
  'store.home.select': 'Valitse tuote',
  'store.home.add': 'Lisää sarake',

  //
  'setting.update.user': 'Henkilökohtaiset tiedot',
  'setting.update.info': 'Muokkaa tietoja',
  'setting.user.avatar': 'Käyttäjän avatar',
  'setting.user.nickname': 'Käyttäjän lempinimi',
  'setting.user.nickname.desc': 'Anna käyttäjän lempinimi',

  'setting.safe.title': 'Turvallisuustiedot',
  'setting.user.passwd': 'Vaihda salasana',
  'setting.passwd.title1': 'Vanha salasana',
  'setting.passwd.desc1': 'Anna vanha salasana',
  'setting.passwd.title2': 'Uusi salasana',
  'setting.passwd.desc2': 'Anna uusi salasana',
  'setting.passwd.title3': 'Vahvista salasana',
  'setting.passwd.desc3': 'Anna vahvistussalasana',
  'setting.invitation': 'Kutsu ystäviä',
  'setting.copy': 'Kopioi',

  'adv.add.products': 'Lisää kampanjatuotteita',
  'adv.list.title': 'Tuotteen edistäminen',
  'adv.type': 'Promotion Type',
  'adv.begin.time': 'Promotion time',
  'adv.end.time': 'Loppuaika',
  'adv.status': 'Tila',
  'adv.status.s1': 'Promoting',
  'adv.status.s2': 'Loppu',
  'adv.add.back': 'Palaa korotettuun luetteloon',
  'adv.recharge': 'Lataa tarjouspisteitä',
  'adv.select.goods': 'Valitse tavarat',
  'adv.select.btn': 'Promoted Products',
  'adv.recharge.title': 'Recharge Promotion Points',
  'adv.recharge.balance': 'Pistesaldo',
  'adv.point': 'piste',
  'adv.point.rate': 'Pistesuhde',
  'adv.recharge.amount': 'Lataa summa',
  'adv.recharge.input.amount': 'Anna lataussumma',
  'adv.select.mod': 'Valitse paneeli',
  'adv.mod1': 'Suositellut sarakkeet',
  'adv.mod2': 'Suositeltu sarake',
  'adv.mod3': 'Kuuma alennussarake',
  'adv.mod4': 'Erinomainen lista',
  'adv.mod5': 'Luokitteluluettelo',
  'adv.mod6': 'Yksityiskohtainen kampanja',
  'adv.ge': 'Määrä',
  'adv.xs': 'tunti',
  'adv.xzbk': 'Valitse levy',
  'adv.syme': 'Jäljellä oleva kiintiö',
  'adv.tgsc': 'Tarjouksen kesto',
  'adv.tgjg': 'Tarjoushinta',
  'adv.syye': 'Nykyinen saldo',
  'adv.tgye': 'Tarjouksen saldo',
  'adv.yj1': 'odotettu',
  'adv.yj2': 'Aloita promootio',
  'adv.pay.point': 'Kulutuspisteet',

  'wallet.ykcsxf': 'Käsittelymaksu on vähennetty',
  'wallet.sxf': 'Palvelumaksu',
  'wallet.zxje': 'Vähimmäismäärä',
  'wallet.24hxe': '24 tunnin raja',
  'wallet.desc.text': 'Virkamies ei missään olosuhteissa pyydä sinua siirtämään rahaa tilille, eikä pyydä sinulta vahvistuskoodia. Älä osallistu laittomaan toimintaan, kuten ostamiseen muiden puolesta, rahanpesuun ja laittomaan rahankeräykseen, ja varo verkkopetoksia',
  'wallet.cunbi.text': 'Voit tallettaa vain {name} tähän osoitteeseen, muuta omaisuutta ei noudeta',

  'credit.title': 'Luottoraja',
  'credit.ed': 'Kiintiö',
  'credit.hk': 'Takaisinmaksu',
  'credit.kyye': 'Saatavilla oleva saldo',
  'credit.dqqk': 'Nykyiset maksurästit',
  'credit.hkje': 'Takaisinmaksusumma',
  'credit.hkje.desc': 'Anna takaisinmaksun summa',
  'credit.cgje': 'Ostosumma',
  'credit.qrz': 'Siirry todentamiseen',
  'credit.srxyj': 'Anna luottomaksun summa',

  'store.gzl': 'Huomiomäärä',
  'store.wxz': 'Rajaton',

  'auth.smrz': 'Oikean nimen todennus',
  'auth.rzlx': 'Tyyppi',
  'auth.sfzm': 'henkilökortin etuosa',
  'auth.sffm': 'Henkilökortin kääntöpuoli',
  'auth.zsxm': 'oikea nimi',
  'auth.zjhm': 'Asiakirjan numero',
  'auth.yyzz': 'Yrityslupa',
  'auth.gsmc': 'Yrityksen nimi',
  'auth.shz': 'Tarkastus',
  'auth.ytg': 'Hyväksytty',
  'auth.ybh': 'hylätty',
  'auth.zt': 'status',
  'auth.gr': 'Henkilökohtainen',
  'auth.gs': 'Yritys',
  'auth.ljtj': 'Hae todennusta',
  'auth.wrz': 'Todistamaton',

  'credit.qts': 'Näytä kuvaus',
  'store.spss': 'Hyödykkeen yläraja',
  'store.dpfh': 'Kaupan osingot',
  'store.qydj': 'pääomataso',
  'store.level': 'taso',
  'store.jian': 'pieces',

  'order.notify.email': 'Sähköpostin riskienhallinnan vuoksi alustan tilausmuistutussähköposteja lähetetään usein ja ne siepataan. Jotta ne saadaan ajoissa, on suositeltavaa lisätä sisäänkirjautumiseen service@nextstore.etxts.com sähköpostin sallittujen lista',

  'setting.sjxx': 'Yritystiedot',
  'setting.smrz': 'Oikean nimen todennus',
  'setting.aqzx': 'Turvakeskus',
  'setting.tzxx': 'Ilmoitustiedot',
  'setting.dpzx': 'Koristeiden kotisivu',
  'setting.sjqy': 'Liiketoiminnan edut',
  'setting.sjdjqy': 'Kuvaus liiketoimintatason eduista',

  'setting.fhbky': 'Nykyinen osinko ei ole saatavilla',
  'setting.spgzyzy': 'Kaupan säännöt ja ohjeet',

  'desc.dqqy': 'nykyinen pääoma',
  'desc.xjqy': 'Alisteiset intressit',
  'desc.sjqy_0': 'Kaupan luottoraja',
  'desc.sjqy_1': 'Kauppaosingot',
  'desc.sjqy_2': 'Julkaistavien tuotteiden määrä',

  'share.yqlb': 'Kutsuluettelo',
  'share.zmj': 'Myyjä yhteensä',
  'share.zdd': 'Tilauksen kokonaismäärä',
  'share.zsy': 'välitystuotot yhteensä',
  'share.wcl': 'käsittelemätön',
  'share.ycl': 'Käsitelty',
  'share.ywc': 'Valmis',
  'share.dpmc': 'Kaupan nimi',
  'share.ddl': 'Tilausmäärä',
  'share.jjsy': 'Välitystulot',
  'share.zts': 'Kokonaismäärä',

  'chat.state': 'osavaltio',
  'chat.zx': 'Online',
  'chat.ljz': 'Yhdistetään',

  'bind.wallet.title': 'Nostoosoite',
  'bind.address.title': 'Lisää osoite',
  'bind.bj': 'Muokkaa',
  'bind.sc': 'Poista',
  'bind.qrsc': 'Haluatko varmasti poistaa tämän osoitteen?',
  'bind.qbdz': 'Lompakon osoite',
  'bind.tjsj': 'Lisää aika',
  'bind.cz': 'Toiminta',
  'withdrawal.address.choice': 'Valitse nostoosoite!',

  'order.sxsj': 'Tehollinen aika',
  'order.ljsj': 'voimassa välittömästi',
  'order.dssj': 'Ajastettu voimaan',

  'store.st.zt': 'Store Status',
  'store.st.zc': 'normaali',
  'store.st.zc.desc': 'Kauppasi on normaali, jatka samaan malliin',
  'store.st.yc': 'Poikkeus',
  'store.st.yc.desc': 'Liikkessäsi on epänormaalia tilausta tai asiakasvalituksia',
  'store.st.dj': 'Freeze',
  'store.st.dj.desc': 'Kauppaasi liittyy tietty toimintariski ja varat on jäädytetty',
  'store.st.jy': 'pois käytöstä',
  'store.st.jy.desc': 'Kauppasi on rikkonut alustaan ​​liittyviä sopimuksia ja sääntöjä, ja se on poistettu käytöstä',

  'task.sy': 'Kotisivu',
  'task.rwlbo': 'Tehtävälista',
  'task.rwxqo': 'Tehtävän tiedot',
  'task.ljcy': 'Osallistu nyt',
  'task.gdrw': 'Lisää tehtäviä',
  'task.rwlb': 'Lista tarttumistehtävistä',
  'task.qbrw': 'Kaikki tehtävät',
  'task.wcyd': 'Osallistuin',
  'task.qdz': 'Tilausten saaminen',
  'task.ywc': 'Valmis',
  'task.djy': 'vaihdettu',
  'task.ddje': 'Tilauksen määrä',
  'task.cyrs': 'Osallistujien määrä',
  'task.ewsy': 'Lisäedut',
  'task.jzsj': 'Deadline',
  'task.ckxq': 'Näytä tiedot',
  'task.rwxq': 'Tehtävän tiedot',
  'task.cylb': 'Osallistujaluettelo',
  'task.qdcyrw': 'Haluatko varmasti osallistua tähän tilaustehtävään?',
  'task.sd': 'Kyllä',
  'task.bue': 'ei',

  'task.wdsy': 'Tuotto',
  'task.wdfh': 'Osingot',
  'task.wdqd': 'Hanki tilaus',
  'task.dd.leixin': 'Tilauksen tyyppi',
  'task.dd.qdrw': 'Pikatilaustehtävä',
  'task.dd.ptdd': 'Tavallinen järjestys',

  'flow.lltg': 'Flow Promotion',
  'flow.gmll': 'Osta tietopaketti',
  'flow.dqtc': 'Nykyinen paketti',
  'flow.tc1': 'Paketti',
  'flow.sy2': 'remaining',
  'flow.tian3': 'day',
  'flow.jlzs': 'Tietueiden kokonaismäärä',
  'flow.lltc': 'Flow-paketti',
  'flow.ll': 'flow',
  'flow.gmsj': 'Ostoaika',
  'flow.tcjg': 'Paketin hinta',
  'flow.sjzf': 'todellinen maksu',
  'flow.tcsm': 'Paketin kuvaus',
  'flow.tcsm.sm': 'Tämän paketin ostaminen lisää myymälän näkyvyyttä ja lisää asiakasliikennettä. Saadaksesi lisää tilauksia ja kasvattaaksemme myymälän tuloja. ',

  'flow.tcmc': 'Paketin nimi',
  'flow.gmsc': 'Oston kesto',
  'flow.zje': 'Kokonaismäärä',
  'flow.jzsj': 'Deadline',
  'flow.gmrq': 'Ostopäivä',
  'flow.tian1': 'päivä',
  'flow.zhou1': 'viikko',
  'flow.yue1': 'kuukausi',

  'share.dj': 'taso',

  'share.hz': 'Siirto',
  'share.hzd': 'Siirrä',
  'share.qbye': 'lompakon saldo',
  'share.tgje': 'Tarjouksen summa',
  'share.hzje': 'Siirtosumma',
  'share.qsrhzje': 'Anna siirtosumma',

  '_inv._title': 'Kutsun kuvaus',
  '_inv._t1': '1. Kutsu- ja kutsusuhde',
  '_inv._desc1': 'A kutsuu B:n, B kutsuu C:n, C kutsuu D:n. Korkein taso on 4, jos D kutsuu E:n uudelleen, niin E:llä ei ole mitään tekemistä A:n kanssa. ',
  '_inv._t2': '2. Kutsuja saa palkinnon',
  '_inv._desc2': 'A saa 4 % tilauksen B kokonaismäärästä, A saa 2 % tilauksen C kokonaismäärästä ja A 1 % tilauksen D kokonaismäärästä',

  '_index._gm': 'Osta',
  '_index._sm': 'Kuvaus: Lisää myymälän näkyvyyttä ja houkuttele liikennettä',

  '_shop._update': '{n} kertaa kuukaudessa, muokattu {m} kertaa kuluvan kuukauden aikana',

  '_chat._hc': 'Poista',

  '_footer._suom': 'Käytännön kuvaus',

  '_footer._platform._qualification': 'Yrityksen pätevyys',
  '_store._ptzz': 'Seuraavan myymälän alustaan ​​liittyvät pätevyydet',

  '_abt._gywm': 'Tietoja meistä',
  '_abt._syms': 'Liiketoimintamalli',
  '_abt._qyzr': 'Yritysvastuu',
  '_abt._lxwm': 'Ota yhteyttä',

  '_wsj._wsjms': 'Juhli Halloweenia tyylikkäästi pelottavassa Halloween-myymälässämme. Etsitpä sitten temppua tai herkkua tänä pelottavana vuodenaikana, Halloween-asujen, koristeiden ja asusteiden valikoimastamme löytyy jotakin koko perheelle. Selkärankaa jäähdyttävä julkilausuma naisten ja miesten kokoelmillamme tai päästä lapsesi mielikuvitus valloilleen upeiden pukupukujen avulla. Löydä täydelliset asusteet vuoden jännittävimmän loman juhlimiseen unohtumattomista söpöistä kodintarvikkeista ja leluista glamouriin kauneuteen ja kosmetiikkaan.',

  '_wsj._b1': 'Syksyinen kodin sisustus',
  '_wsj._b2': 'Kurpitsa',
  '_wsj._b3': 'Koti',
  '_wsj._b4': 'Lapsille',
  '_wsj._b5': 'Vauvan',
  '_wsj._b6': 'Naisten',
  '_wsj._b7': 'Upea mekko',
  '_wsj._b8': 'Personoitu',

  '_wsj._cj': 'Osallistu',
  '_wsj._gz': 'Toimintasäännöt',
  '_wsj._flss': 'Luokkahaku',
  '_wsj._wsnz': 'Halloween naisten vaatteet',
  '_wsj._wsn': 'Halloween miesten vaatteet',
  '_wsj._wst': 'Halloween-lasten vaatteet',
  '_wsj._wszs': 'Halloween-koriste',
  '_wsj._wslp': 'Halloween-lahja',

  '_wsj._desc1': '"Halloween Special, Trick or Treat!" Tänä lokakuussa olemme valmistaneet sinulle karnevaalijuhlan! Tule nappaamaan kaikenlaisia ​​pelottavia ja järkyttäviä tuotteita, suuret alennukset odottavat sinua! ',
  '_wsj._lkm': 'Mene ostoksille',

  '_wsj._wsj': 'Halloween',

  '_wsj._wsjjz': 'Halloween on tulossa',
  '_wsj._mzbt': 'Kauneuden adventtikalenterit',
  '_wsj._mznr': 'Vuoden upeimpaan aikaan on juuri laskeutunut adventtikalentereita, jotka sopivat kaikille.',

  '_chr._tt1': 'Osta koko joulun',
  '_chr._tt2': 'joulukoristeet',
  '_chr._tt3': 'Osta kaikki lahjat',
  '_chr._tt4': 'Osta kaikki jouluyöasut',

  '_chr._dt1': 'Joulua edeltävä aika on yhtä maaginen kuin suuri päivä. Joten laita lahjasi järjestykseen, käytäväsi koristeeksi ja yhteensopivat pyjamasi valmiiksi joulun taikaa varten.',

  '_chr._dtt1': 'Jouluvaatteet',
  '_chr._dtt2': 'Matching Family Christmas',
  '_chr._dtt3': 'Adventtikalenterit',
  '_chr._dtt4': 'Joulu kotona',
  '_chr._dtt5': 'Mukava kauppa',
  '_chr._dtt6': 'Kalusteet ajoissa jouluksi',

  '_chr._tre1': 'Nousussa nyt',
  '_chr._tre2': 'Lisää kotiisi hieman juhlavaa tunnelmaa trendeillä, joita kaikki rakastavat.',
  '_chr._tre3': 'Piparkakut',
  '_chr._tre4': 'Jouluvanukas',
  '_chr._tre5': 'Joulu Gonks',

  '_chr._ttre1': 'Kortit ja käärepaperi',
  '_chr._ttre2': 'Lelut ja pelit',
  '_chr._ttre3': 'Ruoka- ja juomalahjat',
  '_chr._ttre4': 'Sukkatäyteaineet',
  '_chr._ttre5': 'Salainen joulupukki',
  '_chr._ttre6': 'Vauvan ensimmäinen joulu',
  '_chr._ttre7': 'Kauneuslahjat',
  '_chr._ttre8': 'Premium-lahjat',
  '_chr._ttre9': 'Personoidut lahjat',

  '_sdj._sdnz': 'Naisten vaatteet',
  '_sdj._sdn': 'Miesten vaatteet',
  '_sdj._sdetlw': 'lahja',
  '_sdj._sdzs': 'Sisustus',
  '_sdj._sdjj': 'lelu',

  '_sdj._title1': 'Joulu on tulossa',
  '_sdj._desc1': '"Joulu: antamisen ja kiitollisuuden aikaa." Tänä joulukuussa olemme valmistaneet sinulle valoisasti sisustetut ja lämpimät perhejuhlat! Tule nappaamaan erilaisia ​​valoisia ja lämpimiä tuotteita suurilla alennuksilla odottamassa sinua! ',

  '_ssd._lhpp': 'Joustava maksu',
  '_ssd._lzhq': 'Yleinen lähde',
  '_ssd._pwyx': 'arvioitu erinomaiseksi',
  '_ssd._qsth': 'Helppo palautus',
  '_ssd._jgppcn': 'Hintojen täsmääminen ja sitoutuminen',
  '_ssd._ztbt': 'Luo maailmanlaajuinen keskitetty ostoskeskus',
  '_ssd._yzspl': 'Mainosjakauman hankinta suoraan yli 8 000 itsenäiseltä tuotemerkiltä',
  '_ssd._zcgm': 'Rekisteröidy ostaaksesi',
  '_ssd._zccs': 'Rekisteröidy myyntiin',

  '_ssd._dlwz': 'Kirjaudu sisään ja rekisteröidy',

  '_ssd._qjrgwc': 'Lisää kaikki ostoskoriin',

  '_zddy._bdhb': 'Paikallinen valuutta',
  '_zddy._xzbdhb': 'Valitse paikallinen valuutta',
  '_zddy._zje': 'Kokonaismäärä',
  '_zddy._xzzffs': 'Valitse sinulle sopiva maksutapa',
  '_zddy._jmhb': 'Kryptovaluutta',
  '_sjsy._ddpl': 'Tilauslista',
  '_zddy._fhsy': 'Etusivu',
  '_yhqd._czbz': 'Ota yhteyttä omaan asiakaspalveluun vahvistaaksesi lataustiedot ennen lataamista.',
  '_yhqd._lxkf': 'Ota yhteyttä asiakaspalveluun',
  '_yhqd._qsrje': 'Anna lataussumma',
  '_yhqd._wrhyhtd': 'Ei pääsyä pankkiin',
  '_yhqd._yhzz': 'Pankkisiirto',

  '_yhbt._yhlx': 'Pankin tyyppi',
  '_txcd._qsrtxje': 'Syötä nostosumma',
  '_txcd._skr': 'Maksunsaaja',
  '_txcd._skr._ms': 'Anna kortinhaltijan nimi',
  '_txcd._skzh': 'Maksutilin numero',
  '_txcd._skzh._ms': 'Anna maksutilin numero',
  '_txcd._tjyhk': 'Lisää pankkikortti',
  '_txcd._txje': 'Kotiutussumma',
  '_txcd._wdyhdz': 'pankkiosoite',
  '_txcd._yhdm': 'koodi',
  '_txcd._yhdm._ms': 'Anna pankkikoodi',
  '_txcd._yhdz': 'osoite',
  '_txcd._yhdz._ms': 'Anna pankin osoite',
  '_txcd._yhlx': 'tyyppi',
  '_txcd._yhlx._ms': 'Anna pankkikortin tyyppi',
  '_txcd._yhly': 'reititys',
  '_txcd._yhly._ms': 'Anna pankin reititys',
  '_txcd._yhmc': 'pankki',
  '_txcd._yhmc._ms': 'Anna pankin nimi',
  '_txcd._yhtx': 'Pankki käteisen nosto',

  '_jys._gmjmhb': 'Osta kryptovaluutta',
  '_jys._gmzy': 'Voit rekisteröidä tilin ja ostaa kryptovaluuttoja menemällä seuraaviin pörsseihin ja nykyisen maasi käytäntöjen mukaisesti. Lataa sitten alustan tilille! '
}
