<template>
  <div class="header-dcycqf" style="font-size: 13px">
    <div class="header-adaptive-my-account header-4mw0p4" style="position: relative; display: flex; align-items: center; gap: 10px; z-index: 11">
      <!-- app下载 -->
      <div class="MuiBox-root header-h9r63z">
        <div :class="['header-1n6kbbr', 'menus']">
          <div class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover header-142fymm" role="link" style="cursor: pointer">
            <MobileOutlined :style="{ fontSize: '16px', marginRight: '3px', color: type == 2 ? '#fff' : '#333' }" />
            <span data-testid="header-myaccount-username" class="header-31trse" :style="[type == 2 ? 'color: #fff' : '']"> App </span>
          </div>
          <!-- 下拉菜单 -->
          <div class="mod-menu">
            <div>
              <div class="app-main">
                <div class="app-box">
                  <div class="app-download">
                    <img src="../../assets/app_android.png" alt="" />
                  </div>
                  <div class="app-download">
                    <img src="../../assets/app_ios.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 客服联系 -->
      <div class="MuiBox-root header-h9r63z">
        <div :class="['header-1n6kbbr', isLogin ? 'menus' : '']">
          <div class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover header-142fymm" role="link" style="cursor: pointer">
            <customer-service-outlined :style="{ fontSize: '16px', marginRight: '3px', color: type == 2 ? '#fff' : '#333' }" />
            <span data-testid="header-myaccount-username" class="header-31trse" :style="['white-space: nowrap', type == 2 ? 'color: #fff' : '']"> {{ $t('base.server') }} </span>
          </div>
          <!-- 下拉菜单 -->
          <div class="mod-menu">
            <!-- <div class="menu-suid"></div> -->
            <div>
              <div class="menu-item">
                <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'" :target="sp_info.whatsapp ? '_blank' : ''">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconWhatsApp />
                    WhatsApp
                  </div>
                </a>
              </div>
              <div class="menu-item">
                <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'" :target="sp_info.telegram ? '_blank' : ''">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconTelegram />
                    Telegram
                  </div>
                </a>
              </div>
              <div class="menu-item">
                <a v-if="sp_info.line" :href="sp_info.line" :target="sp_info.line ? '_blank' : ''">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconLine />
                    line
                  </div>
                </a>
                <div class="line-qrcode" v-else-if="sp_info.line_qrcode">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconLine />
                    Line
                    <div class="line-box">
                      <img :src="sp_info.line_qrcode + '?r=' + Math.random() * 1000" alt="line" />
                    </div>
                  </div>
                </div>
                <a v-else href="javascript:">
                  <div class="ng-item ng-mobile icon-ant" style="width: 100%">
                    <IconLine />
                    Line
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 语言 -->
      <div class="MuiBox-root header-h9r63z">
        <div :class="['header-1n6kbbr', 'menus']">
          <div class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover header-142fymm" role="link">
            <!-- <GlobalOutlined :style="{ fontSize: '16px', marginRight: '3px', color: type == 2 ? '#fff' : '#333' }" />
            <span data-testid="header-myaccount-username" class="header-31trse" :style="{ color: type == 2 ? '#fff' : '#000' }"> {{ language.text }} </span> -->

            <div class="langs-box">
              <div :class="'langs-img langs-' + language.lang"></div>
              <span class="tit">{{ language.text }}</span>
            </div>
          </div>
          <!-- 下拉菜单 -->
          <div class="mod-menu">
            <!-- <div class="menu-suid"></div> -->
            <div>
              <div class="menu-item" v-for="(vo, i) in list" :key="i" @click="onLang(vo)">
                <div class="langs-box">
                  <div :class="'langs-img langs-' + vo.key"></div>
                  <span class="lang-item" style="padding: 0">
                    {{ vo.title }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 登录状态 -->
      <div class="MuiBox-root header-h9r63z">
        <div :class="['header-1n6kbbr', isLogin ? 'menus' : '']">
          <a-badge :count="msgCenter.msg" v-if="isLogin">
            <a class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover header-142fymm" role="link" style="display: flex; align-items: center; justify-content: center">
              <img style="width: 30px; height: 30px; border-radius: 50%" :src="sp_info.avatar == '' ? '@/static/img/avatar.png' : sp_info.avatar" alt="" />
              <span data-testid="header-myaccount-username" class="header-31trse head-name1" :style="{ color: type == 2 ? '#fff' : '#000' }"> {{ sp_info.nickname }} </span>
            </a>
          </a-badge>
          <a v-else @click="onSignin()" class="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineHover header-142fymm" role="link">
            <img src="@/static/img2/next_my-account_desktop.svg" />
            <span data-testid="header-myaccount-username" class="header-31trse"> {{ $t('_ssd._dlwz') }} </span>
          </a>

          <!-- 下拉菜单 -->
          <div class="mod-menu">
            <div class="menu-suid" v-if="sp_info.type == 1">UID:{{ sp_info.uid }}</div>
            <div class="menu-suid" v-if="sp_info.type == 2">SID:{{ sp_info.sid }}</div>
            <div v-if="sp_info.type == 1">
              <router-link :to="{ name: 'overview' }">
                <div class="menu-item">
                  {{ $t('member.overview') }}
                </div>
              </router-link>
              <router-link :to="{ name: 'orderAll' }">
                <div class="menu-item">
                  {{ $t('member.orders') }}
                </div>
              </router-link>
              <router-link :to="{ name: 'payment' }">
                <div class="menu-item">
                  {{ $t('member.payment') }}
                </div>
              </router-link>
              <router-link :to="{ name: 'address' }">
                <div class="menu-item">{{ $t('member.address') }}</div>
              </router-link>
              <router-link :to="{ name: 'wishlist' }">
                <div class="menu-item">{{ $t('member.wishlist') }}</div>
              </router-link>
              <router-link :to="{ name: 'follow' }">
                <div class="menu-item">{{ $t('member.followlist') }}</div>
              </router-link>
              <router-link :to="{ name: 'message' }">
                <div class="menu-item">
                  {{ $t('member.message.center') }}
                  <span style="color: #ffb500">({{ msgCenter.msg }})</span>
                </div>
              </router-link>
              <router-link :to="{ name: 'setting' }">
                <div class="menu-item">{{ $t('member.setting') }}</div>
              </router-link>
              <div class="menu-item" @click="onSignout()">{{ $t('signup.sign.out') }}</div>
            </div>
            <!-- 商家 -->
            <div v-if="sp_info.type == 2">
              <router-link :to="{ name: 'storeDashboard' }">
                <div class="menu-item">
                  {{ $t('store.dashboard') }}
                </div>
              </router-link>
              <router-link :to="{ name: 'storeProductsList' }">
                <div class="menu-item">
                  {{ $t('store.products.list') }}
                </div>
              </router-link>
              <router-link :to="{ name: 'storeOrderList' }">
                <div class="menu-item">
                  {{ $t('store.orders') }}
                  <span style="color: #ffb500">({{ msgCenter.order }})</span>
                </div>
              </router-link>
              <router-link :to="{ name: 'storeWallet' }">
                <div class="menu-item">{{ $t('store.wallet') }}</div>
              </router-link>
              <router-link :to="{ name: 'storeAdvertiseList' }">
                <div class="menu-item">{{ $t('advertise.title') }}</div>
              </router-link>

              <router-link :to="{ name: 'storeMessage' }">
                <div class="menu-item">
                  {{ $t('store.message') }}
                  <span style="color: #ffb500">({{ msgCenter.msg }})</span>
                </div>
              </router-link>
              <router-link :to="{ name: 'storeShare' }">
                <div class="menu-item">{{ $t('setting.invitation') }}</div>
              </router-link>
              <router-link :to="{ name: 'storeStore' }">
                <div class="menu-item">{{ $t('store.setting') }}</div>
              </router-link>
              <div class="menu-item" @click="onSignout()">{{ $t('signup.sign.out') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="shoppingbag header-1nxssqr" v-if="sp_info.type == 1">
        <div class="MuiBox-root header-wo12yk">
          <router-link :to="{ name: 'shopcart' }" class="header-19xm0lj" style="fill: #fff; position: relative">
            <template v-if="type == 1">
              <div class="header-1lfi7uv">{{ msgCenter.cart }}</div>
              <img src="@/static/img2/shopping-bag-large.svg" aria-hidden="true" />
            </template>
            <template v-else>
              <div class="header-1lfi7uv" style="position: absolute; top: -5px; right: 7px; color: #fff">{{ msgCenter.cart }}</div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M21.58 6.998a1 1 0 0 0-1-1h-2.82a6.15 6.15 0 0 0-11.52 0H3.41a1 1 0 0 0-1 1L2 20.928a1 1 0 0 0 1 1.07h18a1.002 1.002 0 0 0 1-1.07l-.42-13.93Zm-9.58-4a5.18 5.18 0 0 1 4.67 3H7.33a5.18 5.18 0 0 1 4.67-3Zm-9 18 .41-14h17.18l.41 14H3Z" />
              </svg>
            </template>
          </router-link>
        </div>
      </div>
    </div>

    <!-- <div class="header-adaptive-checkout MuiBox-root header-7kjtwp" data-testid="header-adaptive-checkout">
                <a class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium Mui-disabled MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium header-1sb9m85">Checkout</a>
              </div> -->
    <div class="header-1j297y5"></div>

    <!-- 注册 -->
    <Signup ref="signup" v-show="isShowSignin" @onCloseSignin="onCloseSignin" @tokenStatus="tokenStatus"></Signup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCurrentInstance } from 'vue'
import { setLangauge } from '@/utils/vue-i18n'
import { CustomerServiceOutlined, MobileOutlined } from '@ant-design/icons-vue'
import Signup from '@/components/common/Signup.vue'
export default {
  name: 'indexVue',
  components: {
    CustomerServiceOutlined,
    // GlobalOutlined,
    MobileOutlined,
    Signup
  },
  setup() {
    const { proxy } = getCurrentInstance()
    function change(lang) {
      proxy.$i18n.locale = lang
      setLangauge(lang)
      console.log('切换语言', lang)
    }
    return { change }
  },
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      categoryList: [],
      childList: [],
      childTop: [],
      currentMenu: -1,

      isShowSignin: false,

      //消息
      // msgCenter: {
      //   msg: 0,
      //   order: 0,
      //   cart: 0
      // },

      //语言
      language: {
        text: 'English',
        lang: 'en-us'
      },
      list: [
        {
          title: 'English',
          key: 'en-us'
        },
        {
          title: '한국어',
          key: 'ko-kr'
        },
        {
          title: '日本語',
          key: 'ja-jp'
        },
        {
          title: 'Deutsch',
          key: 'de-de'
        },
        {
          title: 'عربي',
          key: 'ar-sa'
        },
        {
          title: 'Русский',
          key: 'be-by'
        },
        {
          title: 'Română',
          key: 'ro-ro'
        },
        {
          title: 'Français',
          key: 'fr-fr'
        },
        {
          title: 'Türkçe',
          key: 'tr-tr'
        },
        {
          title: 'Italiano',
          key: 'it-it'
        },
        {
          title: 'Suomalainen',
          key: 'fi-fi'
        },
        {
          title: 'España',
          key: 'es-es'
        },
        {
          title: 'Danmark',
          key: 'dk-da'
        },
        {
          title: 'Nederland',
          key: 'nl-nl'
        },
        {
          title: 'Tiếng Việt',
          key: 'vi-vn'
        },
        {
          title: 'Português',
          key: 'pt-pt'
        },
        {
          title: 'Bahasa Indonesia',
          key: 'id-id'
        },
        {
          title: '繁體中文',
          key: 'zh-hant'
        }
      ],
      //搜索
      searchVal: '',
      searchShop: [],
      searching: false,

      sp_info: {},
      isLogin: false
    }
  },
  created() {
    this.tokenStatus()

    //获取基本信息
    this.$store.dispatch('base/getBase', {})
    //获取基本
    // this.$api.home.getBase().then((res) => {
    //   this.msgCenter = res.data
    // })

    //获取基本
    this.$api.home.getService().then((res) => {
      this.$common.setService(res.data)
    })

    //获取基本
    this.$api.home.getCartNum().then((res) => {
      this.msgCenter.cart = res.data.cart
    })

    let config = localStorage.getItem('config')
    try {
      if (config) {
        this.language = JSON.parse(config)
      }
    } catch (error) {
      //console.log(error);
    }

    // this.$on("onSigninEvent")
  },
  computed: {
    ...mapState({
      msgCenter: (state) => state.base.msgCenter
    })
  },
  methods: {
    /**
     * 去登陆
     */
    onSignin() {
      this.$refs.signup.onInit(2)
      this.isShowSignin = true
    },
    /**
     * 去注册
     */
    onSignup() {
      this.$refs.signup.onInit(1)
      this.isShowSignin = true
    },
    /**
     * 关闭
     */
    onCloseSignin() {
      this.isShowSignin = false
    },
    /**
     * 登录成功
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.sp_info = this.$common.getInfo()
      this.$emit('tokenStatus', [])
    },
    /**
     * 退出登录
     */
    onSignout() {
      localStorage.removeItem('sp_token')
      localStorage.removeItem('sp_info')
      this.isLogin = false
      // this.$socket.close()
      // this.$emit('onSignout', [])
    },
    onLang(vo) {
      console.log(vo)
      this.language = {
        text: vo.title,
        lang: vo.key
      }
      this.change(vo.key)
      localStorage.setItem('config', JSON.stringify(this.language))
      this.$router.go(0)
    }
  }
}
</script>

<style></style>
<style>
.menus:hover .mod-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.mod-menu {
  transform: translateY(10px);
  position: absolute;
  background: #fff;
  top: 27px;
  left: 0;
  padding: 5px 5px;
  border-radius: 7px;
  box-shadow: 3px 5px 10px 0px #b5b5b5;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease, visibility 0.15s ease, transform 0.15s ease;
}

.menus {
  position: relative;
}

.menu-suid {
  padding: 5px 10px;
  font-weight: 700;
}

.menu-item {
  padding: 5px 15px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}

.menu-item:hover {
  background-color: #d3ebeb96;
  color: #13abab;
}

.mod-menu a {
  color: #000 !important;
  display: flex;
  justify-content: flex-start !important;
}

.search-box {
  position: absolute;
  width: 100%;
  background: #fff;
  top: 36px;
  padding: 0px 0;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #9b9b9b;
  max-height: 400px;
  overflow-y: scroll;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease, visibility 0.15s ease, transform 0.15s ease;
}

.search-box.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.search-li {
  padding: 5px 20px;
  cursor: pointer;
}

.search-li:hover {
  background-color: #13abab3d;
}

.app-download {
  width: 140px;
  height: 140px;
}

.app-download img {
  width: 100%;
}
.app-box {
  display: flex;
}

.head-name1 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<style src="@/static/css2/headnav.css"></style>
<style src="@/static/css2/Gel3-0.min.css?v=2023.830.500.5"></style>
<style src="@/static/css2/Alibaba-font.min.css"></style>
