import request from '@/utils/request'

const api = {
  getTopGoods: 'home/getTopGoods',
  searchShop: 'home/searchShop',
  getBanner: 'home/getBanner',
  getActivity: 'home/getActivity',
  getGoodsList: 'home/getGoodsList',
  getBase: 'home/getBase',
  getCartNum: 'home/getCartNum',
  getTopStore: 'home/getTopStore',
  getService: 'home/getService'
}
export default {
  getTopGoods(parameter) {
    return request({
      url: api.getTopGoods,
      method: 'post',
      data: parameter
    })
  },
  getTopStore(parameter) {
    return request({
      url: api.getTopStore,
      method: 'post',
      data: parameter
    })
  },
  searchShop(parameter) {
    return request({
      url: api.searchShop,
      method: 'post',
      data: parameter
    })
  },
  getBanner(parameter) {
    return request({
      url: api.getBanner,
      method: 'post',
      data: parameter
    })
  },
  getActivity(parameter) {
    return request({
      url: api.getActivity,
      method: 'post',
      data: parameter
    })
  },
  getGoodsList(parameter) {
    return request({
      url: api.getGoodsList,
      method: 'post',
      data: parameter
    })
  },
  getBase(parameter) {
    return request({
      url: api.getBase,
      method: 'post',
      data: parameter
    })
  },
  getService(parameter) {
    return request({
      url: api.getService,
      method: 'post',
      data: parameter
    })
  },
  getCartNum(parameter) {
    return request({
      url: api.getCartNum,
      method: 'post',
      data: parameter
    })
  }
}
