<template>
  <div class="nav-header">
    <span class="nv-title">{{ $t('store.orders') }}</span>
  </div>
  <div :style="{ background: '#fff', padding: '20px 20px', minHeight: '800px' }">
    <div class="nav-content">
      <div>
        <a-space :size="20">
          <div style="display: flex">
            <div style="display: flex; gap: 20px 20px; flex-wrap: wrap">
              <div class="com-box">
                <span class="com-title">{{ $t('dashboard.sales.total') }}</span
                >:<span class="com-val">$ {{ commission.total }}</span>
              </div>
              <div class="com-box">
                <span class="com-title">{{ $t('dashboard.sales.real') }}</span
                >:<span class="com-val">$ {{ commission.real_total }}</span>
              </div>
              <div class="com-box">
                <span class="com-title">{{ $t('dashboard.sales.pay') }}</span
                >:<span class="com-val">$ {{ commission.purchase }}</span>
              </div>
              <div class="com-box">
                <span class="com-title">{{ $t('dashboard.sales.profit') }}</span
                >:
                <a-tooltip placement="topLeft">
                  <template #title>
                    <div style="">
                      <div>{{ $t('task.wdsy') }}: $ {{ commission.profit }}</div>
                      <div>{{ $t('task.wdfh') }}: $ {{ commission.dividends }}</div>
                    </div>
                  </template>
                  <span class="com-val" style="cursor: pointer">$ {{ commission.total_profit }}</span>
                </a-tooltip>
              </div>
            </div>
          </div>
        </a-space>
      </div>
      <div class="box-search">
        <a-space :size="20">
          <div>
            <a-select size="large" v-model:value="queryParam.status" style="width: 250px" :options="statusList" :field-names="{ label: 'name', value: 'id' }" :placeholder="$t('store.order.status')"></a-select>
          </div>
          <div>
            <a-input-search v-model:value="queryParam.search" style="width: 300px" :placeholder="$t('base.search.text')" :enter-button="$t('base.search')" size="large" @search="onSearch" />
          </div>
        </a-space>
      </div>
    </div>
    <div class="tb-box1" style="overflow-y: auto">
      <a-table :columns="columns" :data-source="dataSource" :pagination="pagination" :loading="loading" @change="getStoreOrders" :scroll="{ x: 1500 }">
        <template #bodyCell="{ column, record }">
          <!-- <template v-if="record && column.title === 'Name'">
          <div style="display:flex;align-items: center;">
            <img :src="record.img" width="40" style="margin-right:5px" />
            <div style="font-size:13px">{{record.name}}</div>
          </div>
        </template> -->
          <template v-if="record && column.key === 'task_id'">
            <a-tag color="blue" v-show="record.task_id > 0">{{ $t('task.dd.qdrw') }}</a-tag>
            <a-tag color="" v-show="record.task_id <= 0">{{ $t('task.dd.ptdd') }}</a-tag>
          </template>
          <template v-if="record && column.key === 'order_sn'">
            <a-tooltip placement="topLeft" :title="record.order_sn">
              <div style="width: 220px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                {{ record.order_sn }}
              </div>
            </a-tooltip>
          </template>

          <template v-if="record && column.key === 'total'">
            <div>${{ record.total }}</div>
          </template>
          <template v-if="record && column.key === 'purchase'">
            <div>${{ record.purchase }}</div>
          </template>
          <template v-if="record && column.key === 'profit'">
            <a-tooltip>
              <template #title>
                <div style="">
                  <div>{{ $t('task.wdsy') }}: $ {{ record.profit }} ({{ record.profit_rate }}%)</div>
                  <div>{{ $t('task.wdfh') }}: $ {{ record.dividends }} ({{ record.dividends_rate }}%)</div>
                  <div>{{ $t('task.wdqd') }}: $ {{ record.task_profit }} ({{ record.task_rate }}%)</div>
                </div>
              </template>
              <a-tag color="green">$ {{ (parseFloat(record.profit) + parseFloat(record.dividends) + parseFloat(record.task_profit)).toFixed(2) }}</a-tag>
            </a-tooltip>
          </template>
          <template v-if="record && column.key === 'is_pay'">
            <div>
              <a-tag :color="payFilter(record.status).color">{{ payFilter(record.status).text }}</a-tag>
            </div>
          </template>
          <template v-if="record && column.key === 'status'">
            <div>
              <a-tag :color="purchaseFilter(record.status).color">{{ purchaseFilter(record.status).text }}</a-tag>
            </div>
          </template>
          <template v-if="record && column.key === 'status_text'">
            <div>
              <a-tag :color="statusFilter(record.status).color">{{ statusFilter(record.status).text }}</a-tag>
            </div>
          </template>
          <template v-if="record && column.key === 'purchase_time'">
            <a-tooltip placement="topLeft" :title="record.purchase_time">
              <div style="width: 80px; white-space: nowrap; overflow: hidden">
                {{ record.purchase_time }}
              </div>
            </a-tooltip>
          </template>
          <template v-if="record && column.key === 'create_time'">
            <a-tooltip placement="topLeft" :title="record.create_time">
              <div style="width: 80px; white-space: nowrap; overflow: hidden">
                {{ record.create_time }}
              </div>
            </a-tooltip>
          </template>
          <template v-else-if="column.key === 'action'">
            <div style="display: flex">
              <div>
                <router-link :to="{ name: 'storeOrderDetail', query: { order_sn: record.order_sn } }">{{ $t('member.order.detail') }}</router-link>
                <!-- <a-divider type="vertical" /> -->
              </div>
              <div v-if="record.status == 3" style="margin-left: 10px">
                <!-- <a-popconfirm :title="$t('store.order.purchase.desc')" :ok-text="$t('store.order.purchase.yes')" :cancel-text="$t('store.order.purchase.no')" @confirm="onPurchase(record)"> </a-popconfirm> -->
                <a href="javascript:" @click="onPurchaseMd(record)" class="cg-btn">{{ $t('store.order.purchase') }}</a>
                <!-- <a-divider type="vertical" /> -->
              </div>
            </div>
          </template>
        </template>
      </a-table>
    </div>

    <!-- msg -->
    <a-modal v-model:visible="visible" :title="mdTitle" @ok="onPurchase" :okText="$t('store.order.purchase.yes')" :cancelText="$t('store.order.purchase.no')">
      <p style="font-size: 18px; font-weight: 600; margin-bottom: 10px">{{ $t('store.order.purchase.desc') }}</p>
      <p>{{ $t('store.order.desc') }}</p>
      <div class="ct-box">
        <div class="ct-title">{{ $t('credit.cgje') }}:</div>
        <div class="ct-desc">US ${{ currentRecord.purchase }}</div>
      </div>
      <div class="ct-box" hidden>
        <div class="ct-title">{{ $t('credit.title') }}:</div>
        <div class="ct-input">
          <a-input v-model:value="creditNum" type="number" step="0.01" :placeholder="$t('credit.srxyj')" />
        </div>
        <div class="ct-desc">{{ $t('credit.ed') }}:US ${{ credit.balance }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import { computed } from "@vue/runtime-core";
export default {
  name: 'orderVue',
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '60px',
          fixed: 'left'
        },
        {
          title: this.$t('task.dd.leixin'),
          key: 'task_id',
          dataIndex: 'task_id',
          width: '60px'
        },
        {
          title: this.$t('store.order.no'),
          key: 'order_sn',
          dataIndex: 'order_sn',
          width: '220px'
        },
        {
          title: this.$t('store.order.number'),
          key: 'num',
          dataIndex: 'num',
          width: '60px'
        },

        {
          title: this.$t('store.order.total'),
          key: 'total',
          dataIndex: 'total',
          width: '60px'
        },
        {
          title: this.$t('store.order.purchase.amount'),
          key: 'purchase',
          dataIndex: 'purchase',
          width: '90px'
        },
        // {
        //   title: this.$t('store.order.will.earning'),
        //   dataIndex: "total",
        //   width: "5%",
        // },
        {
          title: this.$t('store.order.profit'),
          key: 'profit',
          dataIndex: 'profit',
          width: '60px'
        },
        // {
        //   title: this.$t('store.order.dividends'),
        //   key: 'dividends',
        //   dataIndex: 'dividends',
        //   width: '60px'
        // },
        // {
        //   title: this.$t('store.order.payment.status'),
        //   key: 'is_pay',
        //   dataIndex: 'status',
        //   width: '90px'
        // },
        // {
        //   title: this.$t('store.order.seller.buy.status'),
        //   key: 'status',
        //   dataIndex: 'status',
        //   width: '90px'
        // },

        {
          title: this.$t('store.order.purchase.date'),
          key: 'purchase_time',
          dataIndex: 'purchase_time',
          width: '90px'
        },
        {
          title: this.$t('store.order.date'),
          key: 'create_time',
          dataIndex: 'create_time',
          width: '90px'
        },
        {
          title: this.$t('store.order.status'),
          key: 'status_text',
          dataIndex: 'status',
          width: '100px'
        },
        {
          title: this.$t('store.order.buyer'),
          key: 'u_contact',
          dataIndex: 'u_contact',
          width: '90px'
        },
        {
          title: this.$t('store.order.action'),
          key: 'action',
          width: '110px',
          fixed: 'right'
        }
      ],
      //状态
      statusList: [
        {
          id: 0,
          name: this.$t('member.order.all')
        },
        {
          id: 1,
          name: this.$t('member.order.unpaid')
        },
        {
          id: 3,
          name: this.$t('member.order.procurement')
        },
        {
          id: 4,
          name: this.$t('member.order.processing')
        },
        {
          id: 5,
          name: this.$t('member.order.shipped')
        },
        {
          id: 2,
          name: this.$t('member.order.completed')
        },
        {
          id: '-1',
          name: this.$t('member.order.refund')
        }
      ],
      /**
       * 订单状态
       */
      orderStatus: {
        '-1': {
          text: this.$t('member.order.refund'),
          color: 'red'
        },
        1: {
          text: this.$t('member.order.unpaid'),
          color: 'red'
        },
        2: {
          text: this.$t('member.order.completed'),
          color: 'green'
        },
        3: {
          text: this.$t('member.order.procurement'),
          color: 'orange'
        },
        4: {
          text: this.$t('member.order.processing'),
          color: 'cyan'
        },
        5: {
          text: this.$t('member.order.shipped'),
          color: 'blue'
        },
        6: {
          text: this.$t('member.order.return'),
          color: 'red'
        }
      },

      loading: false,
      pagination: { total: 0, current: 1, pageSize: 20 },
      dataSource: [],
      queryParam: {
        search: ''
      },
      total_profit: 0,

      //确认框
      visible: false,
      mdTitle: '',
      currentRecord: {},

      //佣金
      commission: {
        total: 0,
        unsettled: 0,
        pending: 0,
        settled: 0
      },

      //采购时支付信用
      creditNum: 0,
      wallet: {},
      credit: {}
    }
  },
  created() {
    this.getSales()

    //从缓存读取当前分页
    const page = localStorage.getItem('store_o_p')
    if (page) {
      this.pagination.current = page
    }
    this.getStoreOrders(this.pagination)

    this.getWallet()
  },
  methods: {
    getSales() {
      this.$api.store.getSales().then((res) => {
        this.commission = res.data
      })
    },
    // 获取钱包
    getWallet() {
      let that = this
      this.$api.store.getShopWallet().then((res) => {
        that.wallet = res.data.wallet
        that.credit = res.data.credit
      })
    },
    onPurchaseMd(record) {
      this.currentRecord = record
      this.visible = true
      this.mdTitle = ''
    },
    /**
     * 支付状态
     * @param {*} status
     */
    payFilter(status) {
      if (status === 1) {
        return {
          text: this.$t('member.order.unpaid'),
          color: 'red'
        }
      }
      return {
        text: this.$t('member.order.paid'),
        color: 'green'
      }
    },
    /**
     * 订单状态
     * @param {*} status
     */
    statusFilter(status) {
      if (!this.orderStatus[status]) {
        return { text: '', color: '' }
      }
      return this.orderStatus[status]
    },
    /**
     * 采购状态
     * @param {*} status
     */
    purchaseFilter(status) {
      if (status == 1 || status == 3) {
        return {
          color: 'orange',
          text: this.$t('member.order.procurement')
        }
      }
      return {
        color: 'green',
        text: this.$t('member.order.seller.paid')
      }
    },
    onPurchase() {
      let that = this
      this.$message.loading({ content: 'Purchase order...', key: 'msg' })
      this.$api.store.purchase({ id: this.currentRecord.id, credit_num: this.creditNum }).then((res) => {
        that.$message.success({
          content: res.msg,
          key: 'msg'
        })
        that.visible = false
        that.getStoreOrders(that.pagination)
      })
    },
    onSearch() {
      this.getStoreOrders(this.pagination)

      this.getSales()
    },
    getStoreOrders(parameter) {
      const pagination = {
        page: parameter.current,
        size: parameter.pageSize
      }
      const requestParameters = Object.assign({}, pagination, this.queryParam)

      this.loading = true

      //缓存当前分页
      localStorage.setItem('store_o_p', pagination.page)

      let that = this
      this.$api.store.getStoreOrders(requestParameters).then((res) => {
        that.loading = false
        that.pagination = {
          total: res.data.total,
          current: res.data.pageNo,
          pageSize: res.data.pageSize
        }
        that.dataSource = res.data.data
        that.total_profit = res.data.total_profit
      })
    }
  }
}
</script>

<style scoped src="@/static/css/store-css.css"></style>
<style scoped>
.com-box {
  border-right: 1px dashed #ccc;
  padding-right: 18px;
  height: 15px;
  display: flex;
  align-items: center;
}
.com-title {
  font-size: 16px;
  font-weight: 700;
}
.com-val {
  margin-left: 5px;
  color: #4caf50;
  font-size: 20px;
}
.box-search {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.ct-box {
  display: flex;
  margin-top: 20px;
}
.ct-title {
  display: flex;
  padding-right: 10px;
  align-items: center;
}
.ct-input {
  display: flex;
  padding-right: 10px;
  align-items: center;
}
.ct-desc {
  display: flex;
  padding-right: 10px;
  align-items: center;
}

.tb-box >>> .ant-table table {
  min-width: 1200px;
}

.cg-btn {
  color: #ff5722;
  font-weight: 700;
  border: 1px dashed #ff5722;
  padding: 2px 5px;
  border-radius: 5px;
}

.cg-btn:hover {
  color: #ff0057;
  border: 2px dashed #ff0057;
}
</style>
