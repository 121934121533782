// {"type":"import.kline","data":{"symbol":"XAU/USD","time":"m1"}}
// {"type":"import.kline","data":{"symbol":"Copper","time":"W1"}}
// {"method":"sendMsg","data":{"id":4,"session_id":12,"content":"111111111111111111111111"}}
class Socket {
  constructor(ip = '', port = '') {
    const protocol = location.protocol === 'https:' ? 'wss://' : 'ws://'
    this.url = protocol + ip + ':' + port
    this.url = 'wss://atlnes.com/ws'
    console.log(process.env.NODE_ENV)
    if (process.env.NODE_ENV === 'development') {
      this.url = 'ws://127.0.0.1:2310/wsg'
    }
    this.methods = {}
    this.socket = {}
    this.reconnect_time = '2000'
    this.connect_inter = null
    // this.open()
    return this
  }
  on(method, fn) {
    this.methods[method] = fn
  }
  open(isReconnect) {
    var that = this
    /** ---------------------------------- */
    // console.log(that.url,'socket 请求连接');
    /** ---------------------------------- */
    that.socket = new WebSocket(that.url)

    that.socket.onopen = function () {
      clearInterval(that.connect_inter)
      /** ---------------------------------- */
      console.log(that.url, 'socket 连接成功!')
      /** ---------------------------------- */

      that.login()

      // 请求心跳
      that.sendPing()

      //    that.hasConnected()
      if (that.methods['onReady']) {
        that.methods['onReady']()
      }

      if (isReconnect) {
        if (that.methods['onReconnect']) {
          that.methods['onReconnect']()
        }
      }

      // 打开连接，请求登录
      //const token = localStorage.getItem('sp_token')
      // if (token) {
      //   setTimeout(() => {
      //     const msg = {
      //       method: 'login',
      //       data: {
      //         type: 'store',
      //         token: token
      //       }
      //     }
      //     socket.send(msg)
      //   }, 500)
      // }
    }
    that.socket.onerror = function (msg) {
      console.log(msg, '连接失败')
      if (that.methods['onError']) {
        that.methods['onError'](msg)
      }
      that.reconnect()
    }
    that.socket.onmessage = function (msg) {
      const data = JSON.parse(msg.data)
      /** ---------------------------------- */
      // console.log(data,'socket 接收数据');
      if (data.type === 'ping') {
        that.socket.send('{"pong":' + Date.parse(new Date()) + '}')
      } else {
        /** ---------------------------------- */
        if (data.method === 'login') {
          console.log('login', data.data)
        }

        if (data.type == 'func' && that.methods[data.method]) {
          that.methods[data.method](data.data)
        }
      }
    }
  }
  login() {
    //如果已经的登陆就立即登录
    const token = localStorage.getItem('sp_token')
    if (token) {
      this.send({ type: 'login', data: { token: token } })
    }
  }
  logout() {
    this.send({ type: 'logout' })
  }
  connected(fn) {
    if (this.socket.readyState === 1) {
      // 成功
      fn(this)
      this.sendPing()
    } else {
      setTimeout(() => {
        console.log('等待连接')
        this.connected(fn)
      }, 1000)
    }
  }
  send(infos) {
    if (infos.constructor === Array) {
      /** ---------------------------------- */
      // console.log(infos, infos.constructor, 'socket 发送Array数据!');
      /** ---------------------------------- */
      for (var i in infos) {
        this.socket.send(
          JSON.stringify({
            type: infos[i].type,
            params: infos[i].params
          })
        )
      }
    } else if (infos.constructor === Object) {
      this.socket.send(JSON.stringify(infos))
    } else if (infos.constructor === String) {
      /** ---------------------------------- */
      // console.log(infos, infos.constructor, 'socket 发送String数据!');
      /** ---------------------------------- */
      this.socket.send(infos)
    } else {
      /** ---------------------------------- */
      // console.log(infos,infos.constructor,'socket 发送数据!');
      /** ---------------------------------- */
      this.socket.send(JSON.stringify(infos))
    }
  }
  close() {
    // var that = this;
    clearInterval(this.connect_inter)
    this.send({ method: 'close', data: {} })
    delete this
    // this.socket.onclose = function () {
    //   this.close()
    //   console.log('关闭连接')
    //   // that.reconnect();
    // }
  }
  reconnect() {
    clearInterval(this.connect_inter)
    this.connect_inter = setInterval(() => {
      this.open(true)
    }, this.reconnect_time)
  }
  /** 获取当前socket状态 */
  // 0：表示连接尚未建立，CONNECTING 状态。
  // 1：表示连接已建立，可以进行通信，OPEN 状态。
  // 2：表示连接正在进行关闭握手，CLOSING 状态。
  // 3：表示连接已经关闭或者根本没有建立，CLOSED 状态。
  getReadyState() {
    return this.socket.readyState
  }
  /** 发送心跳 */
  sendPing() {
    if (this.connect_inter) {
      clearInterval(this.connect_inter)
    }
    this.send('{"ping":' + Date.parse(new Date()) + '}')
    this.connect_inter = setInterval(() => {
      if (this.socket.readyState !== 1) {
        this.reconnect()
      } else {
        this.send('{"ping":' + Date.parse(new Date()) + '}')
      }
    }, 5000)
  }
}
// var socket=new WebSocket('ws://newnb.m88mo.cn/ws')

const socket = new Socket()
export default socket
