<template>
  <!-- 提示步骤 -->
  <div :style="{ padding: '0 20px' }" v-if="step == STEP.ShowTips">
    <div class="hb-sdfqe">
      <div class="hb-sdijfi">USD {{ ShowParams.show_usd }}</div>
      <div class="hb-tit1asd">{{ $t('_zddy._zje') }}</div>
    </div>
    <a-form :model="params" name="basic" :wrapper-col="{ span: 24 }" autocomplete="off">
      <a-form-item :label="$t('_jys._gmjmhb')" name="username" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
        <div style="padding: 5px 0; line-height: 23px; color: #999">
          <div>{{ $t('_jys._gmzy') }}</div>
        </div>
      </a-form-item>
      <a-form-item>
        <div class="sdsfq" style="height: 180px; overflow: scroll">
          <a :href="item.href" v-for="(item, i) in jys()" :key="i" class="tupian" target="__blank">
            <div>
              <img :src="item.src" alt="" />
            </div>
          </a>
        </div>
      </a-form-item>

      <!-- 按钮 -->
      <div class="ant-row ant-form-item" style="margin-bottom: 0">
        <div class="ant-col ant-col-24 ant-form-item-label">
          <div for="basic_amount" class="ant-form-item-required" style="display: flex; align-items: center; justify-content: center; gap: 10px">
            <a-button type="dashed" key="back" @click="onFatherStep()">{{ $t('bank.ok.prev') }}</a-button>
            <a-button type="primary" @click="onNext(STEP.SelectCurrency)">{{ $t('bank.ok.text') }}</a-button>
          </div>
        </div>
      </div>
    </a-form>
  </div>

  <!-- 加密货币 -->
  <div :style="{ padding: '0 20px' }" v-if="step == STEP.SelectCurrency">
    <div class="hb-sdfqe">
      <div class="hb-sdijfi">USD {{ ShowParams.show_usd }}</div>
      <div class="hb-tit1asd">{{ $t('_zddy._zje') }}</div>
    </div>
    <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item :label="$t('recharge.currency')">
        <a-select ref="select" v-model:value="selectIndex" style="width: 100%" @change="onCurrencyChange" placeholder="选择加密货币">
          <a-select-option :value="i" v-for="(item, i) in currencyList" :key="i">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('recharge.protocol')">
        <div style="display: flex">
          <div v-for="(item, i) in protocolList" :key="i" @click="onProtocolClick(i)">
            <div :class="['xy_span', current.id == item.id ? 'active' : '']">
              {{ item.cp_name }}
            </div>
          </div>
        </div>
      </a-form-item>
      <a-form-item :label="$t('recharge.qrcode')">
        <div style="display: flex">
          <div class="token_css" style="width: 70%">
            <img :src="current.token && current.token.qrcode" alt="" />
          </div>
        </div>
      </a-form-item>
      <a-form-item :label="$t('recharge.address')">
        <div style="display: flex">
          <div class="token_css">
            {{ current.token && current.token.receive_token }}
          </div>
          <div style="margin-left: 10px">
            <a-button type="primary" @click="onCopy(current.token.receive_token)">
              {{ $t('recharge.copy') }}
            </a-button>
          </div>
        </div>
      </a-form-item>
      <!-- 备注 -->
      <a-form-item :label="$t('withdrawal.notice')" name="username" :label-col="{ span: 4 }" :wrapper-col="{ span: 17 }">
        <div style="padding: 5px 0; line-height: 23px; color: #999">
          <div>{{ $t('wallet.cunbi.text', { name: current.network }) }}</div>
        </div>
      </a-form-item>
    </a-form>
  </div>

  <div :style="{ padding: '0 20px' }" v-if="step == STEP.UplaodAuth">
    <a-form :model="params" name="basic" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item :label="$t('recharge.currency')">
        {{ current.name + '_' + current.cp_name }}
      </a-form-item>
      <!-- amount -->
      <a-form-item :label="$t('bank.amount')" name="number" :rules="[{ required: true, message: 'Please input your amount!' }]">
        <a-input :suffix="current.name" v-model:value="params.number" type="number" :step="0.00000001" :placeholder="$t('bank.amount.desc')" />
      </a-form-item>
      <!-- imgs -->
      <a-form-item :label="$t('bank.credential.picture')" :required="true">
        <!-- <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action="/web/v1/upload/file" :before-upload="beforeUpload" @change="onUploadChange" capture="false" accept="image/*">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" width="126" />
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">{{ $t('bank.upload') }}</div>
            </div>
          </a-upload> -->
        <upload-file @onUpload="onUpload"></upload-file>
        <!-- <div class="upload-box" @click="onOpenFile">
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" width="126" />
            <div v-else class="upload-file">
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">{{ $t('bank.upload') }}</div>
            </div>
          </div>
          <input ref="files" type="file" accept="image/*" @change="onFile" hidden /> -->
      </a-form-item>
    </a-form>
  </div>

  <!-- 按钮 -->
  <div class="ant-row ant-form-item" style="margin-bottom: 0">
    <div class="ant-col ant-col-24 ant-form-item-label">
      <div for="basic_amount" class="ant-form-item-required" style="display: flex; align-items: center; justify-content: center; gap: 10px">
        <a-button key="back" @click="onPrev()" v-if="step == STEP.SelectCurrency">{{ $t('bank.ok.prev') }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from '@/components/UploadFile.vue'
import jysJson from '@/utils/jys.json'
const STEP = {
  HideOptions: 0,
  ShowTips: 1,
  SelectCurrency: 2,
  UplaodAuth: 3
}

export default {
  name: 'RechargeVue',
  components: {
    UploadFile
  },
  props: {
    ShowParams: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['onStep'],
  data() {
    return {
      //步骤
      STEP,
      step: STEP.HideOptions,

      //代理类型
      wallet: 1, //1 平台钱包 2冷钱包

      //存币
      selectIndex: 0,
      currencyList: [],
      protocolList: [],
      current: {},
      store: {},
      currency_id: 0,

      // 提交信息
      params: {
        number: '',
        img: ''
      },

      // 上传图片
      loading: false,
      imageUrl: '',
      img: '',

      jys: () => {
        let img = []
        let names = jysJson
        for (var i in jysJson) {
          img.push({
            name: names[i].name,
            href: names[i].href,
            src: require('../../static/jys/' + names[i].name + '.png')
          })
        }
        return img
      }
    }
  },
  created() {
    this.show()
  },
  methods: {
    onFatherStep() {
      this.$emit('onStep', 2)
    },
    refresh() {
      let that = this
      this.$api.currency.getRechargeList().then((res) => {
        that.currencyList = res.data.list
        that.wallet = res.data.wallet
        //默认第一个
        that.onCurrencyChange(0)
      })
    },
    onUpload(img) {
      this.imageUrl = img
      this.img = img
    },
    /**
     * 存币
     */
    show() {
      this.refresh()

      this.step = STEP.ShowTips

      this.imageUrl = ''
      this.img = ''
      this.params = {
        number: '',
        img: ''
      }
    },
    hide() {
      this.step = STEP.HideOptions
    },
    onNext() {
      this.step = STEP.SelectCurrency
    },
    onPrev() {
      this.step = STEP.ShowTips
    },
    // 币种改变
    onCurrencyChange(i) {
      this.protocolList = this.currencyList[i].protocol
      this.onProtocolClick(0)
    },
    // 点击协议
    onProtocolClick(i) {
      this.current = this.protocolList[i]
      console.log(this.current)
    },
    /**
     * 复制地址
     */
    onCopy(token) {
      console.log(token)
      // copyData想要复制的内容
      this.$copyText(token)
        .then(() => {
          this.$message.success('copy success! ' + token)
        })
        .catch(() => {
          this.$message.error('copy fail')
        })
    },

    /**
     * 上传封面图
     * @param {*} img
     * @param {*} callback
     */
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    },
    onUploadChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        var response = info.file.response
        if (response.code === 1) {
          this.loading = false
          this.$message.error(response.msg)
          return
        }

        this.img = response.data.url
        this.loading = false

        // Get this url from response in real world.
        this.getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/bmp'
      if (!isJpgOrPng) {
        this.$message.error('can only upload jpg, png, bmp pictures')
      }
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('Images must be less than 20MB!')
      }
      return isJpgOrPng && isLt2M
    },
    valid() {
      if (this.params.amount === '') {
        this.$message.error('amount cannot be empty')
        return false
      }

      if (this.params.img === '') {
        this.$message.error('credential image cannot be empty')
        return false
      }

      return true
    },
    onSubmit() {
      this.loadingBtn = true

      this.params.cp_id = this.current.id
      this.params.img = this.img

      if (!this.valid()) {
        this.loadingBtn = false
        return
      }

      //提交到后台
      const that = this
      this.$api.store
        .transferWallet(this.params)
        .then((res) => {
          that.loadingBtn = false
          if (res.code == 0) {
            that.$message.success(res.msg)
            setTimeout(function () {
              that.hide()
              that.$emit('BankCardSuccess')
            }, 500)
          }
        })
        .catch((err) => {
          err
          that.loadingBtn = false
        })
    }
  }
}
</script>

<style>
.hb-sert {
  display: flex;
  align-items: center;
  gap: 10px;
}
.hb-91dfs {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
}
.hb-tit {
  font-weight: 700;
}
.hb-sdfqe {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}
.hb-sdijfi {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 700;
}
.hb-tit1asd {
  font-size: 16px;
  font-weight: 700;
  color: #27f;
}

.hb-zzdsf {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.hb-zz1 {
  padding: 5px 20px;
  border: 1px solid #22c2ff1f;
  border-radius: 10px;
  cursor: pointer;
  background-color: #22c2ff0d;
}

.hb-zz1:hover {
  border: 1px solid #27f;
}

.hb0zdfs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 15px;
  font-weight: 700;
}

.safag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sdsfq {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tupian {
  width: calc(100% / 4 - 10px);
  height: 55px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  overflow: hidden;
}
</style>
