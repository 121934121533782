<template>
  <div>
    <!-- 顶部导航 -->
    <HeadNav></HeadNav>
    <!-- 搜索烂 -->
    <!-- <MemberSearch></MemberSearch> -->
    <!-- 导航 -->
    <div id="account-page" style="margin-top: 121px">
      <div id="nav-box">
        <div id="nav-crumb"></div>
        <div id="page-menu">
          <div class="page-menu-item page-menu-title">
            <div class="item-text">{{ $t('member.account') }}</div>
          </div>
          <div :class="['page-menu-item', checked == MENU.overview ? 'page-menu-item-active' : '']" @click="onMenu(MENU.overview)">
            <div class="item-text">{{ $t('member.overview') }}</div>
          </div>
          <div :class="['page-menu-item', checked == MENU.order ? 'page-menu-item-active' : '']" @click="onMenu('orderAll')">
            <a-badge :dot="msgCenter.order > 0">
              <div class="item-text">{{ $t('member.orders') }}</div>
            </a-badge>
          </div>
          <div class="has-underline"></div>
          <div :class="['page-menu-item', checked == MENU.payment ? 'page-menu-item-active' : '']" @click="onMenu(MENU.payment)">
            <div class="item-text">{{ $t('member.payment') }}</div>
          </div>
          <div :class="['page-menu-item', checked == MENU.address ? 'page-menu-item-active' : '']" @click="onMenu(MENU.address)">
            <div class="item-text">{{ $t('member.address') }}</div>
          </div>
          <div :class="['page-menu-item', checked == MENU.wishlist ? 'page-menu-item-active' : '']" @click="onMenu(MENU.wishlist)">
            <div class="item-text">{{ $t('member.wishlist') }}</div>
          </div>
          <div :class="['page-menu-item', checked == MENU.follow ? 'page-menu-item-active' : '']" @click="onMenu(MENU.follow)">
            <div class="item-text">{{ $t('member.followlist') }}</div>
          </div>
          <div :class="['page-menu-item', checked == MENU.message ? 'page-menu-item-active' : '']" @click="onMenu(MENU.message)">
            <a-badge :count="msgCenter.msg">
              <div class="item-text">{{ $t('member.message.center') }}</div>
            </a-badge>
          </div>
          <div :class="['page-menu-item', checked == MENU.setting ? 'page-menu-item-active' : '']" @click="onMenu(MENU.setting)">
            <div class="item-text">{{ $t('member.setting') }}</div>
          </div>
        </div>
      </div>
      <div id="page-content">
        <!-- 右边导航 -->
        <router-view></router-view>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer.vue'
import HeadNav from '@/components/common/HeadNav.vue'
// import MemberSearch from '@/components/member/MemberSearch.vue'
const MENU = {
  overview: 'overview',
  order: 'order',
  payment: 'payment',
  address: 'address',
  setting: 'setting',
  wishlist: 'wishlist',
  follow: 'follow',
  message: 'message'
}
export default {
  name: 'layoutVue',
  components: {
    HeadNav,
    // MemberSearch,
    Footer
  },
  data() {
    return {
      MENU,
      checked: MENU.overview,

      //循环
      ajaxMsg: null,
      ajaxTime: 2000
      // msgCenter: {
      //   msg: 0,
      //   order: 0
      // }
    }
  },
  watch: {
    $route(newRoute) {
      //   console.log(newRoute);
      this.setCurrent(newRoute)
    }
  },
  created() {
    this.setCurrent(this.$route)

    // 定时获取消息通知
    // this.onSocket()

    this.$store.dispatch('base/getBase', {})
  },
  computed: {
    ...mapState({
      msgCenter: (state) => state.base.msgCenter
    })
  },
  mounted() {
    // 定时获取消息通知
    // this.runAjaxMsg()
  },
  beforeUnmount() {
    // clearInterval(this.ajaxMsg)
  },
  methods: {
    /**
     * 异步循环获取消息
     */
    // runAjaxMsg() {
    //   //let that = this
    //   this.$api.home.getBase().then((res) => {
    //     this.msgCenter = res.data
    //   })
    //   this.ajaxMsg = setTimeout(this.runAjaxMsg, this.ajaxTime)
    // },
    // onSocket() {
    //   //连接
    //   const that = this
    //   this.$socket.on('base', function (data) {
    //     that.msgCenter = data
    //   })
    // },

    onMenu(val) {
      this.$router.push({ name: val })
    },
    setCurrent(route) {
      console.log(route)
      this.checked = route.meta.name
    }
  }
}
</script>
<style src="@/static/css/member-index.css"></style>
