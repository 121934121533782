<template>
  <!-- 主体内容 -->
  <div id="root" class="page-container">
    <!-- 第一板块 -->
    <div id="home-firstscreen" class="home-firstscreen global-sale-firstscreen" :style="'padding-bottom: 10px;-height: ' + (screenHeight - 121) + 'px;'">
      <div class="abcd123">
        <div class="bs13234">
          <div class="sdf21233">
            <div class="ad23">
              <div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="SavingsOutlinedIcon"><path d="M15 10c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zM8 9h5V7H8v2zm14-1.5v6.97l-2.82.94L17.5 21H12v-2h-2v2H4.5S2 12.54 2 9.5 4.46 4 7.5 4h5c.91-1.21 2.36-2 4-2 .83 0 1.5.67 1.5 1.5 0 .21-.04.4-.12.58-.14.34-.26.73-.32 1.15l2.27 2.27H22zm-2 2h-1L15.5 6c0-.65.09-1.29.26-1.91-.97.25-1.76.97-2.09 1.91H7.5C5.57 6 4 7.57 4 9.5c0 1.88 1.22 6.65 2.01 9.5H8v-2h6v2h2.01l1.55-5.15 2.44-.82V9.5z"></path></svg>
              </div>
              <div>{{ $t('_ssd._lhpp') }}</div>
            </div>
          </div>
          <div class="sdf21233">
            <div class="ad23">
              <div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="LocalShippingOutlinedIcon"><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5 1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>
              </div>
              <div>{{ $t('_ssd._lzhq') }}</div>
            </div>
          </div>
          <div class="sdf21233">
            <div class="ad23">
              <SolutionOutlined :style="{ fontSize: '20px', height: '29px' }" />
              <div>{{ $t('_ssd._pwyx') }}</div>
            </div>
          </div>
          <div class="sdf21233">
            <div class="ad23">
              <div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="HistoryOutlinedIcon"><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"></path></svg>
              </div>
              <div>{{ $t('_ssd._qsth') }}</div>
            </div>
          </div>
          <div class="sdf21233">
            <div class="ad23">
              <div>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="PaymentsOutlinedIcon"><path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"></path></svg>
              </div>
              <div>{{ $t('_ssd._jgppcn') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="kbjbg">
        <div class="kbj11">{{ $t('_ssd._ztbt') }}</div>
        <div class="kbj22">{{ $t('_ssd._yzspl') }}</div>
        <div class="bkx333">
          <div class="bkbtn32" @click="onSigninEvent()">{{ $t('_ssd._zcgm') }}</div>
          <router-link :to="{ name: 'beseller' }">
            <div class="bkbtn32 bb">{{ $t('_ssd._zccs') }}</div>
          </router-link>
        </div>
      </div>

      <div>
        <!--gop region start regionId biz/site_server_info version 20210723.192537.165-->
        <div class="site-server-box" style="display: flex">
          <div class="site-item">
            <i class="icon i-great">&nbsp;</i>
            <h3>{{ $t('footer.great.value') }}</h3>
            <p>{{ $t('footer.great.value.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-delivery">&nbsp;</i>
            <h3>{{ $t('footer.shopping') }}</h3>
            <p>{{ $t('footer.shopping.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-payment">&nbsp;</i>
            <h3>{{ $t('footer.safe.payment') }}</h3>
            <p>{{ $t('footer.safe.payment.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-confidence">&nbsp;</i>
            <h3>{{ $t('footer.shop.with.confidence') }}</h3>
            <p>{{ $t('footer.shop.with.confidence.desc') }}</p>
          </div>
          <div class="site-item">
            <i class="icon i-help">&nbsp;</i>
            <h3>{{ $t('footer.help.center') }}</h3>
            <p>{{ $t('footer.help.center.desc') }}</p>
          </div>
        </div>
      </div>
      <div class="cbanner" style="margin-bottom: 10px">
        <div class="home-firstscreen-main">
          <div class="advertise-main">
            <div class="banner-main">
              <div class="exn6E box" data-spm="01003">
                <div class="slick-slider slick-initialized" style="height: 100%">
                  <button type="button" class="slick-arrow slick-prev sl-prev">Previous</button>
                  <div class="slick-list" style="height: 100%">
                    <div class="slick-track" style="height: 100%">
                      <swiper
                        :modules="modules"
                        :slidesPerView="6"
                        :spaceBetween="30"
                        :pagination="{
                          el: '.banner-slick-dots',
                          type: 'custom',
                          renderCustom: onRenderCustom
                        }"
                        :navigation="{
                          nextEl: '.sl-next',
                          prevEl: '.sl-prev'
                        }"
                        :loop="false"
                        :autoplay="{
                          delay: 10000,
                          disableOnInteraction: false
                        }"
                        style="height: auto"
                      >
                        <swiper-slide v-for="(item, i) in banner" :key="i">
                          <div class="slick-slide slick-active slick-current" style="outline: none; width: 100%">
                            <div style="position: relative; height: 250px; padding-bottom: 100%">
                              <div style="position: absolute; width: 100%">
                                <a :href="item.url" class="_3RrZV" style="width: 100%; height: 100%; display: inline-block; overflow: hidden" v-if="item.type == 2">
                                  <img :src="item.banner" style="width: 100%; height: 100%" />
                                </a>
                                <div v-else>
                                  <img :src="item.banner" style="width: 100%; height: 100%" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                  <button type="button" data-role="none" class="slick-arrow slick-next sl-next">Next</button>
                  <ul class="banner-slick-dots">
                    <li :class="[current == i && 'slick-active']" v-for="i in total" :key="i">
                      <button>{{ i }}</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 美妆 -->
      <div class="container" id="divStorefrontContentSection" style="padding: 0 10px">
        <div id="multi-2-teaser13" class="multi-2-teaser-module" style="">
          <div class="multi-teaser shop-news-edits-two">
            <div class="multi-teaser-outer-container teaser- isSwipeForNarrow">
              <div class="multi-teaser-inner-container" style="gap: 5px; height: 220px">
                <div class="shp-box" :style="'background-image:url(' + require('@/static/christmas/christmas.png') + ');background-size: 100% 100%;'">
                  <h3>{{ $t('_sdj._title1') }}</h3>
                  <div class="shp-text">{{ $t('_sdj._desc1') }}</div>
                  <router-link :to="{ name: 'christmas' }" target="_self">
                    <div class="shp-btn">{{ $t('_wsj._lkm') }}</div>
                  </router-link>
                </div>
                <div class="shp-box" :style="'background-image:url(' + require('@/static/img2/halloween.jpg') + ');background-size: 100% 100%;'">
                  <h3>{{ $t('_wsj._wsjjz') }}</h3>
                  <div class="shp-text">{{ $t('_wsj._desc1') }}</div>
                  <router-link :to="{ name: 'halloween' }" target="_self">
                    <div class="shp-btn">{{ $t('_wsj._lkm') }}</div>
                  </router-link>
                </div>
                <div class="shp-box">
                  <h3>{{ $t('_wsj._mzbt') }}</h3>
                  <div class="shp-text">{{ $t('_wsj._mznr') }}</div>
                  <router-link :to="{ name: 'category', query: { id: 462, level: 3 } }" target="_self">
                    <div class="shp-btn">{{ $t('_wsj._lkm') }}</div>
                  </router-link>
                </div>

                <!-- <div class="shp-box" style="padding: 0">
                  <div class="video-wrapper" style="display: flex; align-items: center; justify-content: center; overflow: hidden; border-radius: 10px">
                    <router-link :to="{ name: 'category', query: { id: 462, level: 3 } }" target="_self">
                      <video autoplay="" loop="" muted="" playsinline="" name="media" class="" id="html5_video_iyxyec1r6t" style="width: 150%">
                        <source src="@/static/mp4/14.09.23-Christmas_9x3-608KB.mp4" type="video/mp4" />
                      </video>
                    </router-link>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- store top -->
    <div v-if="topStore.length > 0">
      <div class="container scm2">
        <button type="button" class="slick-arrow slick-prev st-prev">Previous</button>
        <swiper
          :modules="modules1"
          :slidesPerView="8"
          :spaceBetween="10"
          :navigation="{
            nextEl: '.st-next',
            prevEl: '.st-prev'
          }"
          :loop="true"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false
          }"
        >
          <swiper-slide v-for="(item, i) in topStore" :key="i">
            <router-link :to="{ name: 'storeHome', query: { id: item.id } }" style="position: relative">
              <div class="sbox2">
                <div class="sbimg">
                  <div class="sbimg2">
                    <img :src="item.avatar" alt="" />
                  </div>
                </div>
                <div class="snbox">
                  <div class="sb_name">{{ item.name }}</div>
                </div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
        <button type="button" class="slick-arrow slick-next st-next">Next</button>
      </div>
    </div>

    <!-- 第二板块 -->
    <div class="MexE9 global-sale-module-3002766" v-if="activity">
      <div class="sale-space s-banner" style="/*background-color: #F9384F;*/">
        <div class="_3e0Fq container">
          <a href="javascript:">
            <img :src="activity.banner" />
          </a>
        </div>
      </div>
    </div>

    <!-- puls -->
    <div class="container">
      <div class="avyII container-shadow">
        <div class="_1w0PQ" style="height: 36px">
          <a class="_3TosV" href="javascript:">
            <img src="../../static/img/plus.png" height="40" />
            <p class="_3uM_-"></p>
          </a>
          <div class="_1BT44">
            <a href="javascript:">
              <!-- <span class="_2t-OM">{{ $t('base.view.more') }}</span> -->
              <span class="_1JCiP"></span>
            </a>
          </div>
        </div>
        <div class="_2SmRz box">
          <button type="button" class="slick-arrow slick-prev s3-prev">Previous</button>
          <swiper
            :modules="modules"
            :slidesPerView="6"
            :slidesPerGroup="1"
            :spaceBetween="0"
            :loopFillGroupWithBlank="true"
            :navigation="{
              nextEl: '.s3-next',
              prevEl: '.s3-prev'
            }"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false
            }"
            :loop="false"
          >
            <swiper-slide v-for="(item, i) in plusSale" :key="i">
              <div class="_1W9c2" ae_object_value="0" ae_button_type="item" v-if="item.goods">
                <router-link :to="{ name: 'detail', query: { id: item.sg_id } }" style="position: relative">
                  <div class="XfQo5 _3g9vq">
                    <img :src="item.goods && item.goods.img" />
                  </div>
                  <div class="_2XICq" style="height: 184px"></div>
                  <div class="_1TZSu">
                    <span class="_1fMek">US ${{ item.goods.price }}</span
                    ><span class="_3cWm1">US ${{ item.goods.cost_price }}</span>
                  </div>
                </router-link>
              </div>
            </swiper-slide>
          </swiper>
          <button type="button" class="slick-arrow slick-next s3-next">Next</button>
        </div>
      </div>
    </div>

    <!-- super deals -->
    <div class="container">
      <div class="_2AYyQ container-shadow">
        <div class="_2uFWT fd-wrap">
          <a class="_1oRj_" href="javascript:">
            <div class="_1oRj_">
              <div class="_2oAw_">
                <img src="../../static/img/super_deals.png" class="MupMl" height="34" width="167" />
                <span class="_1WXA2">{{ $t('text.super.deals') }}</span>
                <!-- <div class="countDown">
                    <div class="timer">
                      <span class="dice" data-role="hour">03</span>
                      <span class="split">:</span>
                      <span class="dice" data-role="minute">59</span>
                      <span class="split">:</span>
                      <span class="dice" data-role="second">11</span>
                    </div>
                  </div> -->
              </div>
              <!-- <span class="_2t-OM">{{ $t('base.view.more') }}</span> -->
              <span class="_1JCiP"></span>
            </div>
          </a>
        </div>
        <!-- 列表 -->
        <div class="box URNoY nNUBO">
          <button type="button" class="slick-arrow slick-prev s2-prev">Previous</button>
          <ul class="_2vR2K _24Zfm">
            <swiper
              :modules="modules"
              :slidesPerView="6"
              :slidesPerGroup="1"
              :spaceBetween="0"
              :loopFillGroupWithBlank="true"
              :navigation="{
                nextEl: '.s2-next',
                prevEl: '.s2-prev'
              }"
              :autoplay="{
                delay: 3000,
                disableOnInteraction: false
              }"
              :loop="false"
            >
              <swiper-slide v-for="(item, i) in superDeals" :key="i">
                <li class="EtktB" v-if="item.goods">
                  <router-link :to="{ name: 'detail', query: { id: item.sg_id } }">
                    <div class="_14-u9 _3g9vq">
                      <img :src="item.goods.img" :alt="item.goods.name" />
                    </div>
                    <section class="_3sDqp">
                      <div class="_3kmwU">
                        <div class="_10Ovz">$</div>
                        <i class="">{{ parseInt(item.goods.price) }}</i>
                        <p class="_3OExO">
                          <span class="_2hrdP"></span>
                          <span class="_1_95M">.{{ item.goods && $common.cutPrice(item.goods.price) }}</span>
                        </p>
                      </div>
                      <div class="_21mrB">
                        <div class="_3dVh7">US ${{ item.goods.cost_price }}</div>
                      </div>
                    </section>
                    <p class="_1PlT6">{{ item.orders }} {{ $t('detail.orders') }}</p>
                    <span class="hUozl">-{{ item.goods && parseInt((item.goods.price / item.goods.cost_price) * 100) }}<span class="g9-jS">%</span></span>
                  </router-link>
                </li>
              </swiper-slide>
            </swiper>
          </ul>
          <button type="button" class="slick-arrow slick-next s2-next">Next</button>
        </div>
      </div>
    </div>

    <!-- morelove -->
    <div class="container" :infinite-scroll-immediate-check="false" :infinite-scroll-disabled="scrollDisabled" infinite-scroll-watch-disabled="scrollDisabled" v-infinite-scroll="loadMore" infinite-scroll-distance="5">
      <div class="_2jK6B">
        <h2 class="_15__V">
          <div class="_192Xe"></div>
          <div class="_3dDSw">More To Love</div>
          <div class="_192Xe"></div>
        </h2>
        <div class="_1nker WCeVm">
          <router-link :to="{ name: 'detail', query: { id: item.id } }" v-for="(item, i) in goodsData" :key="i" class="_3t7zg _2S8eq TQXr3 container-shadow" target="" style="margin: 0; padding: 0; width: calc(100% / 6 - 10px); text-align: left">
            <div class="_3A0hz" style="padding-bottom: 100%">
              <img class="_1RtJV product-img" :src="item.img" :alt="item.name" />
            </div>
            <div class="_3GR-w">
              <div class="_1tu1Z">
                <!-- <img class="_1mroo" src="../static/img/superdeals_icon.png" height="15" width="52"> -->
                <!-- <img class="_1mroo" src="../static/img/sale_icon.png" height="15" width="29.0625"/> -->
                <h1 class="_18_85">{{ item.name }}</h1>
              </div>
              <!-- 销售爆涨 -->
              <!-- <div class="_1oOmj">
                  <img class="_1mroo" src="//ae01.alicdn.com/kf/H08a2b4d322c5466498dc53c63e90133cM.png" height="15" width="145">
                </div> -->
              <div class="mGXnE">
                <span style="font-size: 12px">US $</span>
                <span style="font-size: 20px">{{ parseInt(item.price) }}</span>
                <span style="font-size: 12px">.</span>
                <span style="font-size: 12px">{{ $common.cutPrice(item.price) }}</span>
              </div>
              <div class="_11_8K"></div>
              <div class="g_XRl" style="margin-top: 5px">
                <span style="color: #2cc3c3">{{ item.orders }} {{ $t('detail.orders') }}</span>
              </div>
              <div class="ZzMrp">
                <!-- <span class="_1kNf9">{{ item.sales_num }} sold</span> -->
                <i class="next-icon next-icon-favorites-filling next-medium"></i>
                <span class="eXPaM">{{ item.star }}</span>
              </div>
              <div class="KnIS-">
                <!-- <div><span><img class="_1mroo" src="//ae01.alicdn.com/kf/H628a9b47337942bdbbddf04fbbb3813cB.png" height="15" width="15"><span class="_1aWWA">5-day Delivery</span></span></div> -->
                <div><span class="_2jcMA">Free Shipping</span></div>
                <!-- <div><span class="_2jcMA">Free Return</span></div> -->
              </div>
            </div>
          </router-link>
        </div>
        <!-- 加载更多 -->
        <div v-show="loadmore">
          <img class="_2O2Fm" src="@/static/img/loading.gif" />
        </div>

        <div class="_3sm8Z">
          <span class="_3xk_u" @click="onMore">{{ $t('base.view.more') }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- 弹出框 -->
  <div class="_3kBox">
    <div :class="['_3KrBP', taskShow ? '_3kShow' : '']">
      <div class="_1-aaU" data-spm-anchor-id="a2g0o.home.0.i0.650c21459Bf0Gu">
        <div>
          <div class="_1u9ll">{{ currentTask.name }}</div>
          <div class="_3hbpp" v-html="currentTask.content"></div>
        </div>
        <div class="_1ZwH_">
          <router-link class="_1-SOk" :to="{ name: 'task_content', query: { id: currentTask.id } }">{{ $t('task.ljcy') }}</router-link>
          <router-link class="Sk1_X _1-SOk" :to="{ name: 'task' }">{{ $t('task.gdrw') }}</router-link>
        </div>
      </div>
      <img class="_24EHh" src="../../static/close.png" alt="" @click="onCloseTask()" />
    </div>
  </div>

  <!-- 回到顶部 -->
  <go-top v-if="isGoTop"></go-top>
</template>
<script>
import GoTop from '@/components/GoTop.vue'
import { Navigation, Pagination, Autoplay } from 'swiper'
import { defineComponent } from 'vue'
import { SolutionOutlined } from '@ant-design/icons-vue'
// import { Button } from 'ant-design-vue'
export default defineComponent({
  name: 'IndexVue',
  components: {
    GoTop,
    SolutionOutlined
  },
  emits: ['onSignup', 'onSignin', 'signinEvent'],
  data() {
    return {
      modules: [Navigation, Pagination, Autoplay],
      modules1: [Navigation, Autoplay],
      total: 0,
      current: 0,

      //屏幕高度
      screenHeight: document.documentElement.clientHeight,

      //是否显示置顶
      isGoTop: false,

      //轮播图
      banner: [],

      //推荐商品
      recommend: {},
      //建议商品
      suggest: {},
      //活动板块
      activity: {},
      //活动板块
      superDeals: {},
      plusSale: {},

      //消息
      ajaxMsg: null,
      ajaxTime: 2000,
      msgCenter: {
        msg: 0,
        order: 0
      },

      //是否登录
      isLogin: false,
      user: {},

      //加载更多
      scrollDisabled: false,
      goodsData: [],
      page: 1,
      loadmore: false,

      //店铺置顶
      topStore: [],

      //当前任务
      taskShow: false,
      currentTask: {
        name: '',
        content: ''
      }
    }
  },
  created() {
    if (this.$route.query.token) {
      let token = this.$route.query.token
      localStorage.setItem('sp_token', token)
      this.$api.auth.autoLogin({ token: token }).then((res) => {
        //存入缓存
        let info = res.data
        delete info.token
        localStorage.setItem('sp_info', JSON.stringify(info))
      })
    }
    // this.onSocket()
    this.getActivity()

    this.orderTask()
  },
  mounted() {
    // 注册监听页面高度
    let that = this
    window.addEventListener('resize', () => {
      that.screenHeight = document.documentElement.clientHeight
      // that.screenWidth = document.documentElement.clientWidth
    })
    window.addEventListener('scroll', () => {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 800) {
        this.isGoTop = true
      } else {
        this.isGoTop = false
      }
      // that.screenWidth = document.documentElement.clientWidth
    })
    // this.runAjaxMsg()
  },
  beforeUnmount() {
    // 注册监听页面高度
    window.removeEventListener('resize', this.getHeight)
    // clearInterval(this.ajaxMsg)
  },
  methods: {
    onSigninEvent() {
      this.$emit('signinEvent')
    },
    onCloseTask() {
      this.taskShow = false
    },
    orderTask() {
      this.isLogin = this.$common.isLogin()
      //获取后台任务
      let that = this
      if (this.isLogin) {
        this.$api.orderTask.getOrderTaskList().then((res) => {
          setTimeout(function () {
            that.taskTast(res.data, 0)
          }, 2000)
        })
      }
    },
    taskTast(data, i) {
      if (i >= data.length) {
        i = 0
        return
      }
      //随机时间弹出
      let that = this
      that.currentTask = data[i]
      that.taskShow = true
      setTimeout(function () {
        that.taskShow = false
        that.currentTask = {}
      }, 550000)

      setTimeout(function () {
        //递归
        that.taskTast(data, i + 1)
      }, 6000)
    },
    /**
     * 异步循环获取消息
     */
    // async runAjaxMsg() {
    //   if (this.isLogin) {
    //     await this.$api.home.getBase().then((res) => {
    //       this.msgCenter = res.data
    //     })
    //   }
    //   this.ajaxMsg = setTimeout(this.runAjaxMsg, this.ajaxTime)
    // },
    getActivity() {
      //获取轮播图
      let that = this
      this.$api.home.getBanner().then((res) => {
        that.banner = res.data
      })

      //获取推荐
      // this.$api.home.getTopGoods({ type: 1 }).then((res) => {
      //   this.recommend = res.data
      // })

      //获取super
      this.$api.home.getTopGoods({ type: 2 }).then((res) => {
        this.superDeals = res.data
      })

      //获取plus
      this.$api.home.getTopGoods({ type: 3 }).then((res) => {
        this.plusSale = res.data
      })

      //获取活动
      this.$api.home
        .getActivity({
          key: ['suggest', 'home_act']
        })
        .then((res) => {
          this.suggest = res.data['suggest']
          this.activity = res.data['home_act']
        })

      //获取置顶店铺
      this.$api.home.getTopStore().then((res) => {
        that.topStore = res.data
      })
    },
    // onSocket() {
    //   //连接
    //   const that = this
    //   this.$socket.on('base', function (data) {
    //     that.msgCenter = data
    //   })
    // },

    onMore() {
      this.scrollDisabled = false
      this.loadMore(0)
    },
    // 触底加载
    loadMore(page = 1) {
      if (this.loadmore) {
        return
      }

      if (page > 0 && this.page > page) {
        this.scrollDisabled = true
        return
      }
      this.loadmore = true
      this.getGoods()
    },
    getGoods(size = 24) {
      let that = this
      this.$api.home.getGoodsList({ page: this.page, size: size }).then((res) => {
        that.page++
        res.data.forEach(function (item) {
          that.goodsData.push(item)
        })
        that.loadmore = false
      })
    },
    onRenderCustom(swiper, current, total) {
      this.total = total
      this.current = current
    },
    /**
     * 登录状态
     * 由topnav 内部调用父类触发
     */
    tokenStatus() {
      this.isLogin = this.$common.isLogin()
      this.user = this.$common.getInfo()

      this.ajaxTime = 2000
      // 定时获取消息通知
      //this.runAjaxMsg()
    },
    /**
     * 退出登录
     */
    onSignout() {
      this.isLogin = this.$common.isLogin()
      this.user = this.$common.getInfo()
      this.ajaxTime = 0

      console.log(this.isLogin, this.user, 'logout')
    },
    /**
     * 注册
     */
    onSignup() {
      this.$emit('onSignup')
    },
    /**
     * 登录
     */
    onSignin() {
      this.$emit('onSignin')
    }
  }
})
</script>
<style scoped src="@/static/css/index.css"></style>
<style scoped src="@/static/css2/shopcss.css"></style>
<style scoped src="@/static/css2/newindex.css"></style>
<style scoped>
.item-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style scoped>
._3kBox {
  /* position: fixed; */
  top: 0;
  z-index: 10000;
}
._3KrBP {
  position: absolute;
  left: -335px;
  z-index: 10000;
  top: 155px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 0 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  opacity: 0;
  /* display: none; */
  /* height: 154px; */
  transition: all 0.2s;
  max-width: 550px;
}

._3kShow {
  left: 35px;
  opacity: 1;
  /* display: block; */
  /* transition: all 0.2s; */
}

._3KrBP .UAEqK {
  height: 100%;
  width: 114px;
  position: relative;
}
._3KrBP .UAEqK img {
  width: 114px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

._3KrBP ._1-aaU {
  text-align: left;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  height: 100%;
}

._3KrBP ._1-aaU ._1u9ll {
  font-weight: 700;
  font-size: 18px;
  color: #333;
  letter-spacing: 0;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  max-height: 60px;
}

._3KrBP ._1-aaU ._3hbpp {
  font-weight: 700;
  font-size: 13px;
  color: #666;
  letter-spacing: 0;
  margin-bottom: 17px;
  max-height: 240px;
  min-height: 50px;
  overflow: hidden;
}

._3KrBP ._1-aaU ._1ZwH_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
}

._3KrBP ._1-aaU ._1ZwH_ ._1-SOk.Sk1_X {
  background: #f3f4f4;
  color: #666;
}
._3KrBP ._1-aaU ._1ZwH_ ._1-SOk + ._1-SOk {
  margin-left: 8px;
  margin-right: 8px;
}
._3KrBP ._1-aaU ._1ZwH_ ._1-SOk {
  cursor: pointer;
  background: #ffb500;
  border-radius: 15px;
  padding: 6px 28px;
  font-weight: 700;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0;
}

._3KrBP ._24EHh {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 21px;
  height: 21px;
}

.next-icon {
  color: #ffb500;
}
#divStorefrontContentSection div {
  line-height: normal;
}

#multi-2-teaser13 .multi-teaser {
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 0px 0px;
}

.button-list .button-list-outer-container,
.button-list .multi-teaser-outer-container,
.multi-teaser .button-list-outer-container,
.multi-teaser .multi-teaser-outer-container {
  /* overflow-x: scroll; */
  overflow-y: hidden;
  min-width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #000 #ccc;
}

.button-list .button-list-outer-container .button-list-inner-container,
.button-list .button-list-outer-container .multi-teaser-inner-container,
.button-list .multi-teaser-outer-container .button-list-inner-container,
.button-list .multi-teaser-outer-container .multi-teaser-inner-container,
.multi-teaser .button-list-outer-container .button-list-inner-container,
.multi-teaser .button-list-outer-container .multi-teaser-inner-container,
.multi-teaser .multi-teaser-outer-container .button-list-inner-container,
.multi-teaser .multi-teaser-outer-container .multi-teaser-inner-container {
  display: flex;
  margin: 0 auto;
}

#multi-2-teaser13 .multi-teaser-item {
  min-width: calc(100% / 3);
  width: calc(100% / 3);
}

#multi-2-teaser13_item_0,
#multi-2-teaser13_item_0 .hover-img-wrap-con {
  padding-inline-end: 0px;
  padding-inline-start: 0;
}

.multi-teaser.shop-news-edits-two h2 {
  margin: 0px !important;
  color: #ffffff !important;
  font-family: Playfair Display, Times New Roman, serif !important;
  padding-bottom: 5px;
  font-size: 2.2vw;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}

.cm-teaser-overlay__text :first-child {
  margin-top: 0;
}

.cm-richtext .align--center {
  text-align: center;
}

/* .multi-teaser.shop-news-edits-two h3 {
  margin: auto !important;
  color: #ffffff !important;
  padding-bottom: 15px;
  width: 80% !important;
  text-align: center !important;
} */

.shop-news-edits-two h3 {
  margin: 0px !important;
  line-height: 1.3;
  color: #ffffff !important;
  padding-bottom: 0px;
}

.cm-richtext .align--center {
  text-align: center;
}

.cm_bootstrap .shop-news-edits-two p {
  margin: 0 !important;
}

.cm-richtext .align--center {
  text-align: center;
}

#multi-2-teaser13_item_1,
#multi-2-teaser13_item_1 .hover-img-wrap-con {
  padding-inline-start: 0px;
  padding-inline-end: 0;
}
.video-wrapper > video {
  width: 100%;
  vertical-align: middle;
}

.shop-news-edits-two .hp-btn {
  border-radius: 40px;
  background-color: #e5c287;
  border: 1px solid #e5c287 !important;
  color: black !important;
  font-size: 14px;
  border: 1px solid #e6e6e6;
  margin: 0;
  padding: 11px 28px;
  text-align: center;
  text-decoration: none !important;
  transition: background 0.3s ease;
  cursor: pointer;
}

.shp-box {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 1;
  background-color: #000;
  border-radius: 10px;
}
.shp-btn {
  border-radius: 40px;
  background-color: #e5c287;
  border: 1px solid #e5c287 !important;
  color: black !important;
  font-size: 14px;
  border: 1px solid #e6e6e6;
  margin: 0;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none !important;
  transition: background 0.3s ease;
  cursor: pointer;
  margin-bottom: 10px;
}

.shp-text {
  color: rgb(255, 255, 255);
  margin: 5px;
  font-size: 13px;
  text-align: center;
}
</style>
