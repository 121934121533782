<template>
  <div class="header header-outer-container header-special-container header-tag-home" id="header" data-spm="1000002">
    <div class="header-wrap container">
      <div class="hm-left hm-left-store" style="width: 256px">
        <div class="header-categories" id="header-categories" data-widget-cid="widget-9">
          <span class="categories-title">CATEGORIAS</span>
          <i class="balloon-arrow"></i>
        </div>
        <div class="site-logo">
          <router-link :to="{ name: 'index' }">
            <span class="logo-base"></span>
            <span class="logo-slogan"></span>
          </router-link>
        </div>
      </div>
      <div class="hm-right">
        <!--ams-region-start 1261-->
        <div class="header-right-content" style="visibility: visible">
          <div class="right-shopcart">
            <router-link :to="{ name: 'shopcart' }">
              <i class="right-cart-icon"></i>
              <span class="right-cart-number" v-if="msgCenter.cart > 0">{{
                msgCenter.cart
              }}</span>
            </router-link>
          </div>
        </div>
        <!--ams-region-end 1261-->
      </div>
      <div class="hm-middle">
        <div id="form-searchbar" class="searchbar-form" method="get">
          <input type="hidden" id="catId" name="catId" value="0" />
          <div class="searchbar-operate-box">
            <button type="submit" class="search-button" @click="onSearch()"></button>
            <div id="search-cate" class="search-category hidden-sm"></div>
          </div>
          <!-- 搜索商铺 -->
          <search-shop @setSearchVal="setSearchVal"></search-shop>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchShop from "../SearchShop.vue";
export default {
  name: "shopCart",
  components: {
    SearchShop
  },
  data () {
    return {
      msgCenter: {
        cart: 0,
      },

      // 搜索值
      searchVal: '',
    };
  },
  created () {
    //获取基本
    this.$api.home.getCartNum().then((res) => {
      this.msgCenter = res.data;
    });
  },
  methods: {
    setSearchVal (val) {
      this.searchVal = val
    },
    onSearch () {
      this.$router.push({ name: 'category', query: { id: 0, level: 0, keyword: this.searchVal } })
    }
  },
};
</script>
<style scoped src="@/static/css/ae-header.css">

</style>
<style scoped type="text/css">
.hm-right .header-right-content {
  visibility: hidden;
}

.header-categories {
  display: none;
}
</style>
